import appointmentIcon from 'assets/svgs/appointments.svg'
import calendarIcon from 'assets/svgs/calendar.svg'
import cameraIcon from 'assets/svgs/camera.svg'
import checkedCircleIcon from 'assets/svgs/check.svg'
import circleIcon from 'assets/svgs/circle.svg'
import clientIcon from 'assets/svgs/clients.svg'
import copyIcon from 'assets/svgs/copy.svg'
import currentCircleIcon from 'assets/svgs/current.svg'
import dashboardIcon from 'assets/svgs/dashboard.svg'
import englishFlagIcon from 'assets/svgs/english-flag.svg'
import germanFlagIcon from 'assets/svgs/german-flag.svg'
import greenFlagIcon from 'assets/svgs/green-flag.svg'
import messageIcon from 'assets/svgs/message-circle.svg'
import newsPaperIcon from 'assets/svgs/newspaper.svg'
import redFlagIcon from 'assets/svgs/red-flag.svg'
import servicesIcon from 'assets/svgs/services.svg'
import settingsIcon from 'assets/svgs/settings.svg'
import smallGreenFlagIcon from 'assets/svgs/small-green-flag.svg'
import smallRedFlagIcon from 'assets/svgs/small-red-flag.svg'
import smallWhiteFlagIcon from 'assets/svgs/small-white-flag.svg'
import smallYellowFlagIcon from 'assets/svgs/small-yellow-flag.svg'
import subCompanyIcon from 'assets/svgs/subcompany.svg'
import taskManagerIcon from 'assets/svgs/task-manager.svg'
import whiteFlagIcon from 'assets/svgs/white-flag.svg'
import yellowFlagIcon from 'assets/svgs/yellow-flag.svg'

import accountIcon from 'assets/pngs/account.png'
import appointmentBackgroundIcon from 'assets/pngs/appointment-background.png'
import checkmarkIcon from 'assets/pngs/checkmark.png'
import dangerIcon from 'assets/pngs/danger.png'
import deleteIcon from 'assets/pngs/delete.png'
import healthcornerIcon from 'assets/pngs/healthcorner.png'
import loginbackgroundIcon from 'assets/pngs/login.png'
import noInboxIcon from 'assets/pngs/no-inbox.png'
import noMessagesIcon from 'assets/pngs/no-messages.png'
import profilePhotoIcon from 'assets/pngs/profile-photo.png'
import sendButton from 'assets/pngs/send-button.png'
import transparentHeart from 'assets/pngs/transparent-heart.png'
import warningIcon from 'assets/pngs/warning.png'
import whiteHealthcornerIcon from 'assets/pngs/white-logo.png'
import stripeIcon from 'assets/svgs/stripe.svg'

export const AppointmentSidebarIcon = appointmentIcon
export const CameraIcon = cameraIcon
export const CalendarSidebarIcon = calendarIcon
export const CheckedCircleIcon = checkedCircleIcon
export const CircleIcon = circleIcon
export const CopyIcon = copyIcon
export const CurrentCircleIcon = currentCircleIcon
export const ClientSidebarIcon = clientIcon
export const DashboardSidebarIcon = dashboardIcon
export const EnglishFlagIcon = englishFlagIcon
export const GermanFlagIcon = germanFlagIcon
export const MessageIcon = messageIcon
export const NewspaperIcon = newsPaperIcon
export const GreenFlagIcon = greenFlagIcon
export const RedFlagIcon = redFlagIcon
export const ServicesSidebarIcon = servicesIcon
export const SettingsSidebarIcon = settingsIcon
export const SubCompanyIcon = subCompanyIcon
export const StripeIcon = stripeIcon
export const SmallGreenFlagIcon = smallGreenFlagIcon
export const SmallRedFlagIcon = smallRedFlagIcon
export const SmallYellowFlagIcon = smallYellowFlagIcon
export const SmallWhiteFlagIcon = smallWhiteFlagIcon

export const CheckmarkIcon = checkmarkIcon
export const TaskManagerIcon = taskManagerIcon
export const YellowFlagIcon = yellowFlagIcon
export const WhiteFlagIcon = whiteFlagIcon

export const AccountIcon = accountIcon
export const AppointmentBackgroundIcon = appointmentBackgroundIcon
export const DangerIcon = dangerIcon
export const DeleteIcon = deleteIcon
export const HealthcornerIcon = healthcornerIcon
export const NoInboxIcon = noInboxIcon
export const NoMessagesIcon = noMessagesIcon
export const LoginBackgroundIcon = loginbackgroundIcon
export const ProfilePhotoIcon = profilePhotoIcon
export const SendButtonIcon = sendButton
export const TransparentHeartIcon = transparentHeart
export const WarningIcon = warningIcon
export const WhiteHealthcornerIcon = whiteHealthcornerIcon
