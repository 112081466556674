import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import { WarningIcon } from 'assets/icons'
import { Button } from 'components/app/button'
import { Modal } from 'components/app/modal'
import useIsMobile from 'hooks/useIsMobile'
import { getTKey } from 'utils/language'

interface StatusPromptProps {
	currentStatus: string
	name?: string
	onUpdate: () => void
	onCancel: () => void
}

export const StatusPrompt = ({ currentStatus, name, onUpdate, onCancel }: StatusPromptProps) => {
	const { t } = useTranslation()
	const isMobile = useIsMobile()

	const tKey = getTKey('statusPrompt')
	return (
		<Modal width={isMobile ? 'w-[320px]' : 'w-[512px]'} showCrossIcon={false}>
			<div className="flex flex-col gap-4 md:gap-6 items-center">
				<div className="flex flex-col gap-3 md:gap-5 items-center">
					<img src={WarningIcon} className="max-sm:h-14" />
					<p className="text-primary text-base md:text-[18px] leading-6 font-domine text-center">
						{currentStatus === 'aktiv'
							? t(tKey('headings.activeStatus'))
							: t(tKey('headings.inactiveStatus'))}
					</p>
				</div>
				<div className="flex flex-col gap-y-2 items-center">
					<p className="md:mx-5 text-center max-md:text-sm">
						{t(tKey('labels.settings'))}{' '}
						<span
							className={clsx(currentStatus === 'aktiv' ? 'text-[#EE664E]' : 'text-primary')}>{`'${
							currentStatus === 'aktiv' ? t(tKey('labels.inactive')) : t(tKey('labels.active'))
						}'`}</span>{' '}
						{currentStatus === 'aktiv' ? t(tKey('labels.deactivate')) : t(tKey('labels.activate'))}
					</p>
					{name && (
						<div className="md:px-8 px-4 max-md:text-xs flex justify-center py-3 bg-[#CFD8DC99] rounded text-sm text-primary leading-5">
							{name}
						</div>
					)}
				</div>
				<div className="flex flex-col gap-y-3.5 w-full">
					<Button onClick={onUpdate} className="py-[9px] max-md:text-sm w-full px-[17px] rounded">
						{t(tKey('buttons.update'))}
					</Button>
					<button
						style={{ boxShadow: '0px 1px 2px 0px #0000000D' }}
						onClick={onCancel}
						className="py-[9px] w-full px-[17px] max-md:text-sm border border-[#CFD8DC] rounded">
						{t(tKey('buttons.cancel'))}
					</button>
				</div>
			</div>
		</Modal>
	)
}
