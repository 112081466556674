import { useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { EnglishFlagIcon, GermanFlagIcon } from 'assets/icons'
import { Spinner } from 'components/animations/spinner'
import { Button } from 'components/app/button'
import { Input } from 'components/inputs/input'
import { useAppSelector } from 'hooks'
import useIsMobile from 'hooks/useIsMobile'
import faqsService from 'services/faqs-service'
import { getTKey } from 'utils/language'

interface CreateClientProps {
	faqId?: string
	onCreation: () => void
	onCancel: () => void
}

export const CreateOrEditFaqs = ({ faqId, onCreation, onCancel }: CreateClientProps) => {
	const { t } = useTranslation()
	const isMobile = useIsMobile()

	const tKey = getTKey('faqs.create')

	const isEditForm = !!faqId
	const auth = useAppSelector(state => state.auth)

	const [isLoading, setIsLoading] = useState(false)

	const schema = yup.object({
		faq: yup.array().of(
			yup
				.object()
				.shape({
					question_de: yup.string().required(t(tKey('errors.questionDe'))),
					question_en: yup.string().required(t(tKey('errors.questionEn'))),
					answer_de: yup.string().required(t(tKey('errors.answerDe'))),
					answer_en: yup.string().required(t(tKey('errors.answerEn')))
				})
				.required()
		)
	})

	const {
		register,
		handleSubmit,
		formState: { errors },
		control
	} = useForm<FaqForms>({
		resolver: yupResolver(schema as any),
		mode: 'onSubmit'
	})

	const { fields, append, remove } = useFieldArray({ name: 'faq', control })

	const onSubmit = (data: any) => {
		setIsLoading(true)
		if (isEditForm) {
			const payload: FaqForm = {
				...data.faq[0],
				id_company: auth.companyId
			}
			faqsService
				.updateFaq(faqId as string, payload)
				.then(() => {
					toast.success(t(tKey('toast.updateSuccess')))
					onCreation()
				})
				.catch(() => toast.error(t(tKey('toast.updateError'))))
		} else {
			Promise.all(
				data.faq.map((faq: FaqForm) => {
					const payload: FaqForm = {
						...faq,
						id_company: auth.companyId
					}
					faqsService.createFaq(payload).catch(() => toast.error(t(tKey('toast.faqError'))))
				})
			)
				.then(() => {
					toast.success(t(tKey('toast.faqSuccess')))
					onCreation()
				})
				.finally(() => setIsLoading(false))
		}
	}

	useEffect(() => {
		if (isEditForm) {
			faqsService.getFaqById(faqId).then(res =>
				append({
					question_de: res.question_de,
					question_en: res.question_en,
					answer_de: res.answer_de,
					answer_en: res.answer_en
				})
			)
		} else if (fields.length === 0) {
			append({ question_de: '', question_en: '', answer_de: '', answer_en: '' })
		}
	}, [isEditForm])

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="md:px-8 px-5 py-6">
			<div className="flex flex-col gap-y-[18px]">
				<h2 className="text-primary text-sm md:text-base">{t(tKey('labels.addQuestion'))}</h2>
				<div className="max-md:hidden grid grid-cols-2 p-1 place-items-center bg-[#D3E3F14D] rounded-[20px] text-primary items-center">
					<div className="flex gap-x-2 text-center items-center">
						<img src={GermanFlagIcon} className="h-5 w-5" />
						<h6 className="text-xs">German</h6>
					</div>
					<div className="flex gap-x-2 text-center items-center">
						<img src={EnglishFlagIcon} className="h-5 w-5" />
						<h6 className="text-xs">English</h6>
					</div>
				</div>
				{fields.map((field, index) => {
					return (
						<div key={field.id} className="flex flex-col gap-y-[18px]">
							<div className="flex items-center justify-between">
								<h4>{t(tKey('titles.question')) + ' ' + (index + 1)}</h4>
								{!isEditForm && (
									<svg
										onClick={() => remove(index)}
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="21"
										viewBox="0 0 20 21"
										fill="none"
										className="cursor-pointer">
										<path
											d="M6.08975 18.0534C5.67549 18.0534 5.32085 17.9059 5.02585 17.6108C4.73084 17.3158 4.58333 16.9612 4.58333 16.547V5.97006H4.375C4.19792 5.97006 4.04948 5.91013 3.92969 5.79028C3.8099 5.67045 3.75 5.52195 3.75 5.34479C3.75 5.16763 3.8099 5.01923 3.92969 4.89958C4.04948 4.77991 4.19792 4.72008 4.375 4.72008H7.49998C7.49998 4.51602 7.57183 4.34215 7.71552 4.19845C7.85922 4.05476 8.03309 3.98291 8.23715 3.98291H11.7628C11.9669 3.98291 12.1407 4.05476 12.2844 4.19845C12.4281 4.34215 12.5 4.51602 12.5 4.72008H15.625C15.802 4.72008 15.9505 4.78 16.0703 4.89985C16.1901 5.01969 16.25 5.16819 16.25 5.34535C16.25 5.52251 16.1901 5.67092 16.0703 5.79058C15.9505 5.91023 15.802 5.97006 15.625 5.97006H15.4166V16.547C15.4166 16.9612 15.2691 17.3158 14.9741 17.6108C14.6791 17.9059 14.3245 18.0534 13.9102 18.0534H6.08975ZM14.1666 5.97006H5.83331V16.547C5.83331 16.6217 5.85735 16.6832 5.90544 16.7313C5.95352 16.7793 6.01496 16.8034 6.08975 16.8034H13.9102C13.985 16.8034 14.0464 16.7793 14.0945 16.7313C14.1426 16.6832 14.1666 16.6217 14.1666 16.547V5.97006ZM8.46181 15.1367C8.63897 15.1367 8.78737 15.0768 8.90702 14.957C9.02669 14.8372 9.08652 14.6888 9.08652 14.5117V8.2617C9.08652 8.08463 9.0266 7.9362 8.90675 7.81641C8.7869 7.69662 8.6384 7.63672 8.46125 7.63672C8.28408 7.63672 8.13567 7.69662 8.01602 7.81641C7.89637 7.9362 7.83654 8.08463 7.83654 8.2617V14.5117C7.83654 14.6888 7.89647 14.8372 8.01631 14.957C8.13615 15.0768 8.28465 15.1367 8.46181 15.1367ZM11.5387 15.1367C11.7159 15.1367 11.8643 15.0768 11.9839 14.957C12.1036 14.8372 12.1634 14.6888 12.1634 14.5117V8.2617C12.1634 8.08463 12.1035 7.9362 11.9836 7.81641C11.8638 7.69662 11.7153 7.63672 11.5381 7.63672C11.361 7.63672 11.2126 7.69662 11.0929 7.81641C10.9733 7.9362 10.9134 8.08463 10.9134 8.2617V14.5117C10.9134 14.6888 10.9734 14.8372 11.0932 14.957C11.2131 15.0768 11.3616 15.1367 11.5387 15.1367Z"
											fill="#F84C6B"
										/>
									</svg>
								)}
							</div>
							<div className="md:hidden bg-[#D3E3F14D] flex items-center justify-center gap-x-2">
								<img src={GermanFlagIcon} className="h-4 w-4" />
								<p className="text-primary text-xs">German</p>
							</div>
							<div className="grid grid-cols-1 md:grid-cols-2 gap-[18px]">
								<div className="flex flex-col gap-y-0.5">
									<Input
										type="text"
										labelText={t(tKey('labels.question'))}
										name={`faq.${index}.question_de`}
										register={register}
										errors={errors}
									/>
									{errors?.['faq']?.[index]?.question_de?.message && (
										<p className="text-xs text-red-500 mt-1">
											{errors?.['faq']?.[index]?.question_de?.message}
										</p>
									)}
								</div>
								<div className="md:hidden flex flex-col gap-y-0.5">
									<Input
										type="text"
										labelText={t(tKey('labels.answer'))}
										name={`faq.${index}.answer_de`}
										register={register}
										errors={errors}
									/>
									{errors?.['faq']?.[index]?.answer_de?.message && (
										<p className="text-xs text-red-500 mt-1">
											{errors?.['faq']?.[index]?.answer_de?.message}
										</p>
									)}
								</div>
								<div className="md:hidden bg-[#D3E3F14D] flex items-center justify-center gap-x-2">
									<img src={EnglishFlagIcon} className="h-4 w-4" />
									<p className="text-primary text-xs">English</p>
								</div>
								<div className="flex flex-col gap-y-0.5">
									<Input
										type="text"
										labelText={t(tKey('labels.question'))}
										name={`faq.${index}.question_en`}
										register={register}
										errors={errors}
									/>
									{errors?.['faq']?.[index]?.question_en?.message && (
										<p className="text-xs text-red-500 mt-1">
											{errors?.['faq']?.[index]?.question_en?.message}
										</p>
									)}
								</div>
							</div>
							<div className="grid grid-cols-1 md:grid-cols-2 gap-[18px]">
								{!isMobile && (
									<div className="flex max-md:hidden flex-col gap-y-0.5">
										<Input
											type="text"
											labelText={t(tKey('labels.answer'))}
											name={`faq.${index}.answer_de`}
											register={register}
											errors={errors}
										/>
										{errors?.['faq']?.[index]?.answer_de?.message && (
											<p className="text-xs text-red-500 mt-1">
												{errors?.['faq']?.[index]?.answer_de?.message}
											</p>
										)}
									</div>
								)}
								<div className="flex flex-col gap-y-0.5">
									<Input
										type="text"
										labelText={t(tKey('labels.answer'))}
										name={`faq.${index}.answer_en`}
										register={register}
										errors={errors}
									/>
									{errors?.['faq']?.[index]?.answer_en?.message && (
										<p className="text-xs text-red-500 mt-1">
											{errors?.['faq']?.[index]?.answer_en?.message}
										</p>
									)}
								</div>
							</div>
						</div>
					)
				})}
				{!isEditForm && (
					<div className="flex justify-end">
						<button
							style={{ boxShadow: '0px 6px 24px 0px rgba(18, 50, 88, 0.08)' }}
							className="py-2 px-3 max-md:text-sm flex gap-x-1 border border-secondary rounded-full"
							onClick={event => {
								event.preventDefault()
								append({
									question_de: '',
									question_en: '',
									answer_de: '',
									answer_en: ''
								})
							}}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="21"
								viewBox="0 0 20 21"
								fill="none">
								<path
									d="M9.58333 10.8869H5V10.0535H9.58333V5.47021H10.4167V10.0535H15V10.8869H10.4167V15.4702H9.58333V10.8869Z"
									fill="#123258"
								/>
							</svg>
							{t(tKey('buttons.newQuestion'))}
						</button>
					</div>
				)}
				<div className="flex gap-x-6 py-6 justify-end">
					<button type="button" onClick={onCancel} className="text-primary font-bold text-sm">
						{t(tKey('buttons.cancel'))}
					</button>
					<Button disabled={isLoading} className="text-sm font-bold">
						{isLoading ? (
							<div className="flex items-center justify-center gap-x-5">
								<Spinner />
								<span className="animate-pulse whitespace-nowrap">
									{t(tKey('buttons.pleaseWait'))}
								</span>
							</div>
						) : (
							<span>{isEditForm ? t(tKey('buttons.update')) : t(tKey('buttons.save'))}</span>
						)}
					</Button>
				</div>
			</div>
		</form>
	)
}
