import { Disclosure } from '@headlessui/react'
import { ChevronRightIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { yupResolver } from '@hookform/resolvers/yup'
import clsx from 'clsx'
import cond from 'cond-construct'
import { DateTime } from 'luxon'
import * as yup from 'yup'

import { Spinner } from 'components/animations/spinner'
import { Button } from 'components/app/button'
import { MultiCombobox } from 'components/app/combo-selector'
import { Input } from 'components/inputs/input'
import { Select } from 'components/inputs/select'
import { ProgressBar } from 'components/progress-bar/progress-bar'
import { Language, germanToEnglishDays } from 'constants/constants'
import { useAppSelector } from 'hooks'
import categoryService from 'services/category-service'
import companyService from 'services/company-service'
import employeeService from 'services/employee-service'
import medicalService from 'services/medical-service'
import { getLocalizedWeekDays } from 'utils/date'
import { getAppLang, getTKey } from 'utils/language'

interface CreateServiceProps {
	id?: string
	onCancel: () => void
}

enum FormSteps {
	SERVICEDETAILS,
	SERVICETIME
}

export const CreateService = ({ id, onCancel }: CreateServiceProps) => {
	const { t } = useTranslation()

	const tKey = getTKey('services.create')

	const isEditForm = !!id

	const auth = useAppSelector(state => state.auth)

	const [steps, setSteps] = useState(FormSteps.SERVICEDETAILS)
	const [serviceId, setServiceId] = useState<string>()
	const [companyCategories, setCompanyCategories] = useState<Category[]>([])

	useEffect(() => {
		if (auth.is_adminUser === true) {
			categoryService
				.getCompanyCategories()
				.then(res => setCompanyCategories(res.filter(r => r.is_active === 'aktiv')))
		} else {
			categoryService
				.getCompanyCategoriesForEmployee()
				.then(res => setCompanyCategories(res.filter(r => r.is_active === 'aktiv')))
		}
	}, [])

	const renderComponent = cond([
		[
			steps === FormSteps.SERVICEDETAILS,
			() => (
				<ServiceInfo
					categories={companyCategories}
					id={id}
					onSubmission={() => setSteps(prev => prev + 1)}
					setServiceId={setServiceId}
					onCancel={onCancel}
				/>
			)
		],
		[
			steps === FormSteps.SERVICETIME,
			() => <ServiceTime serviceId={isEditForm ? id : (serviceId as string)} />
		]
	])

	return (
		<div className="flex flex-col gap-y-5 px-5 md:px-8 py-6">
			<div className="mx-auto my-4 mb-8">
				<ProgressBar
					totalSteps={2}
					currentStep={steps}
					steps={[
						{ label: t(tKey('progressBar.serviceDetails')), onAction: () => setSteps(0) },
						{
							label: t(tKey('progressBar.serviceTime')),
							onAction: () => {
								if (!isEditForm) return
								setSteps(1)
							}
						}
					]}
				/>
			</div>
			<>{renderComponent}</>
		</div>
	)
}

interface ServiceInfoProps {
	categories: Category[]
	id?: string
	setServiceId: (id: string) => void
	onSubmission: () => void
	onCancel: () => void
}

const ServiceInfo = ({
	categories,
	id,
	setServiceId,
	onSubmission,
	onCancel
}: ServiceInfoProps) => {
	const { t } = useTranslation()

	const tKey = getTKey('services.create')

	const serviceDuration = [
		{ label: '15 min', value: '15' },
		{ label: '30 min', value: '30' },
		{ label: '45 min', value: '45' },
		{ label: '1 hour', value: '60' },
		{ label: '1 hour 15 min', value: '75' },
		{ label: '1 hour 30 min', value: '90' },
		{ label: '1 hour 45 min', value: '105' },
		{ label: '2 hours', value: '120' },
		{ label: '2 hours 15 min', value: '135' },
		{ label: '2 hours 30 min', value: '150' },
		{ label: '2 hours 45 min', value: '165' },
		{ label: '3 hours', value: '180' }
	]

	const minimumBookingTimeSlots = [
		{ label: '1 hour', value: '60' },
		{ label: '2 hour', value: '120' },
		{ label: '3 hour', value: '180' }
	]

	const requiredFields = [
		{ label: 'Gewicht', value: '1' },
		{ label: 'Größe', value: '2' },
		{ label: 'BMI', value: '3' },
		{ label: 'Bauchumfang', value: '4' }
	]

	const [isLoading, setIsLoading] = useState(false)
	const [employees, setEmployees] = useState<Employee[]>([])
	const [selectedEmployee, setSelectedEmployee] = useState<{ id: string; label: string }[]>([])

	const schema = yup.object<ServiceForm>().shape({
		id_category: yup.string().required(t(tKey('errors.category'))),
		name: yup.string().required(t(tKey('errors.name'))),
		description: yup.string().notRequired(),
		duration: yup.string().required(t(tKey('errors.duration'))),
		health_insurance: yup.string().required(t(tKey('errors.healthInsurance'))),
		min_booking: yup.string().required(t(tKey('errors.minBooking'))),
		min_cancel: yup.string().required(t(tKey('errors.minCancel'))),
		notify_sms: yup.string().required(t(tKey('errors.notifySms'))),
		price: yup.string().required(t(tKey('errors.price'))),
		is_active: yup.string().required(t(tKey('errors.status')))
	})

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors }
	} = useForm<ServiceForm>({
		resolver: yupResolver(schema as any),
		mode: 'all'
	})

	useEffect(() => {
		employeeService.getCompanyEmployees().then(res => setEmployees(res))
		if (id) {
			medicalService.getServiceById(id).then(res => {
				setSelectedEmployee(
					res.id_employees?.map(r => ({ id: r._id, label: r.fname + ' ' + r.lname }))
				)
				reset({ ...res, price: res.price.toString() })
			})
		}
	}, [id])

	const onSubmit = (data: ServiceForm) => {
		setIsLoading(true)
		const payload =
			selectedEmployee.length > 0
				? { ...data, id_employees: selectedEmployee.map(employee => employee.id) }
				: data

		if (id) {
			medicalService
				.updateService(id, payload)
				.then(() => {
					toast.success(t(tKey('toast.updateSuccess')))
					onSubmission()
				})
				.catch(error => {
					if (error?.response?.data?.message) {
						return toast.error(error?.response?.data?.message)
					}
					toast.error(t(tKey('toast.updateError')))
				})
				.finally(() => setIsLoading(false))
		} else {
			medicalService
				.createService(payload)
				.then(data => {
					toast.success(t(tKey('toast.addSuccess')))
					setServiceId(data._id)
					onSubmission()
				})
				.catch(error => {
					if (error?.response?.data?.message) {
						return toast.error(error?.response?.data?.message)
					}
					toast.error(t(tKey('toast.addError')))
				})
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="md:px-8 max-md:pt-8 md:py-6">
			<div className="flex flex-col gap-y-5">
				<div className="grid grid-cols-1 items-start md:grid-cols-2 gap-y-5 md:grid-flow-col gap-x-5">
					<Select
						labelText={t(tKey('labels.category'))}
						name="id_category"
						register={register}
						errors={errors}>
						<option value="">{t(tKey('labels.selectCategory'))}</option>
						{categories.map(category => (
							<option key={category._id} value={category._id}>
								{category.category_name}
							</option>
						))}
					</Select>
					<Input
						register={register}
						errors={errors}
						name="name"
						labelText={t(tKey('labels.name'))}
					/>
				</div>
				<div className="grid grid-cols-1 items-start md:grid-cols-2 gap-y-5 md:grid-flow-col gap-x-5">
					<Input
						register={register}
						errors={errors}
						inputMode="numeric"
						labelText={t(tKey('labels.price'))}
						name="price"
						type="number"
						min="0"
					/>
					<Select
						labelText={t(tKey('labels.duration'))}
						name="duration"
						register={register}
						errors={errors}>
						<option value="">{t(tKey('labels.selectDuration'))}</option>
						{serviceDuration.map(duration => (
							<option key={duration.label} value={duration.value}>
								{duration.label}
							</option>
						))}
					</Select>
				</div>
				<div className="relative overflow-visible rounded-md">
					<label
						htmlFor="description"
						className="absolute -top-2.5 z-10 text-xs cursor-text px-0.5 text-primary bg-white left-3">
						{t(tKey('labels.description'))}
					</label>
					<textarea
						rows={4}
						{...register('description')}
						placeholder={t(tKey('labels.description'))}
						className="w-full rounded font-normal pl-4 py-3 bg-transparent focus:ring-0 border focus:border-secondary border-[#D3E3F1] text-primary placeholder-[#7F9AB2] placeholder:text-base focus:outline-none max-md:text-sm"
					/>
				</div>
				<div className="grid grid-cols-1 md:grid-cols-2 gap-y-5 gap-x-5">
					<Select
						labelText={t(tKey('labels.minBooking'))}
						name="min_booking"
						register={register}
						errors={errors}>
						<option value="">{t(tKey('labels.selectMinBooking'))}</option>
						{minimumBookingTimeSlots.map(timeSlot => (
							<option key={timeSlot.label} value={timeSlot.value}>
								{timeSlot.label}
							</option>
						))}
					</Select>
					<Select
						labelText={t(tKey('labels.minCancel'))}
						name="min_cancel"
						register={register}
						errors={errors}>
						<option value="">{t(tKey('labels.selectMinCancel'))}</option>
						{minimumBookingTimeSlots.map(timeSlot => (
							<option key={timeSlot.label} value={timeSlot.value}>
								{timeSlot.label}
							</option>
						))}
					</Select>
				</div>
				<div className="grid grid-cols-1 md:grid-cols-2 gap-y-5 md:grid-flow-col gap-x-5">
					<MultiCombobox
						placeholder={t(tKey('labels.selectEmployee'))}
						options={employees.map(employee => ({
							label: `${employee.fname} ${employee.lname}`,
							id: employee._id
						}))}
						selectedItems={selectedEmployee}
						onChange={value => {
							const labelCountMap = new Map()
							value.forEach(item => {
								const count = labelCountMap.get(item.label) || 0
								labelCountMap.set(item.label, count + 1)
							})
							const uniqueValue = value.filter(item => labelCountMap.get(item.label) === 1)
							setSelectedEmployee(uniqueValue)
						}}
					/>
					<Select
						labelText={t(tKey('labels.smsNotification'))}
						name="notify_sms"
						register={register}
						errors={errors}>
						<option value="">{t(tKey('labels.activateSmsNotification'))}</option>
						<option value="0">{t(tKey('labels.yes'))}</option>
						<option value="1">{t(tKey('labels.no'))}</option>
					</Select>
				</div>
				<div className="grid grid-cols-1 md:grid-cols-2 gap-y-5 md:grid-flow-col gap-x-5">
					<Select
						labelText={t(tKey('labels.healthInsurance'))}
						name="health_insurance"
						register={register}
						errors={errors}>
						<option value="0">{t(tKey('labels.no'))}</option>
						<option value="1">{t(tKey('labels.yes'))}</option>
					</Select>
					<Select
						labelText={t(tKey('labels.status'))}
						name="is_active"
						register={register}
						errors={errors}>
						<option value="">{t(tKey('labels.status'))}</option>
						<option value="aktiv">{t(tKey('labels.active'))}</option>
						<option value="inaktiv">{t(tKey('labels.inActive'))}</option>
					</Select>
				</div>
				<div className="flex gap-x-6 justify-end">
					<button type="button" onClick={onCancel} className="text-primary font-bold text-sm">
						{t(tKey('buttons.cancel'))}
					</button>
					<Button disabled={isLoading} className="text-sm font-bold">
						{isLoading ? (
							<div className="flex items-center justify-center gap-x-5">
								<Spinner />
								<span className="animate-pulse whitespace-nowrap">
									{t(tKey('buttons.pleaseWait'))}
								</span>
							</div>
						) : (
							<span>{id ? t(tKey('buttons.edit')) : t(tKey('buttons.add'))}</span>
						)}
					</Button>
				</div>
			</div>
		</form>
	)
}

interface ServiceTimeProps {
	serviceId: string
}

const ServiceTime = ({ serviceId }: ServiceTimeProps) => {
	const { t } = useTranslation()

	const tKey = getTKey('services.create')

	const [forceRefresh, setForceRefresh] = useState(false)
	const [time, setTime] = useState<{ [day: string]: ScheduleForm }>({})

	useEffect(() => {
		medicalService.getServiceSchedule(serviceId).then(res => {
			const mappedTime = res
				.map(r => ({ ...r, day: r.day.toString() }))
				.reduce((agg, curr) => ({ ...agg, [curr.day]: curr }), {})
			setTime(mappedTime)
		})
	}, [forceRefresh])

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>, day: string) => {
		const { name, value } = event.target
		const germanToEnglish = germanToEnglishDays[day]

		const dateTime = DateTime.fromFormat(value, 'HH:mm')
		const weekDay =
			DateTime.fromFormat(getAppLang() === Language.EN ? day : germanToEnglish, 'EEEE').weekday ===
			7
				? 0
				: DateTime.fromFormat(getAppLang() === Language.EN ? day : germanToEnglish, 'EEEE').weekday

		if (!dateTime.isValid) {
			return setTime(prev => ({
				...prev,
				[weekDay.toString()]: {
					...prev[weekDay.toString()],
					day: weekDay.toString(),
					[name]: null
				}
			}))
		}
		setTime(prev => ({
			...prev,
			[weekDay.toString()]: {
				...prev[weekDay.toString()],
				day: weekDay.toString(),
				[name]: { hour: dateTime.hour, minute: dateTime.minute }
			}
		}))
	}

	const addSchedule = (weekDay: number) => {
		const payload: ScheduleForm = {
			start: time[weekDay]?.start ?? { hour: 0, minute: 0 },
			end: time[weekDay]?.end ?? { hour: 0, minute: 0 },
			pause_start: time[weekDay]?.pause_start ?? { hour: 0, minute: 0 },
			pause_end: time[weekDay]?.pause_end ?? { hour: 0, minute: 0 },
			id_service: serviceId,
			type: time[weekDay]?.type ?? 'Closed',
			day: weekDay.toString()
		}
		medicalService
			.createServiceSchedule(payload)
			.then(() => {
				toast.success(t(tKey('toast.addScheduleSuccess')))
				setForceRefresh(prev => !prev)
			})
			.catch(() => toast.error(t(tKey('toast.addScheduleError'))))
	}

	const updateSchedule = (weekDay: number) => {
		const scheduleId = time[weekDay]._id as string
		delete time[weekDay].__v
		delete time[weekDay]._id
		delete time[weekDay].id_company
		companyService
			.updateCompanySchedule(scheduleId, time[weekDay])
			.then(() => {
				toast.success(t(tKey('toast.scheduleSuccess')))
				setForceRefresh(prev => !prev)
			})
			.catch(() => toast.error(t(tKey('toast.scheduleError'))))
	}

	const deleteSchedule = (weekDay: number) => {
		const scheduleId = (time[weekDay] as any)._id

		medicalService
			.deleteServiceSchedule(scheduleId)
			.then(() => {
				toast.success(t(tKey('toast.deleteScheduleSuccess')))
				setForceRefresh(prev => !prev)
			})
			.catch(() => toast.error(t(tKey('toast.deleteScheduleError'))))
	}

	return (
		<div className="md:px-8 max-md:pt-8 md:py-6">
			<div className="flex flex-col md:items-center md:gap-y-4">
				<table className="-mt-8 -mb-12 lg:overflow-x-hidden max-md:hidden table-auto w-full max-xl:block whitespace-nowrap overflow-x-auto border-separate border-spacing-x-[29px] border-spacing-y-[27px]">
					<thead>
						<tr>
							<th></th>
							<th className="text-primary text-lg text-center font-semibold">
								{t(tKey('tables.status'))}
							</th>
							<th className="text-primary text-center text-lg font-semibold">
								{t(tKey('tables.availability'))}
							</th>
							<th className="text-primary text-lg text-center font-semibold">
								{t(tKey('tables.break'))}
							</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{getLocalizedWeekDays().map(day => {
							const germanToEnglish = germanToEnglishDays[day]

							const weekDay =
								DateTime.fromFormat(getAppLang() === Language.EN ? day : germanToEnglish, 'EEEE')
									.weekday === 7
									? 0
									: DateTime.fromFormat(
											getAppLang() === Language.EN ? day : germanToEnglish,
											'EEEE'
										).weekday
							return (
								<tr key={day}>
									<td className="px-2.5 bg-border rounded text-center text-primary font-semibold">
										{day}
									</td>
									<td className="flex w-max">
										<Select
											onChange={event =>
												setTime(prev => ({
													...prev,
													[weekDay.toString()]: {
														...prev[weekDay.toString()],
														type: event.target.value as Schedule['type']
													}
												}))
											}
											value={time[weekDay]?.type ?? 'Closed'}
											name="type">
											<option value="Custom">{t(tKey('labels.custom'))}</option>
											<option value="Closed">{t(tKey('labels.closed'))}</option>
											<option value="24">{t(tKey('labels.open'))}</option>
										</Select>
									</td>
									<td>
										<div className="flex gap-x-5">
											<Input
												disabled={
													!time[weekDay.toString()]?.type ||
													time[weekDay.toString()]?.type === '24' ||
													time[weekDay.toString()]?.type === 'Closed'
												}
												name="start"
												type="time"
												value={
													!time[weekDay.toString()]?.start ||
													time[weekDay.toString()]?.start === null
														? '00:00'
														: DateTime.fromObject(
																time[weekDay.toString()]?.start as Object
															).toFormat('HH:mm')
												}
												onChange={event => handleChange(event, day)}
											/>
											<Input
												disabled={
													!time[weekDay.toString()]?.type ||
													time[weekDay.toString()]?.type === '24' ||
													time[weekDay.toString()]?.type === 'Closed'
												}
												name="end"
												value={
													!time[weekDay.toString()]?.end || time[weekDay.toString()]?.end === null
														? '00:00'
														: DateTime.fromObject(time[weekDay.toString()]?.end as Object).toFormat(
																'HH:mm'
															)
												}
												type="time"
												onChange={event => handleChange(event, day)}
											/>
										</div>
									</td>

									<td>
										<div className="flex gap-x-5">
											<Input
												disabled={
													!time[weekDay.toString()]?.type ||
													time[weekDay.toString()]?.type === '24' ||
													time[weekDay.toString()]?.type === 'Closed'
												}
												name="pause_start"
												value={
													!time[weekDay.toString()]?.pause_start ||
													time[weekDay.toString()]?.pause_start === null
														? '00:00'
														: DateTime.fromObject(
																time[weekDay.toString()]?.pause_start as Object
															).toFormat('HH:mm')
												}
												type="time"
												onChange={event => handleChange(event, day)}
											/>
											<Input
												disabled={
													!time[weekDay.toString()]?.type ||
													time[weekDay.toString()]?.type === '24' ||
													time[weekDay.toString()]?.type === 'Closed'
												}
												name="pause_end"
												value={
													!time[weekDay.toString()]?.pause_end ||
													time[weekDay.toString()]?.pause_end === null
														? '00:00'
														: DateTime.fromObject(
																time[weekDay.toString()]?.pause_end as Object
															).toFormat('HH:mm')
												}
												type="time"
												onChange={event => handleChange(event, day)}
											/>
										</div>
									</td>
									<td>
										{time[weekDay]?._id ? (
											<div className="flex gap-x-4">
												<PencilIcon
													onClick={() => updateSchedule(weekDay)}
													className="h-5 w-5 cursor-pointer text-primary"
												/>
												<TrashIcon
													onClick={() => deleteSchedule(weekDay)}
													className="h-5 w-5 cursor-pointer text-[#F84C6B]"
												/>
											</div>
										) : (
											<Button onClick={() => addSchedule(weekDay)}>{t(tKey('buttons.add'))}</Button>
										)}
									</td>
								</tr>
							)
						})}
						<tr></tr>
					</tbody>
				</table>
				<div className="md:hidden">
					{getLocalizedWeekDays().map((day, index) => {
						const weekDay =
							DateTime.fromFormat(day, 'EEEE').weekday === 7
								? 0
								: DateTime.fromFormat(day, 'EEEE').weekday
						return (
							<Disclosure key={day}>
								{({ open }) => (
									<>
										<Disclosure.Button
											className={clsx(
												'flex justify-between w-full px-3 py-2 border-b border-border',
												{
													'bg-background': open,
													'border-b-0': index === getLocalizedWeekDays().length - 1
												}
											)}>
											<div className="flex justify-between gap-x-3 w-full">
												<div className="flex grow w-full gap-x-3 items-center">
													<ChevronRightIcon
														className={clsx('h-4 w-4 text-primary shrink-0', {
															'rotate-90 transform': open
														})}
													/>
													<p className="text-primary text-sm">{day}</p>
												</div>
												<Select
													onChange={event =>
														setTime(prev => ({
															...prev,
															[weekDay.toString()]: {
																...prev[weekDay.toString()],
																type: event.target.value as Schedule['type']
															}
														}))
													}
													onClick={event => event.stopPropagation()}
													value={time[weekDay]?.type ?? 'Closed'}
													name="type"
													className="w-full">
													<option value="Custom">{t(tKey('labels.custom'))}</option>
													<option value="Closed">{t(tKey('labels.closed'))}</option>
													<option value="24">{t(tKey('labels.open'))}</option>
												</Select>
											</div>
										</Disclosure.Button>
										<Disclosure.Panel>
											<div className="flex px-3 py-2 flex-col gap-y-1.5 border-b border-border bg-background">
												<h3 className="text-primary text-sm font-semibold">
													{t(tKey('tables.availability'))}
												</h3>
												<div className="flex justify-center gap-x-5 sm:grid sm:grid-cols-2">
													<input
														disabled={
															!time[weekDay.toString()]?.type ||
															time[weekDay.toString()]?.type === '24' ||
															time[weekDay.toString()]?.type === 'Closed'
														}
														name="start"
														type="time"
														value={
															!time[weekDay.toString()]?.start ||
															time[weekDay.toString()]?.start === null
																? '00:00'
																: DateTime.fromObject(
																		time[weekDay.toString()]?.start as Object
																	).toFormat('HH:mm')
														}
														onChange={event => handleChange(event, day)}
														className="px-3 text-sm rounded focus:outline-none focus:ring-0 disabled:text-gray-500 focus:border-secondary border-[#D3E3F1] text-primary placeholder-[#7F9AB2]"
													/>
													<input
														disabled={
															!time[weekDay.toString()]?.type ||
															time[weekDay.toString()]?.type === '24' ||
															time[weekDay.toString()]?.type === 'Closed'
														}
														name="end"
														value={
															!time[weekDay.toString()]?.end ||
															time[weekDay.toString()]?.end === null
																? '00:00'
																: DateTime.fromObject(
																		time[weekDay.toString()]?.end as Object
																	).toFormat('HH:mm')
														}
														type="time"
														onChange={event => handleChange(event, day)}
														className="px-3 text-sm rounded focus:outline-none focus:ring-0 disabled:text-gray-500 focus:border-secondary border-[#D3E3F1] text-primary placeholder-[#7F9AB2]"
													/>
												</div>
											</div>
											<div className="flex px-3 py-2 flex-col gap-y-1.5 bg-background">
												<h3 className="text-primary text-sm font-semibold">
													{t(tKey('tables.break'))}
												</h3>
												<div className="flex justify-center gap-x-5 sm:grid sm:grid-cols-2">
													<input
														disabled={
															!time[weekDay.toString()]?.type ||
															time[weekDay.toString()]?.type === '24' ||
															time[weekDay.toString()]?.type === 'Closed'
														}
														name="pause_start"
														type="time"
														value={
															!time[weekDay.toString()]?.pause_start ||
															time[weekDay.toString()]?.pause_start === null
																? '00:00'
																: DateTime.fromObject(
																		time[weekDay.toString()]?.pause_start as Object
																	).toFormat('HH:mm')
														}
														onChange={event => handleChange(event, day)}
														className="px-3 text-sm rounded focus:outline-none focus:ring-0 disabled:text-gray-500 focus:border-secondary border-[#D3E3F1] text-primary placeholder-[#7F9AB2]"
													/>
													<input
														disabled={
															!time[weekDay.toString()]?.type ||
															time[weekDay.toString()]?.type === '24' ||
															time[weekDay.toString()]?.type === 'Closed'
														}
														name="pause_end"
														value={
															!time[weekDay.toString()]?.pause_end ||
															time[weekDay.toString()]?.pause_end === null
																? '00:00'
																: DateTime.fromObject(
																		time[weekDay.toString()]?.pause_end as Object
																	).toFormat('HH:mm')
														}
														type="time"
														onChange={event => handleChange(event, day)}
														className="px-3 text-sm rounded focus:outline-none focus:ring-0 disabled:text-gray-500 focus:border-secondary border-[#D3E3F1] text-primary placeholder-[#7F9AB2]"
													/>
												</div>
											</div>
											{time[weekDay]?._id ? (
												<div className="bg-[#D3E3F1B2] text-primary grid auto-cols-auto grid-flow-col divide-x divide-gray-400 py-2 px-3 border-b border-border">
													<button
														className="cursor-pointer"
														onClick={() => updateSchedule(weekDay)}>
														{t(tKey('buttons.add'))}
													</button>
													<button
														className="cursor-pointer"
														onClick={() => deleteSchedule(weekDay)}>
														{t(tKey('buttons.clear'))}
													</button>
												</div>
											) : (
												<button
													onClick={() => addSchedule(weekDay)}
													className="bg-[#D3E3F1B2] w-full text-center text-primary py-2 px-3 border-b border-border">
													{t(tKey('buttons.add'))}
												</button>
											)}
										</Disclosure.Panel>
									</>
								)}
							</Disclosure>
						)
					})}
				</div>
			</div>
		</div>
	)
}
