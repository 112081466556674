import { DateTime } from 'luxon'

import { getAppLang } from 'utils/language'

interface DateCardProps {
	date: DateTime
	endDate?: DateTime
}

export const DateCard = ({ date, endDate }: DateCardProps) => {
	const appLanguage = getAppLang()
	return (
		<div className="rounded grow w-full max-md:w-[160px] px-2 md:px-4 shrink-0 h-[90px] relative flex items-center justify-center bg-primary text-white">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="162"
				height="89"
				viewBox="0 0 162 89"
				className="absolute top-0 right-0"
				fill="none">
				<circle opacity="0.05" cx="118" cy="-29.7092" r="118" fill="white" />
			</svg>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="202"
				height="29"
				viewBox="0 0 202 29"
				className="absolute bottom-0"
				fill="none">
				<circle opacity="0.05" cx="118" cy="118.291" r="118" fill="white" />
			</svg>
			<div className="flex gap-x-[11px] items-center">
				<h2 className="font-domine font-bold text-[40px]">{date.toFormat('dd')}</h2>
				<div className="flex flex-col gap-y-[9px] text-xs font-semibold">
					<p className="whitespace-nowrap">{date.setLocale(appLanguage).toFormat('LLLL, yyyy')}</p>
					<p>
						at{' '}
						{endDate
							? `${date.toFormat('HH:mm')} - ${endDate.toFormat('HH:mm')}`
							: date.toFormat('HH:mm')}
					</p>
				</div>
			</div>
		</div>
	)
}
