import clsx from 'clsx'
import { useAppSelector } from 'hooks'
import { DateTime } from 'luxon'
import { useDrag } from 'react-dnd'
import { useTranslation } from 'react-i18next'

interface EventCardProps {
	event: any
	handleEventSelection: any
	day: any
	isDragging: boolean
	setIsDragging: React.Dispatch<React.SetStateAction<boolean>>
}

const EventCard = ({
	event,
	handleEventSelection,
	day,
	isDragging,
	setIsDragging
}: EventCardProps) => {
	const { t } = useTranslation()
	const auth = useAppSelector(state => state.auth)

	const [{ isCurrentItemDragging }, dragRef] = useDrag(
		() => ({
			type: 'monthly',
			item: event,
			canDrag: () => {
				return (
					event.type === 'appointment' ||
					(event.type === 'event' && (event as any).addedBy._id === auth._id)
				)
			},
			isDragging: monitor => {
				setIsDragging(true)
				return monitor.getItem().id === event.id
			},
			collect: monitor => ({
				isCurrentItemDragging: monitor.isDragging()
			}),
			end: () => setIsDragging(false)
		}),
		[]
	)

	return (
		<li
			onClick={() => handleEventSelection(event, day.toISODate() as string)}
			className={clsx(
				'relative overflow-hidden cursor-pointer',
				isCurrentItemDragging || isDragging ? 'pointer-events-none' : 'pointer-events-auto'
			)}
			ref={dragRef}>
			<div
				style={{ background: event.color + '10', borderColor: event.color }}
				className="group border-l-[3px] pl-2 pr-1 py-1.5 flex flex-col">
				<p className="flex-auto text-primary font-semibold truncate text-[10px] leading-[10px] md:text-sm md:leading-[18px]">
					{event.name}
				</p>
				<time
					dateTime={event.startDateTime}
					className="flex max-md:hidden overflow-hidden whitespace-nowrap items-center gap-1.5 text-[#757575] text-xs">
					{event.allDay
						? t('calendar.eventDetails.labels.allDay')
						: DateTime.fromISO(event.startDateTime).toFormat('HH:mm')}
					<svg
						width="4"
						height="5"
						viewBox="0 0 4 5"
						fill="none"
						className="shrink-0"
						xmlns="http://www.w3.org/2000/svg">
						<circle cx="2" cy="2.55078" r="2" fill="#7F9AB2" />
					</svg>
					{(event as any).type === 'appointment'
						? (event as any).id_client.fname + ' ' + (event as any).id_client.lname
						: (event as any).type === 'event'
							? (event as any).addedBy.fname + ' ' + (event as any).addedBy.lname
							: (event as any).assignedBy.fname + ' ' + (event as any).assignedBy.lname}
				</time>
			</div>
		</li>
	)
}

export default EventCard
