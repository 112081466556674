import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { Spinner } from 'components/animations/spinner'
import { Button } from 'components/app/button'
import { Input } from 'components/inputs/input'
import { Select } from 'components/inputs/select'
import { mappedStatuses } from 'constants/constants'
import departmentService from 'services/department-service'
import { getTKey } from 'utils/language'

interface CreateDepartmentProps {
	departmentId?: string
	onCreation: () => void
	onCancel: () => void
}

export const CreateDepartment = ({ departmentId, onCreation, onCancel }: CreateDepartmentProps) => {
	const { t } = useTranslation()
	const tKey = getTKey('departments.create')

	const isEditForm = !!departmentId

	const [isLoading, setIsLoading] = useState(false)

	const schema = yup.object<{ department_name: string; is_active: string }>().shape({
		department_name: yup.string().required(t(tKey('errors.departmentName'))),
		is_active: yup.string().required(t(tKey('errors.status')))
	})

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors }
	} = useForm<{ department_name: string; is_active: string }>({
		resolver: yupResolver(schema as any),
		mode: 'all'
	})

	useEffect(() => {
		if (isEditForm) {
			departmentService.getDepartmentById(departmentId).then(res => reset(res))
		}
	}, [isEditForm])

	const onSubmit = (data: { department_name: string; is_active: string }) => {
		setIsLoading(true)

		if (isEditForm) {
			departmentService
				.updateDepartment(departmentId as string, data)
				.then(() => {
					toast.success(t(tKey('toast.updateSuccess')))
					onCreation()
				})
				.catch(() => toast.error(t(tKey('toast.updateError'))))
				.finally(() => setIsLoading(false))
		} else {
			departmentService
				.addDepartment(data)
				.then(() => {
					toast.success(t(tKey('toast.departmentSuccess')))
					onCreation()
				})
				.catch(() => toast.error(t(tKey('toast.departmentError'))))
				.finally(() => setIsLoading(false))
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="md:px-8 px-5 py-6">
			<div className="flex flex-col gap-y-5">
				<p className="text-primary text-sm md:text-base">{t(tKey('labels.departmentMessage'))}</p>

				<Input
					register={register}
					errors={errors}
					name="department_name"
					labelText={t(tKey('labels.departmentName'))}
				/>
				<Select
					name="is_active"
					labelText={t(tKey('labels.isActive'))}
					register={register}
					errors={errors}>
					{Object.values(mappedStatuses).map(status => (
						<option key={status} value={status.toLowerCase()}>
							{status}
						</option>
					))}
				</Select>

				<div className="flex gap-x-6 justify-end">
					<button type="button" onClick={onCancel} className="text-primary font-bold text-sm">
						{t(tKey('buttons.cancel'))}
					</button>
					<Button disabled={isLoading} className="text-sm font-bold">
						{isLoading ? (
							<div className="flex items-center justify-center gap-x-5">
								<Spinner />
								<span className="animate-pulse whitespace-nowrap">
									{t(tKey('buttons.pleaseWait'))}
								</span>
							</div>
						) : (
							<span>{isEditForm ? t(tKey('buttons.update')) : t(tKey('buttons.save'))}</span>
						)}
					</Button>
				</div>
			</div>
		</form>
	)
}
