import { useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { yupResolver } from '@hookform/resolvers/yup'
import clsx from 'clsx'
import * as yup from 'yup'

import { PlusIcon } from '@heroicons/react/24/outline'
import { Spinner } from 'components/animations/spinner'
import { Button } from 'components/app/button'
import { Input } from 'components/inputs/input'
import { Select } from 'components/inputs/select'
import { Required, mappedRequiredTypes } from 'constants/constants'
import { useAppSelector } from 'hooks'
import { ReportTabs } from 'pages/medicine/reports'
import medicalService from 'services/medical-service'
import questionService from 'services/question-service'
import { getTKey } from 'utils/language'

interface CreateQuestionProps {
	questionId?: string
	onCreation: () => void
	onCancel: () => void
}

export const CreateReport = ({ questionId, onCreation, onCancel }: CreateQuestionProps) => {
	const { t } = useTranslation()
	const [searchParams, _setSearchParams] = useSearchParams()

	const tKey = getTKey('reports.create')

	const activeTab = searchParams.get('tab') ?? ReportTabs.TREATMENT
	const isEditForm = !!questionId

	const auth = useAppSelector(state => state.auth)

	const [services, setServices] = useState<Service[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const dataTypes = [
		{ label: 'Input (Text)', value: 'text' },
		{ label: 'Boolean (Yes / No)', value: 'radio' },
		{ label: 'Select', value: 'select' },
		{ label: 'Number', value: 'number' },
		{ label: 'Date', value: 'date' },
		{ label: 'Email', value: 'email' },
		{ label: 'Phone', value: 'tel' },
		{ label: 'Textarea', value: 'textarea' }
	]

	const schema = yup.object<QuestionForm>().shape({
		id_service: yup.string().required(t(tKey('errors.service'))),
		label: yup.string().required(t(tKey('errors.questionLabel'))),
		description: yup.string().notRequired(),
		type: yup.string().required(t(tKey('errors.dataType'))),
		order_num: yup.string().required(t(tKey('errors.orderNumber'))),
		help_text: yup.string().notRequired(),
		is_required: yup.string().required(t(tKey('errors.isRequired'))),
		is_active: yup.string().required(t(tKey('errors.status'))),
		options: yup.array().when(['type'], ([type], schema) => {
			if (type === 'select') {
				return schema.of(
					yup.object().shape({
						label: yup.string().required(t(tKey('errors.selectLabel'))),
						value: yup.string().required(t(tKey('errors.selectValue')))
					})
				)
			}
			return schema
		})
	})

	const {
		register,
		handleSubmit,
		watch,
		reset,
		formState: { errors },
		control
	} = useForm<QuestionForm>({
		resolver: yupResolver(schema as any),
		mode: 'all'
	})

	const { fields, append, remove } = useFieldArray({ name: 'options', control })

	const selectedDataType = watch('type')

	useEffect(() => {
		if (selectedDataType === 'select') {
			append({ value: '', label: '' })
		} else {
			remove()
		}
	}, [selectedDataType])

	useEffect(() => {
		if (auth.companyId) {
			medicalService.getServiceByCompany(auth.companyId).then(res => setServices(res))
		}
		if (questionId) {
			questionService.getQuestionById(questionId).then(res =>
				reset({
					...res,
					type: res.type === 'input' ? res.input_type : res.type,
					order_num: res.order_num.toString(),
					group: res.group as QuestionForm['group']
				})
			)
		}
	}, [auth, questionId])

	const onSubmit = (data: QuestionForm) => {
		setIsLoading(true)
		const isInputType = data.type !== 'select' && data.type !== 'radio' && data.type !== 'textarea'
		const payload: QuestionForm = {
			...data,
			input_type: isInputType ? data.type : undefined,
			type: isInputType ? 'input' : data.type,
			group: activeTab === ReportTabs.TREATMENT ? 'treatment' : 'results',
			service: services.find(service => service._id === data.id_service) as Service
		}
		if (questionId) {
			questionService
				.updateQuestion(questionId, payload)
				.then(() => {
					toast.success(t(tKey('toast.updateSuccess')))
					onCreation()
				})
				.catch(() => toast.error(t(tKey('toast.updateError'))))
				.finally(() => setIsLoading(false))
		} else
			questionService
				.addCompanyQuestion(payload)
				.then(() => {
					toast.success(t(tKey('toast.addSuccess')))
					onCreation()
				})
				.catch(() => toast.error(t(tKey('toast.addError'))))
				.finally(() => setIsLoading(false))
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="md:px-8 max-md:px-5 py-6">
			<div className="flex flex-col gap-y-5">
				<p className={clsx('text-tertiary text-sm md:text-base', { hidden: isEditForm })}>
					{activeTab === ReportTabs.TREATMENT
						? t(tKey('labels.treatmentMessage'))
						: t(tKey('labels.diagnosticResultMessage'))}
				</p>
				<div className="grid grid-cols-1 items-start md:grid-cols-2 gap-y-5 md:grid-flow-col gap-x-5">
					<Select
						labelText={t(tKey('labels.service'))}
						name="id_service"
						register={register}
						errors={errors}>
						<option value="">{t(tKey('labels.selectService'))}</option>
						{services.map(service => (
							<option key={service._id} value={service._id}>
								{service.name}
							</option>
						))}
					</Select>
					<Input
						register={register}
						errors={errors}
						disabled={isEditForm}
						name="label"
						labelText={t(tKey('labels.questionLabel'))}
					/>
				</div>
				<textarea
					rows={3}
					{...register('description')}
					placeholder={t(tKey('labels.description'))}
					disabled={isEditForm}
					className="w-full rounded font-normal pl-4 py-3 bg-transparent focus:ring-0 border focus:border-secondary border-[#D3E3F1] text-primary placeholder-[#7F9AB2] placeholder:text-base focus:outline-none max-md:text-sm"
				/>
				<div className="grid grid-cols-1 items-start md:grid-cols-2 gap-y-5 md:grid-flow-col gap-x-5">
					<div className="flex flex-col gap-y-5">
						<Select
							labelText={t(tKey('labels.dataType'))}
							name="type"
							disabled={isEditForm}
							register={register}
							errors={errors}>
							<option value="">{t(tKey('labels.selectDataType'))}</option>
							{dataTypes.map(type => (
								<option key={type.value} value={type.value}>
									{type.label}
								</option>
							))}
						</Select>
						{selectedDataType === 'select' &&
							fields.map((field, index) => {
								return (
									<div key={field.id} className="flex w-full gap-x-3">
										<div className="flex grow flex-col gap-y-0.5">
											<Input
												type="text"
												disabled={isEditForm}
												labelText={t(tKey('labels.selectLabel'))}
												name={`options.${index}.label`}
												register={register}
												errors={errors}
											/>
											{errors?.['options']?.[index]?.label?.message && (
												<p className="text-xs text-red-500 mt-1">
													{errors?.['options']?.[index]?.label?.message}
												</p>
											)}
										</div>
										<div className="flex grow flex-col gap-y-0.5">
											<Input
												type="text"
												disabled={isEditForm}
												labelText={t(tKey('labels.selectValue'))}
												name={`options.${index}.value`}
												register={register}
												errors={errors}
											/>
											{errors?.['options']?.[index]?.value?.message && (
												<p className="text-xs text-red-500 mt-1">
													{errors?.['options']?.[index]?.value?.message}
												</p>
											)}
										</div>
										<div className="flex mt-2 gap-x-1">
											<svg
												onClick={() => {
													if (isEditForm || index === 0) return
													remove(index)
												}}
												xmlns="http://www.w3.org/2000/svg"
												width="20"
												height="20"
												viewBox="0 0 20 20"
												fill="none"
												className={clsx(
													'group shrink-0',
													isEditForm || index === 0 ? 'cursor-default' : 'cursor-pointer'
												)}>
												<path
													d="M6.08975 17.0832C5.67549 17.0832 5.32085 16.9356 5.02585 16.6406C4.73084 16.3456 4.58333 15.991 4.58333 15.5767V4.99984H4.375C4.19792 4.99984 4.04948 4.93992 3.92969 4.82007C3.8099 4.70024 3.75 4.55174 3.75 4.37457C3.75 4.19742 3.8099 4.04901 3.92969 3.92936C4.04948 3.8097 4.19792 3.74986 4.375 3.74986H7.49998C7.49998 3.54581 7.57183 3.37193 7.71552 3.22824C7.85922 3.08454 8.03309 3.0127 8.23715 3.0127H11.7628C11.9669 3.0127 12.1407 3.08454 12.2844 3.22824C12.4281 3.37193 12.5 3.54581 12.5 3.74986H15.625C15.802 3.74986 15.9505 3.80979 16.0703 3.92963C16.1901 4.04948 16.25 4.19798 16.25 4.37513C16.25 4.5523 16.1901 4.70071 16.0703 4.82036C15.9505 4.94001 15.802 4.99984 15.625 4.99984H15.4166V15.5767C15.4166 15.991 15.2691 16.3456 14.9741 16.6406C14.6791 16.9356 14.3245 17.0832 13.9102 17.0832H6.08975ZM14.1666 4.99984H5.83331V15.5767C5.83331 15.6515 5.85735 15.713 5.90544 15.7611C5.95352 15.8091 6.01496 15.8332 6.08975 15.8332H13.9102C13.985 15.8332 14.0464 15.8091 14.0945 15.7611C14.1426 15.713 14.1666 15.6515 14.1666 15.5767V4.99984ZM8.46181 14.1665C8.63897 14.1665 8.78737 14.1066 8.90702 13.9868C9.02669 13.867 9.08652 13.7186 9.08652 13.5415V7.29149C9.08652 7.11442 9.0266 6.96599 8.90675 6.8462C8.7869 6.7264 8.6384 6.66651 8.46125 6.66651C8.28408 6.66651 8.13567 6.7264 8.01602 6.8462C7.89637 6.96599 7.83654 7.11442 7.83654 7.29149V13.5415C7.83654 13.7186 7.89647 13.867 8.01631 13.9868C8.13615 14.1066 8.28465 14.1665 8.46181 14.1665ZM11.5387 14.1665C11.7159 14.1665 11.8643 14.1066 11.9839 13.9868C12.1036 13.867 12.1634 13.7186 12.1634 13.5415V7.29149C12.1634 7.11442 12.1035 6.96599 11.9836 6.8462C11.8638 6.7264 11.7153 6.66651 11.5381 6.66651C11.361 6.66651 11.2126 6.7264 11.0929 6.8462C10.9733 6.96599 10.9134 7.11442 10.9134 7.29149V13.5415C10.9134 13.7186 10.9734 13.867 11.0932 13.9868C11.2131 14.1066 11.3616 14.1665 11.5387 14.1665Z"
													fill="#F84C6B"
													className={clsx('fill-[#7F9AB2]', {
														'group-hover:fill-[#F84C6B]': !isEditForm && index > 0
													})}
												/>
											</svg>
											<PlusIcon
												onClick={() => {
													if (isEditForm) return
													append({
														label: '',
														value: ''
													})
												}}
												className={clsx(
													'text-[#7F9AB2] h-5 w-5 shrink-0',
													isEditForm ? 'cursor-default' : 'cursor-pointer hover:text-primary'
												)}
											/>
										</div>
									</div>
								)
							})}
					</div>
					<Input
						register={register}
						errors={errors}
						inputMode="numeric"
						labelText={t(tKey('labels.orderNumber'))}
						name="order_num"
						type="number"
						min="0"
					/>
				</div>

				<div className="grid grid-cols-1 md:grid-cols-2 gap-y-5 gap-x-5">
					<Input
						register={register}
						errors={errors}
						name="help_text"
						labelText={t(tKey('labels.helpText'))}
					/>
					<Select
						labelText={t(tKey('labels.requiredField'))}
						name="is_required"
						register={register}
						errors={errors}>
						<option value="">{t(tKey('labels.selectRequiredField'))}</option>
						<option value="yes">{mappedRequiredTypes[Required.YES]}</option>
						<option value="no">{mappedRequiredTypes[Required.NO]}</option>
					</Select>
				</div>
				<div className="grid grid-cols-1 md:grid-cols-2 gap-y-5 md:grid-flow-col gap-x-5">
					<Select
						labelText={t(tKey('labels.status'))}
						name="is_active"
						register={register}
						errors={errors}>
						<option value="">{t(tKey('labels.status'))}</option>
						<option value="aktiv">{t(tKey('labels.active'))}</option>
						<option value="inaktiv">{t(tKey('labels.inActive'))}</option>
					</Select>
				</div>
				<div className="flex gap-x-6 justify-end">
					<button type="button" onClick={onCancel} className="text-primary font-bold text-sm">
						{t(tKey('buttons.cancel'))}
					</button>
					<Button disabled={isLoading} className="text-sm font-bold">
						{isLoading ? (
							<div className="flex items-center justify-center gap-x-5">
								<Spinner />
								<span className="animate-pulse whitespace-nowrap">
									{t(tKey('buttons.pleaseWait'))}
								</span>
							</div>
						) : (
							<span>{questionId ? t(tKey('buttons.edit')) : t(tKey('buttons.add'))}</span>
						)}
					</Button>
				</div>
			</div>
		</form>
	)
}
