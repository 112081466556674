// eslint-disable-next-line
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line
import authService from 'services/auth-service';

export const login = createAsyncThunk(
	'auth/login',
	async (user: { username: string; otp: string }, thunkAPI) => {
		try {
			const response = await authService.userVerification(user.username, user.otp)
			if (response.accessToken) {
				return thunkAPI.fulfillWithValue({ ...response })
			}
			return thunkAPI.rejectWithValue(false)
		} catch (error: any) {
			return thunkAPI.rejectWithValue(false)
		}
	}
)

export const demoLogin = createAsyncThunk(
	'auth/demo-login',
	async (user: { email: string; password: string }, thunkAPI) => {
		try {
			const response = await authService.login(user.email, user.password)
			if (response.accessToken) {
				return thunkAPI.fulfillWithValue({ ...response })
			}
			return thunkAPI.rejectWithValue(false)
		} catch (error: any) {
			return thunkAPI.rejectWithValue(false)
		}
	}
)

const initialState = {} as AuthState

const authSlice = createSlice({
	name: 'auth',
	initialState: initialState,
	reducers: {
		updateCompany(state, action: PayloadAction<AuthState['company']>) {
			return { ...state, company: action.payload }
		},
		updateUserProfile(state, action: PayloadAction<AuthState['profile_url']>) {
			return { ...state, profile_url: action.payload }
		},
		logOut(state) {
			return initialState
		}
	},
	extraReducers: builder => {
		// Add reducers for additional action types here, and handle loading state as needed
		builder.addCase(login.fulfilled, (state: AuthState, action: PayloadAction<AuthState>) => {
			const {
				_id,
				email,
				accessToken,
				address,
				phone,
				gender,
				employee_id,
				is_adminUser,
				roles,
				fname,
				lname,
				companyId,
				profile_url,
				company
			} = action.payload
			return {
				_id,
				fname,
				lname,
				address,
				phone,
				gender,
				employee_id,
				email,
				companyId,
				company,
				is_adminUser,
				roles,
				profile_url,
				accessToken
			}
		}),
			builder.addCase(login.rejected, (state: AuthState, action: PayloadAction<any>) => {
				return initialState
			}),
			builder.addCase(demoLogin.fulfilled, (state: AuthState, action: PayloadAction<AuthState>) => {
				const {
					_id,
					email,
					accessToken,
					address,
					phone,
					gender,
					employee_id,
					is_adminUser,
					roles,
					fname,
					lname,
					companyId,
					company
				} = action.payload
				return {
					_id,
					fname,
					lname,
					address,
					phone,
					gender,
					employee_id,
					email,
					companyId,
					company,
					is_adminUser,
					roles,
					accessToken
				}
			}),
			builder.addCase(demoLogin.rejected, (state: AuthState, action: PayloadAction<any>) => {
				return initialState
			})
	}
})

const { reducer } = authSlice

export const { updateCompany, updateUserProfile, logOut } = authSlice.actions

export default reducer
