import axios from 'services/axios'

const getDepartments = (): Promise<Department[]> => {
	return axios.get('/departments').then(response => response.data)
}

const getDepartmentById = (departmentId: string): Promise<Department> => {
	return axios.get(`/departments/${departmentId}`).then(response => response.data)
}

const updateDepartment = (
	departmentId: string,
	data: { department_name: string; is_active: string }
) => {
	return axios.put(`/departments/${departmentId}`, data).then(response => response.data)
}

const addDepartment = (data: { department_name: string; is_active: string }) => {
	return axios.post('/departments', data).then(response => response.data)
}

const deleteDepartment = (departmentId: string) => {
	return axios.delete(`/departments/${departmentId}`).then(response => response.data)
}

const departmentService = {
	getDepartments,
	getDepartmentById,
	addDepartment,
	updateDepartment,
	deleteDepartment
}

export default departmentService
