import { useEffect, useState } from 'react'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import clsx from 'clsx'
import { DateTime } from 'luxon'

import { ProfilePhotoIcon } from 'assets/icons'
import { AppLayout } from 'components/app/layout'
import { MenuDropdown } from 'components/app/menu'
import { Table } from 'components/app/table'
import { DateCard } from 'components/cards/date-card'
import { StatsCard } from 'components/cards/stats-cards'
import {
    AppointmentStatus,
    mappedAppointmentStatuses,
    mappedTaskStatus,
    TaskStatus
} from 'constants/constants'
import { useAppSelector } from 'hooks'
import useIsMobile from 'hooks/useIsMobile'
import appointmentService from 'services/appointment-service'
import categoryService from 'services/category-service'
import companyService from 'services/company-service'
import eventService from 'services/event-service'
import taskService from 'services/task-service'
import userService from 'services/user-service'
import { getCurrentMonthRange, getCurrentWeekRange } from 'utils/date'
import { getAppLang, getTKey } from 'utils/language'

export const Dashboard = () => {
	const { i18n, t } = useTranslation()
	const isMobile = useIsMobile()
	const navigate = useNavigate()

	const tKey = getTKey('dashboard')
	const appLanguage = getAppLang()

	const auth = useAppSelector(state => state.auth)

	const [appointmentStats, setAppointmentStats] = useState<
		{ title: string; value: number; link: string }[]
	>([])
	const [taskStats, setTaskStats] = useState<{ title: string; value: number; link: string }[]>([])
	const [eventStats, setEventStats] = useState<{ title: string; value: number; link: string }[]>([])
	const [companyAppointments, setCompanyAppointments] = useState<Appointment[]>([])
	const [allAppointments, setAllAppointments] = useState<Appointment[]>([])
	const [companyCategories, setCompanyCategories] = useState<Category[]>([])
	const [calendarValue, setCalendarValue] = useState<CalendarValue>(getCurrentWeekRange())
	const [selectedRange, setSelectedRange] = useState(t(tKey('menu.thisWeek')))
	const [users, setUsers] = useState<User[]>([])
	const [subCompanies, setSubCompanies] = useState<CompanyWithStats[]>([])

	useEffect(() => {
		userService.getCompanyUsers().then(res => setUsers(res.users))
		if (auth.is_adminUser === true) {
			appointmentService.getCompanyAppointments(auth.companyId).then(res => setAllAppointments(res))
		} else {
			appointmentService.getCompanyAppointments(auth.companyId).then(res => setAllAppointments(res))
		}
		if (auth.is_adminUser === true) {
			categoryService
				.getCompanyCategories()
				.then(res => setCompanyCategories(res.filter(r => r.is_active === 'aktiv')))
		} else {
			categoryService
				.getCompanyCategoriesForEmployee()
				.then(res => setCompanyCategories(res.filter(r => r.is_active === 'aktiv')))
		}
		if (auth.companyId && auth.roles.includes('ROLE_SUPERADMIN')) {
			companyService.getSubCompanies(auth.companyId).then(res => setSubCompanies(res))
		}
	}, [auth])

	useEffect(() => {
		const from = DateTime.fromJSDate(
			Array.isArray(calendarValue) ? (calendarValue[0] as Date) : (calendarValue as Date)
		)
		const to = DateTime.fromJSDate(
			Array.isArray(calendarValue) ? (calendarValue[1] as Date) : (calendarValue as Date)
		)

		if (selectedRange !== 'allTime') {
			if (auth.companyId) {
				appointmentService
					.getCompanyAppointmentsByTime(
						auth.companyId,
						from.startOf('day').toMillis(),
						to.endOf('day').toMillis()
					)
					.then(res => setCompanyAppointments(res))
			}
			appointmentService
				.getAppointmentStatsByTime(from.startOf('day').toMillis(), to.endOf('day').toMillis())
				.then(res =>
					setAppointmentStats([
						{
							title: t(tKey('stats.labels.totalAppointments')),
							value: res?.totalAppointment as number,
							link: '/appointments'
						},
						{
							title: t(tKey('stats.labels.cancelledAppointments')),
							value: res?.canceled as number,
							link: `/appointments?status=${mappedAppointmentStatuses[AppointmentStatus.CANCELLED]}`
						},
						{
							title: t(tKey('stats.labels.pendingAppointments')),
							value: res?.pendent as number,
							link: `/appointments?status=${mappedAppointmentStatuses[AppointmentStatus.PENDENT]}`
						},
						{
							title: t(tKey('stats.labels.completeAppointments')),
							value: res?.schlusskontrolle as number,
							link: `/appointments?status=${mappedAppointmentStatuses[AppointmentStatus.COMPLETED]}`
						}
					])
				)

			eventService
				.getEventStatsByTime(from.startOf('day').toMillis(), to.endOf('day').toMillis())
				.then(res =>
					setEventStats([
						{
							title: t(tKey('stats.labels.upcomingEvents')),
							value: res?.upcomingCount as number,
							link: '/events'
						},
						{
							title: t(tKey('stats.labels.pastEvents')),
							value: res?.pastCount as number,
							link: `/events`
						}
					])
				)

			taskService
				.getTaskStatsByTime(from.startOf('day').toMillis(), to.endOf('day').toMillis())
				.then(res =>
					setTaskStats([
						{
							title: t(tKey('stats.labels.backlog')),
							value: res?.notStarted as number,
							link: `/task-manager?status=${mappedTaskStatus[TaskStatus.NOTSTARTED]}`
						},
						{
							title: t(tKey('stats.labels.inProgress')),
							value: res?.inProgress as number,
							link: `/task-manager?status=${mappedTaskStatus[TaskStatus.INPROGRESS]}`
						},
						{
							title: t(tKey('stats.labels.completed')),
							value: res?.completed as number,
							link: `/task-manager?status=${mappedTaskStatus[TaskStatus.COMPLETED]}`
						}
					])
				)
		}
	}, [calendarValue, auth])

	useEffect(() => {
		if (selectedRange === 'allTime') {
			appointmentService.getAppointmentStats().then(res =>
				setAppointmentStats([
					{
						title: t(tKey('stats.labels.totalAppointments')),
						value: res?.totalAppointment as number,
						link: '/appointments'
					},
					{
						title: t(tKey('stats.labels.cancelledAppointments')),
						value: res?.canceled as number,
						link: `/appointments?status=${mappedAppointmentStatuses[AppointmentStatus.CANCELLED]}`
					},
					{
						title: t(tKey('stats.labels.pendingAppointments')),
						value: res?.pendent as number,
						link: `/appointments?status=${mappedAppointmentStatuses[AppointmentStatus.PENDENT]}`
					},
					{
						title: t(tKey('stats.labels.completeAppointments')),
						value: res?.abschluss as number,
						link: `/appointments?status=${mappedAppointmentStatuses[AppointmentStatus.COMPLETED]}`
					}
				])
			)
			eventService.getEventStatsByTime().then(res =>
				setEventStats([
					{
						title: t(tKey('stats.labels.upcomingEvents')),
						value: res?.upcomingCount as number,
						link: '/events'
					},
					{
						title: t(tKey('stats.labels.pastEvents')),
						value: res?.pastCount as number,
						link: `/events`
					}
				])
			)
			taskService.getTaskStatsByTime().then(res =>
				setTaskStats([
					{
						title: t(tKey('stats.labels.backlog')),
						value: res?.notStarted as number,
						link: `/task-manager?status=${mappedTaskStatus[TaskStatus.NOTSTARTED]}`
					},
					{
						title: t(tKey('stats.labels.inProgress')),
						value: res?.inProgress as number,
						link: `/task-manager?status=${mappedTaskStatus[TaskStatus.INPROGRESS]}`
					},
					{
						title: t(tKey('stats.labels.completed')),
						value: res?.completed as number,
						link: `/task-manager?status=${mappedTaskStatus[TaskStatus.COMPLETED]}`
					}
				])
			)
			if (auth.is_adminUser === true) {
				appointmentService
					.getCompanyAppointments(auth.companyId)
					.then(res => setCompanyAppointments(res))
			} else {
				appointmentService
					.getCompanyAppointments(auth.companyId)
					.then(res => setCompanyAppointments(res))
			}
		}
		if (selectedRange === t(tKey('menu.today'))) {
			return setCalendarValue(new Date())
		}
		if (selectedRange === t(tKey('menu.thisWeek'))) {
			return setCalendarValue(getCurrentWeekRange())
		}
		if (selectedRange === t(tKey('menu.thisMonth'))) {
			return setCalendarValue(getCurrentMonthRange())
		}
	}, [selectedRange])

	const columns = [
		{
			header: 'Id',
			accessorKey: 'order_num',
			cell: (data: Cell) => (
				<div className="flex gap-x-3 items-center">
					<img
						src={
							data.row.original.profile_url === 'health_corner'
								? ProfilePhotoIcon
								: data.row.original.profile_url
						}
						className="h-6 w-6 object-cover rounded-full"
					/>
					{data.getValue()?.toString().padStart(12, '0')}
				</div>
			)
		},
		{
			header: t(tKey('tables.fullName')),
			accessorFn: (row: User) => `${row.fname} ${row.lname}`
		},
		{
			header: t(tKey('tables.birthday')),
			accessorKey: 'birthDate',
			cell: (date: Cell) =>
				DateTime.fromMillis(Number(date.getValue())).setLocale(appLanguage).toFormat('dd.LL.yyyy')
		},
		{
			header: t(tKey('tables.phone')),
			accessorKey: 'phone'
		}
	]

	const subCompaniesColumns = [
		{
			header: 'Id',
			accessorKey: 'index',
			cell: (data: Cell) => <p className="text-sm text-primary text-center">{data.getValue()}</p>
		},
		{
			header: t('subCompanies.tables.name'),
			accessorKey: 'name',
			cell: (data: Cell) => <p className="text-sm text-primary text-center">{data.getValue()}</p>
		},
		{
			header: t('subCompanies.tables.appointments'),
			accessorKey: 'appointments',
			cell: (data: Cell) => <p className="text-sm text-primary text-center">{data.getValue()}</p>
		},
		{
			header: t('subCompanies.tables.employees'),
			accessorKey: 'employees',
			cell: (data: Cell) => <p className="text-primary text-sm text-center">{data.getValue()}</p>
		},
		{
			header: t('subCompanies.tables.clients'),
			accessorKey: 'clients',
			cell: (data: Cell) => <p className="text-primary text-sm text-center">{data.getValue()}</p>
		}
	]

	return (
		<AppLayout title={t(tKey('titles.dashboard'))} isHeaderVisible={!isMobile}>
			<div className="flex items-center justify-between max-md:p-5 md:px-6 md:pt-6">
				<h2 className="px-2 text-primary font-domine md:text-lg font-bold leading-5">
					{t(tKey('titles.overview'))}
				</h2>
				<MenuDropdown
					options={[
						'allTime',
						t(tKey('menu.today')),
						t(tKey('menu.thisWeek')),
						t(tKey('menu.thisMonth'))
					]}
					value={selectedRange}
					onChange={(value: string) => setSelectedRange(value)}
				/>
			</div>
			<div className="max-md:px-5 max-md:pb-5 md:p-6 md:flex md:gap-x-8">
				<div className="basis-full md:basis-[75%]">
					<div className="flex flex-col gap-y-8">
						<div className="flex flex-col gap-y-2">
							<h2 className="px-2 text-primary font-domine md:text-md font-bold leading-5">
								{t(tKey('titles.appointmentStats'))}
							</h2>
							<div className="md:flex max-md:grid max-md:grid-cols-2 max-md:grid-rows-2 gap-[11px] md:gap-x-8">
								{appointmentStats.map((appointmentStat, index) => (
									<StatsCard
										key={appointmentStat.title}
										title={appointmentStat.title}
										value={appointmentStat.value}
										link={appointmentStat.link}
										className={clsx(index % 2 === 0 ? 'bg-[#B790D852]' : 'bg-[#1232582E]')}
									/>
								))}
							</div>
						</div>
						<div className="flex flex-col gap-y-2">
							<h2 className="px-2 text-primary font-domine md:text-md font-bold leading-5">
								{t(tKey('titles.eventStats'))}
							</h2>
							<div className="md:flex max-md:grid max-md:grid-cols-2 max-md:grid-rows-1 gap-[11px] md:gap-x-8">
								{eventStats.map((eventStat, index) => (
									<StatsCard
										key={eventStat.title}
										title={eventStat.title}
										value={eventStat.value}
										link={eventStat.link}
										className={clsx(index % 2 === 0 ? 'bg-[#B790D852]' : 'bg-[#1232582E]')}
									/>
								))}
							</div>
						</div>
						<div className="flex flex-col gap-y-2">
							<h2 className="px-2 text-primary font-domine md:text-md font-bold leading-5">
								{t(tKey('titles.taskStats'))}
							</h2>
							<div className="md:flex max-md:grid max-md:grid-cols-2 max-md:grid-rows-2 gap-[11px] md:gap-x-8">
								{taskStats.map((taskStat, index) => (
									<StatsCard
										key={taskStat.title}
										title={taskStat.title}
										value={taskStat.value}
										link={taskStat.link}
										className={clsx(index % 2 === 0 ? 'bg-[#B790D852]' : 'bg-[#1232582E]')}
									/>
								))}
							</div>
						</div>
						<h2 className="text-primary font-domine md:text-lg font-bold leading-5">
							{t(tKey('titles.categories'))}
						</h2>
						<div className="flex flex-wrap items-start gap-3.5">
							{companyCategories
								.filter(category => category.is_active === 'aktiv')
								.map(category => (
									<div
										key={category._id}
										onClick={() => navigate(`/appointments?category=${category.category_name}`)}
										className="py-5 px-3.5 cursor-pointer grow max-md:text-xs text-center font-semibold rounded-lg text-primary bg-background hover:bg-slate-200">
										{category.category_name}
									</div>
								))}
						</div>
						<div className="md:hidden gap-y-4 flex flex-col md:bg-background rounded-2xl">
							<h2 className="text-primary whitespace-nowrap font-domine md:text-lg font-bold leading-5">
								{t(tKey('titles.upcomingAppointments'))}
							</h2>
							<div className="flex flex-col gap-y-5">
								{companyAppointments.filter(
									appointment =>
										DateTime.fromMillis(appointment.from) > DateTime.now() &&
										appointment.status !== AppointmentStatus.CANCELLED
								).length > 0 ? (
									companyAppointments
										.filter(appointment => DateTime.fromMillis(appointment.from) > DateTime.now())
										.slice(0, 2)
										.sort((a, b) => a.from - b.from)
										.map(appointment => (
											<div
												key={appointment._id}
												style={{ boxShadow: '0px 4px 24px 0px rgba(18, 50, 88, 0.12)' }}
												className="flex items-center bg-white gap-x-3.5 p-3 rounded-lg">
												<DateCard date={DateTime.fromMillis(appointment.from)} />
												<div className="flex w-full flex-col gap-y-[6.5px]">
													<p className="text-secondary text-xs sm:text-sm">
														{appointment.service_name}
													</p>
													<div className="flex items-center gap-x-1.5">
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="14"
															height="15"
															viewBox="0 0 14 15"
															fill="none"
															className="shrink-0">
															<path
																d="M7.00033 6.93147C6.51571 6.93147 6.10289 6.76096 5.76187 6.41993C5.42084 6.07891 5.25033 5.66609 5.25033 5.18147C5.25033 4.69685 5.42084 4.28402 5.76187 3.943C6.10289 3.60197 6.51571 3.43146 7.00033 3.43146C7.48494 3.43146 7.89776 3.60197 8.23879 3.943C8.57981 4.28402 8.75033 4.69685 8.75033 5.18147C8.75033 5.66609 8.57981 6.07891 8.23879 6.41993C7.89776 6.76096 7.48494 6.93147 7.00033 6.93147ZM2.91699 10.5436V10.1892C2.91699 9.94835 2.9871 9.72305 3.12733 9.51328C3.26755 9.3035 3.4562 9.14065 3.69328 9.02473C4.2437 8.76074 4.7945 8.56274 5.34567 8.43074C5.89685 8.29874 6.44841 8.23274 7.00033 8.23274C7.55225 8.23274 8.1038 8.29874 8.65498 8.43074C9.20615 8.56274 9.75695 8.76074 10.3074 9.02473C10.5445 9.14065 10.7331 9.3035 10.8733 9.51328C11.0135 9.72305 11.0837 9.94835 11.0837 10.1892V10.5436C11.0837 10.7149 11.0255 10.8587 10.9092 10.975C10.7929 11.0913 10.6492 11.1494 10.4779 11.1494H3.52275C3.3515 11.1494 3.20772 11.0913 3.09142 10.975C2.97514 10.8587 2.91699 10.7149 2.91699 10.5436Z"
																fill="#4C6780"
															/>
														</svg>
														<p className="text-xs sm:text-sm text-tertiary">
															{appointment.id_client.fname + ' ' + appointment.id_client.lname}
														</p>
													</div>
													<button
														onClick={() => navigate(`/appointment-details/${appointment._id}`)}
														className="py-2 text-[10px] sm:text-sm font-bold text-primary border border-primary rounded w-full">
														{t(tKey('buttons.details'))}
													</button>
												</div>
											</div>
										))
								) : (
									<p className="text-tertiary text-sm">{t(tKey('noAppointments'))}</p>
								)}
							</div>
						</div>
						{auth.roles.includes('ROLE_SUPERADMIN') && (
							<div className="flex flex-col gap-y-4">
								<div className="flex items-start justify-between">
									<h2 className="text-primary font-domine md:text-lg font-bold leading-5">
										{auth.roles.includes('ROLE_SUPERADMIN')
											? t(tKey('titles.subCompaniesStats'))
											: t(tKey('titles.clients'))}
									</h2>
									<Link
										className="underline text-primary text-sm font-bold"
										to={
											auth.roles.includes('ROLE_SUPERADMIN') ? '/subcompanies/company' : '/clients'
										}>
										{t(tKey('viewAll'))}
									</Link>
								</div>
								<Table
									data={
										auth.roles.includes('ROLE_SUPERADMIN')
											? subCompanies
													.slice(0, 10)
													.map((subCompany, index) => ({ ...subCompany, index: index + 1 }))
											: users.slice(0, 10)
									}
									shouldRedirect={!auth.roles.includes('ROLE_SUPERADMIN') ? 'client' : undefined}
									columns={auth.roles.includes('ROLE_SUPERADMIN') ? subCompaniesColumns : columns}
									enableSorting={false}
									centeredHeadings={auth.roles.includes('ROLE_SUPERADMIN')}
									enablePagination={false}
								/>
							</div>
						)}
					</div>
				</div>
				<div className="max-md:hidden basis-[25%] flex flex-col gap-y-8">
					<Calendar
						className="rounded-2xl w-fit p-4 calendar-icon"
						onChange={setCalendarValue}
						selectRange
						goToRangeStartOnSelect={false}
						tileDisabled={() => selectedRange === 'allTime'}
						tileClassName={({ date }) => {
							const currentDate = DateTime.fromJSDate(date)
							if (
								DateTime.fromJSDate((calendarValue as any)?.[0]) <= currentDate &&
								currentDate <= DateTime.fromJSDate((calendarValue as any)?.[1])
							) {
								return 'text-white bg-secondary font-medium'
							}
							if (DateTime.fromJSDate(calendarValue as any).hasSame(currentDate, 'day')) {
								return 'bg-secondary text-white font-medium'
							}
							if (
								allAppointments.some(appointment =>
									DateTime.fromMillis(appointment.from).hasSame(currentDate, 'day')
								)
							) {
								return 'text-green-700 font-semibold'
							}
							return 'text-primary'
						}}
						locale={i18n.language}
						value={calendarValue}
					/>
					<div className="py-6 px-4 gap-y-4 flex flex-col bg-background rounded-2xl">
						<h2 className="text-primary font-domine text-lg font-bold leading-5">
							{t(tKey('titles.upcomingAppointments'))}
						</h2>
						<div className="flex flex-col gap-y-5">
							{companyAppointments.filter(
								appointment =>
									DateTime.fromMillis(appointment.from) > DateTime.now() &&
									appointment.status !== AppointmentStatus.CANCELLED
							).length > 0 ? (
								companyAppointments
									.filter(appointment => DateTime.fromMillis(appointment.from) > DateTime.now())
									.slice(0, 2)
									.sort((a, b) => a.from - b.from)
									.map(appointment => (
										<div
											key={appointment._id}
											style={{ boxShadow: '0px 4px 24px 0px rgba(18, 50, 88, 0.12)' }}
											className="flex bg-white flex-col gap-y-3.5 p-3 rounded-lg">
											<DateCard date={DateTime.fromMillis(appointment.from)} />
											<div className="flex flex-col gap-y-1.5 items-center">
												<p className="text-secondary text-sm">{appointment.service_name}</p>
												<div className="flex items-center gap-x-1.5">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="14"
														height="15"
														viewBox="0 0 14 15"
														fill="none">
														<path
															d="M7.00033 6.93147C6.51571 6.93147 6.10289 6.76096 5.76187 6.41993C5.42084 6.07891 5.25033 5.66609 5.25033 5.18147C5.25033 4.69685 5.42084 4.28402 5.76187 3.943C6.10289 3.60197 6.51571 3.43146 7.00033 3.43146C7.48494 3.43146 7.89776 3.60197 8.23879 3.943C8.57981 4.28402 8.75033 4.69685 8.75033 5.18147C8.75033 5.66609 8.57981 6.07891 8.23879 6.41993C7.89776 6.76096 7.48494 6.93147 7.00033 6.93147ZM2.91699 10.5436V10.1892C2.91699 9.94835 2.9871 9.72305 3.12733 9.51328C3.26755 9.3035 3.4562 9.14065 3.69328 9.02473C4.2437 8.76074 4.7945 8.56274 5.34567 8.43074C5.89685 8.29874 6.44841 8.23274 7.00033 8.23274C7.55225 8.23274 8.1038 8.29874 8.65498 8.43074C9.20615 8.56274 9.75695 8.76074 10.3074 9.02473C10.5445 9.14065 10.7331 9.3035 10.8733 9.51328C11.0135 9.72305 11.0837 9.94835 11.0837 10.1892V10.5436C11.0837 10.7149 11.0255 10.8587 10.9092 10.975C10.7929 11.0913 10.6492 11.1494 10.4779 11.1494H3.52275C3.3515 11.1494 3.20772 11.0913 3.09142 10.975C2.97514 10.8587 2.91699 10.7149 2.91699 10.5436Z"
															fill="#4C6780"
														/>
													</svg>
													<p className="text-sm text-tertiary">
														{appointment.id_client.fname + ' ' + appointment.id_client.lname}
													</p>
												</div>
												<button
													onClick={() => navigate(`/appointment-details/${appointment._id}`)}
													className="py-2 text-sm font-bold text-primary border border-primary rounded w-full">
													{t(tKey('buttons.details'))}
												</button>
											</div>
										</div>
									))
							) : (
								<p className="text-tertiary text-sm">{t(tKey('noAppointments'))}</p>
							)}
						</div>
						<Link
							className="text-primary underline font-domine font-bold text-center"
							to="/appointments?type=upcoming">
							{t(tKey('viewAll'))}
						</Link>
					</div>
				</div>
			</div>
		</AppLayout>
	)
}
