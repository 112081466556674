import React, { InputHTMLAttributes, useState } from 'react'

import { CameraIcon, ProfilePhotoIcon } from 'assets/icons'
import clsx from 'clsx'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	name: string
	image?: string
	variant: 'logo' | 'cover'
	onUpload?: (file: File) => void
}

export const ImageUpload = ({ name, image, variant, onUpload }: InputProps) => {
	const [file, setFile] = useState<File | null>(null)

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { files } = e.target
		if (!files || files.length === 0) {
			setFile(null)
		} else {
			setFile(files[0])
			onUpload?.(files[0])
		}
	}

	return (
		<div className={clsx('w-full')}>
			<div className="relative">
				<input
					type="file"
					className="hidden w-full appearance-none"
					accept=".jpg,.jpeg,.png"
					name={name}
					onChange={handleInputChange}
				/>
				<div
					className="relative w-full flex flex-col cursor-pointer justify-end items-end text-white rounded-full w-30 h-30"
					onClick={() => {
						const fileInput = document.querySelector(`[name=${name}]`) as HTMLInputElement
						fileInput.click()
					}}>
					{file ? (
						<img
							src={URL.createObjectURL(file)}
							alt="profile-image"
							className={clsx('object-cover rounded-lg', {
								'md:w-[155px] max-md:border max-md:border-[#0000001A] w-[130px] h-[120px] md:h-[155px]':
									variant === 'logo',
								'grow min-w-full h-[120px] md:h-[155px]': variant === 'cover'
							})}
						/>
					) : image ? (
						<img
							src={image}
							className={clsx(
								'rounded-lg w-full h-full object-cover',
								variant === 'logo'
									? 'w-[130px] max-h-[120px] min-h-[120px] md:max-h-[155px] md:min-h-[155px] md:w-[155px]'
									: 'w-full max-h-[120px] min-h-[120px] md:min-h-[155px] md:max-h-[155px]'
							)}
						/>
					) : (
						<div
							className={clsx(
								'flex w-full items-center justify-center border border-[#EEEEEE80] rounded-lg',
								{
									'md:w-[155px] w-[101px] h-[101px] max-md:border max-md:border-[#0000001A]  md:h-[155px] bg-white md:bg-[#F5F5F5] ':
										variant === 'logo',
									'grow w-full h-[120px] md:h-[155px] bg-[#F5F5F5] ': variant === 'cover'
								}
							)}>
							<p className="text-[#4C6780B2] font-md text-xl">
								{variant === 'logo' ? (
									<span className="flex flex-col items-center">
										Logo <span className="text-sm">400 by 400px</span>
									</span>
								) : (
									<span className="flex flex-col items-center">
										Cover Photo <span className="text-sm">1440 by 200px</span>
									</span>
								)}
							</p>
						</div>
					)}
					<div className="absolute -translate-x-3 translate-y-3.5 rounded-full p-2 bg-primary border border-white">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="18"
							height="18"
							viewBox="0 0 18 18"
							fill="none">
							<g clipPath="url(#clip0_3294_13583)">
								<path
									d="M15.75 4.5H13.3725L12 3H7.5V4.5H11.34L12.7125 6H15.75V15H3.75V8.25H2.25V15C2.25 15.825 2.925 16.5 3.75 16.5H15.75C16.575 16.5 17.25 15.825 17.25 15V6C17.25 5.175 16.575 4.5 15.75 4.5ZM6 10.5C6 12.57 7.68 14.25 9.75 14.25C11.82 14.25 13.5 12.57 13.5 10.5C13.5 8.43 11.82 6.75 9.75 6.75C7.68 6.75 6 8.43 6 10.5ZM9.75 8.25C10.9875 8.25 12 9.2625 12 10.5C12 11.7375 10.9875 12.75 9.75 12.75C8.5125 12.75 7.5 11.7375 7.5 10.5C7.5 9.2625 8.5125 8.25 9.75 8.25ZM3.75 4.5H6V3H3.75V0.75H2.25V3H0V4.5H2.25V6.75H3.75V4.5Z"
									fill="white"
								/>
							</g>
							<defs>
								<clipPath id="clip0_3294_13583">
									<rect width="18" height="18" fill="white" />
								</clipPath>
							</defs>
						</svg>
					</div>
				</div>
			</div>
		</div>
	)
}

interface ProfileInputProps extends InputHTMLAttributes<HTMLInputElement> {
	name: string
	image?: string
	onUpload?: (file: File) => void
}

export const ProfileImageUpload = ({ name, image, onUpload }: ProfileInputProps) => {
	const [file, setFile] = useState<File | null>(null)

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { files } = e.target
		if (!files || files.length === 0) {
			setFile(null)
		} else {
			setFile(files[0])
			onUpload?.(files[0])
		}
	}

	return (
		<div>
			<div className="relative">
				<input
					type="file"
					className="hidden w-full appearance-none"
					accept=".jpg,.jpeg,.png"
					name={name}
					onChange={handleInputChange}
				/>
				<div
					className="relative flex flex-col cursor-pointer mx-auto justify-center items-center text-white rounded-full w-30 h-30"
					onClick={() => {
						const fileInput = document.querySelector(`[name=${name}]`) as HTMLInputElement
						fileInput.click()
					}}>
					{file ? (
						<img
							src={URL.createObjectURL(file)}
							alt="profile-image"
							className="object-cover rounded-full w-24 h-24"
						/>
					) : image ? (
						<img src={image ?? ProfilePhotoIcon} className="rounded-full w-24 h-24 object-cover" />
					) : (
						<img src={ProfilePhotoIcon} className="rounded-full w-24 h-24" />
					)}
					<img src={CameraIcon} className="absolute bottom-0 translate-x-10 h-[38px] w-[38px]" />
				</div>
			</div>
		</div>
	)
}
