import { Disclosure } from '@headlessui/react'
import {
	ChevronRightIcon,
	PencilIcon,
	PlusCircleIcon,
	ShareIcon,
	TrashIcon,
	XCircleIcon
} from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-number-input'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { yupResolver } from '@hookform/resolvers/yup'
import clsx from 'clsx'
import { DateTime } from 'luxon'
import * as yup from 'yup'

import { Spinner } from 'components/animations/spinner'
import { Button } from 'components/app/button'
import { DeletePrompt } from 'components/app/delete-prompt'
import { ImageUpload } from 'components/app/image-upload'
import { AppLayout } from 'components/app/layout'
import { Tabs } from 'components/app/tabs'
import { Input } from 'components/inputs/input'
import { RadioInput } from 'components/inputs/radio'
import { Select } from 'components/inputs/select'
import { Language, germanToEnglishDays } from 'constants/constants'
import { useAppDispatch, useAppSelector } from 'hooks'
import useIsMobile from 'hooks/useIsMobile'
import { GalleryUpload } from 'pages/settings/company/gallery-upload'
import companyService from 'services/company-service'
import galleryService from 'services/gallery-service'
import { updateCompany } from 'slices/auth'
import { Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { getLocalizedWeekDays } from 'utils/date'
import { getAppLang, getTKey } from 'utils/language'

import { StripeIcon } from 'assets/icons'
import { MultiCombobox } from 'components/app/combo-selector'
import { ToggleSwitch } from 'components/inputs/toggle'
import ReactQuill from 'react-quill'
import { Link } from 'react-router-dom'
import 'swiper/css'
import 'swiper/css/pagination'

enum ProfileTabs {
	COMPANYDETAILS = 'companyDetails',
	CONTACTDETAILS = 'contactDetails',
	GOOGLEMAPS = 'googleMaps',
	SOCIALMEDIA = 'socialMedia',
	WORKTIME = 'workTime',
	COMPANYGALLERY = 'gallery',
	BANKACCOUNT = 'bankAccount',
	MYPAGE = 'myPage'
}

export const CompanyProfile = () => {
	const { t } = useTranslation()
	const isMobile = useIsMobile()
	const [searchParams, setSearchParams] = useSearchParams()

	const tKey = getTKey('companyProfile')

	return (
		<AppLayout isHeaderVisible={!isMobile} title={t(tKey('titles.profile'))}>
			<div className="md:pt-6 pt-5">
				<h1 className="text-primary font-domine font-bold md:hidden pb-5 px-5">
					{t(tKey('titles.profile'))}
				</h1>
				<div className="border-b border-[#E5E7EB] overflow-x-auto block pb-px px-5 max-md:mr-4 md:px-6">
					<Tabs
						tabs={Object.values(ProfileTabs)}
						setTab={(value: string) => setSearchParams({ profile: value }, { replace: true })}
						selectedTab={searchParams.get('profile') ?? ProfileTabs.COMPANYDETAILS}
					/>
				</div>

				{(searchParams.get('profile') === ProfileTabs.COMPANYDETAILS ||
					searchParams.get('profile') === null) && <CompanyDetails />}
				{searchParams.get('profile') === ProfileTabs.CONTACTDETAILS && <ContactDetails />}
				{searchParams.get('profile') === ProfileTabs.GOOGLEMAPS && <GoogleMaps />}
				{searchParams.get('profile') === ProfileTabs.SOCIALMEDIA && <SocialMedia />}
				{searchParams.get('profile') === ProfileTabs.WORKTIME && <WorkTime />}
				{searchParams.get('profile') === ProfileTabs.COMPANYGALLERY && <CompanyGallery />}
				{searchParams.get('profile') === ProfileTabs.BANKACCOUNT && <BankAccount />}
				{searchParams.get('profile') === ProfileTabs.MYPAGE && <MyPage />}
			</div>
		</AppLayout>
	)
}

const CompanyDetails = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const isMobile = useIsMobile()
	const [_searchParams, setSearchParams] = useSearchParams()

	const tKey = getTKey('companyProfile')

	const auth = useAppSelector(state => state.auth)
	const { isSidebarVisible } = useAppSelector(state => state.sidebar)

	const [categories, setCategories] = useState<CompanyType[]>([])
	const [selectedCategories, setSelectedCategories] = useState<string[]>([])
	const [logo, setLogo] = useState<File>()
	const [coverPhoto, setCoverPhoto] = useState<File>()
	const [isLoading, setIsLoading] = useState(false)
	const [companySlider, setCompanySlider] = useState<string>()
	const [companyLogo, setCompanyLogo] = useState<string>()

	const schema = yup.object<CompanyDetailForm>().shape({
		email: yup
			.string()
			.required(t(tKey('errors.email')))
			.email(t(tKey('errors.validEmail'))),
		phone: yup
			.string()
			.required(t(tKey('errors.phone')))
			.max(13, t(tKey('errors.maxPhone'))),
		company_type: yup
			.array()
			.test('company_type-test', t(tKey('errors.category')), function (value) {
				if (selectedCategories.length === 0) return false
				return true
			}),
		about_company: yup
			.string()
			.test('about_company-test', t(tKey('errors.aboutCompany')), function (value) {
				if (!value) return false
				if (value === '<p><br></p>') return false
				return true
			}),
		agb: yup.string().test('agb-test', t(tKey('errors.agb')), function (value) {
			if (!value) return false
			if (value === '<p><br></p>') return false
			return true
		}),
		website: yup.string().required(t(tKey('errors.website'))),
		konkordac: yup.string().notRequired(),
		fax: yup.string().notRequired(),
		address: yup.string().required(t(tKey('errors.address'))),
		address_no: yup.string().required(t(tKey('errors.addressNumber'))),
		zip: yup.string().required(t(tKey('errors.zip'))),
		google_review_link: yup.string().required(t(tKey('errors.googleReview'))),
		city: yup.string().required(t(tKey('errors.city'))),
		country: yup.string().required(t(tKey('errors.country'))),
		gln_number: yup.string().notRequired()
	})

	const {
		register,
		handleSubmit,
		reset,
		clearErrors,
		formState: { errors },
		control
	} = useForm<CompanyDetailForm>({
		resolver: yupResolver(schema as any),
		mode: 'all'
	})

	useEffect(() => {
		companyService.getCompanyTypes().then(res => {
			setCategories(res)
		})
		companyService.getCompanyById(auth.companyId).then(res => {
			companyService.getCompanyLogo(res._id).then(res => setCompanyLogo(res[0].url))
			companyService.getCompanySlider(res._id).then(res => setCompanySlider(res[0].url))
			setSelectedCategories(res.company_type)
			reset(res)
		})
	}, [])

	const onSubmit = (data: any) => {
		setIsLoading(true)
		const formData = new FormData()
		for (const key in data) {
			if (Object.prototype.hasOwnProperty.call(data, key)) {
				if (key === 'company_type') continue
				const value = (data as { [key: string]: any })[key]
				formData.append(key, value)
			}
		}
		for (const category of selectedCategories) {
			formData.append('company_type[]', category)
		}
		if (logo) {
			formData.append('logo', logo)
		}
		if (coverPhoto) {
			formData.append('slider', coverPhoto)
		}
		formData.delete('gallery')
		formData.delete('parent_company')
		formData.delete('blocked_clients')

		companyService
			.updateCompanyDetails(auth.companyId, formData, !!coverPhoto)
			.then(() => {
				dispatch(updateCompany({ ...data, company_type: selectedCategories }))
				toast.success(t(tKey('toast.updateSuccess')))
			})
			.catch(error => {
				if (error?.response?.data?.message) {
					return toast.error(error?.response?.data?.message)
				}
				toast.error(t(tKey('toast.updateError')))
			})
			.finally(() => setIsLoading(false))
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col mt-5 md:mt-6 gap-y-5">
			<div
				style={{ boxShadow: '0px 4px 24px 0px rgba(0, 31, 63, 0.08)' }}
				className="rounded-xl mx-5 md:mx-6 p-3 md:p-6 flex flex-col max-md:mb-[81px] mb-24 gap-y-24 md:gap-y-12">
				<div className="flex flex-col gap-y-4 md:gap-y-5">
					<h1 className="text-primary font-domine font-bold md:text-lg">
						{t(tKey('titles.companyDetails'))}
					</h1>
					<p className="text-sm">{t(tKey('labels.message'))}</p>
					<div className="relative flex gap-x-8 w-full">
						{isMobile && (
							<div className="md:hidden h-[120px] w-[110px] absolute translate-y-20 translate-x-3 z-20">
								<div className="flex flex-col gap-y-3 items-center">
									<ImageUpload
										name="companyLogo"
										image={companyLogo}
										onUpload={(image: File) => setLogo(image)}
										variant="logo"
									/>
									<p className="text-tertiary text-xs">400 by 400px</p>
								</div>
							</div>
						)}
						<div className="max-md:hidden h-[120px] shrink-0 w-[155px]">
							<div className="flex flex-col gap-y-2 items-start">
								<ImageUpload
									image={companyLogo}
									name="companyLogo"
									onUpload={(image: File) => setLogo(image)}
									variant="logo"
								/>
								<p className="text-tertiary text-sm">400 by 400 pixels</p>
							</div>
						</div>
						<div className="flex flex-col gap-y-2 w-full items-center md:items-start">
							<ImageUpload
								name="companyCoverPhoto"
								image={companySlider}
								onUpload={(image: File) => setCoverPhoto(image)}
								variant="cover"
							/>
							<p className="text-tertiary ml-8 text-xs md:text-sm">1440 by 200 pixels</p>
						</div>
					</div>
				</div>
				<div className="flex flex-col gap-y-5">
					<div className="grid grid-cols-1 md:grid-cols-2 gap-y-5 gap-x-5">
						<Input
							labelText={t(tKey('labels.companyName'))}
							disabled
							defaultValue={auth.company.company_name}
							name="company_name"
						/>
						<div className="flex relative flex-col gap-y-1">
							<MultiCombobox
								placeholder={t(tKey('labels.categories'))}
								options={categories.map(category => ({
									label: category.label,
									id: category.label
								}))}
								selectedItems={categories
									.filter(category => selectedCategories.includes(category.label))
									.map(category => ({
										label: category.label,
										id: category.label
									}))}
								onChange={(selectedOptions: { label: string; id: any }[]) => {
									clearErrors('company_type')
									const labelCountMap = new Map()
									selectedOptions.forEach(item => {
										const count = labelCountMap.get(item.label) || 0
										labelCountMap.set(item.label, count + 1)
									})
									const uniqueValue = selectedOptions.filter(
										item => labelCountMap.get(item.label) === 1
									)

									setSelectedCategories(uniqueValue.map(val => val.label))
								}}
								className="absolute mt-[50px] w-full"
							/>
							{errors?.company_type && (
								<p className="text-xs text-red-500">{errors.company_type?.message}</p>
							)}
						</div>
					</div>

					<div className="flex w-full flex-col gap-y-0.5">
						<Controller
							control={control}
							name="about_company"
							render={({ field: { onChange, value } }) => (
								<div className="relative overflow-visible rounded-md">
									<label
										htmlFor="about_company"
										className="absolute z-10 -top-2.5 text-xs cursor-text px-0.5 text-primary bg-white left-3">
										{t(tKey('labels.aboutCompany'))}
									</label>
									<ReactQuill
										className="editor agb input"
										theme="snow"
										onChange={onChange}
										value={value}
									/>
								</div>
							)}
						/>
						{errors?.about_company && (
							<p className="text-xs text-red-500">{errors.about_company?.message}</p>
						)}
					</div>

					<div className="flex w-full flex-col gap-y-0.5">
						<Controller
							control={control}
							name="agb"
							render={({ field: { onChange, value } }) => (
								<div className="relative overflow-visible rounded-md">
									<label
										htmlFor="agb"
										className="absolute z-10 font-bold -top-2.5 text-xs cursor-text px-0.5 text-primary bg-white left-3">
										{t(tKey('labels.agb'))}
									</label>
									<ReactQuill
										className="editor agb input"
										theme="snow"
										onChange={onChange}
										value={value}
									/>
								</div>
							)}
						/>
						{errors?.agb && <p className="text-xs text-red-500">{errors.agb?.message}</p>}
					</div>

					<div className="grid grid-cols-1 md:grid-cols-2 gap-y-5 gap-x-5">
						<Input register={register} errors={errors} name="email" labelText="Email" />
						<Controller
							control={control}
							name="phone"
							render={({ field: { onChange, value }, fieldState: { error } }) => (
								<div className="flex flex-col">
									<PhoneInput
										numberInputProps={{
											className:
												'w-full rounded font-normal pl-4 py-3 bg-white focus:ring-0 border-0 text-primary placeholder-[#7F9AB2] placeholder:text-base focus:outline-none text-md'
										}}
										placeholder="Enter phone number"
										defaultCountry="CH"
										value={value}
										error={error}
										onChange={onChange}
									/>
									{errors?.phone && (
										<p className="text-xs text-red-500 mt-1">{errors.phone.message as string}</p>
									)}
								</div>
							)}
						/>
					</div>
					<div className="grid md:grid-cols-2 grid-cols-1 max-md:gap-y-5 md:gap-x-5">
						<Input
							register={register}
							errors={errors}
							name="website"
							labelText={t(tKey('labels.website'))}
						/>
						<Select
							name="country"
							register={register}
							errors={errors}
							labelText={t(tKey('labels.country'))}>
							<option value="ch">{t(tKey('labels.switzerland'))}</option>
							<option value="de">{t(tKey('labels.deutschland'))}</option>
							<option value="at">{t(tKey('labels.osterreich'))}</option>
						</Select>

						<div className="hidden">
							<Input
								register={register}
								errors={errors}
								name="konkordac"
								labelText={t(tKey('labels.konkordac'))}
							/>
						</div>
					</div>

					<div className="flex flex-col gap-y-5 md:flex-row gap-x-5">
						<Input
							register={register}
							errors={errors}
							name="address"
							labelText={t(tKey('labels.address'))}
						/>
						<Input
							register={register}
							errors={errors}
							type="number"
							inputMode="numeric"
							min="0"
							labelText={t(tKey('labels.addressNo'))}
							name="address_no"
						/>
					</div>

					<div className="flex flex-col md:flex-row gap-5">
						<Input
							register={register}
							errors={errors}
							labelText={t(tKey('labels.city'))}
							name="city"
						/>
						<Input
							register={register}
							errors={errors}
							type="number"
							inputMode="numeric"
							min="0"
							labelText={t(tKey('labels.zipCode'))}
							name="zip"
						/>
					</div>

					<div className="grid md:grid-cols-2 grid-cols-1 max-md:gap-y-5 md:gap-x-5">
						<Input
							register={register}
							errors={errors}
							name="google_review_link"
							labelText={t(tKey('labels.googleReview'))}
						/>
						<Input
							register={register}
							errors={errors}
							name="fax"
							labelText={t(tKey('labels.fax'))}
						/>
					</div>

					<div className="hidden flex-col md:flex-row gap-5">
						<Input
							register={register}
							errors={errors}
							name="gln_number"
							labelText={t(tKey('labels.gln'))}
						/>
					</div>
				</div>
			</div>
			<div
				className={clsx(
					'fixed w-full bottom-0 z-30 bg-white py-3 px-6 flex justify-center md:justify-end gap-x-3.5 border-t border-[#1232581A]',
					{ 'md:-translate-x-[256px]': isSidebarVisible }
				)}>
				<button className="px-[46px] py-2.5 max-md:text-sm md:py-[13px] text-primary-light rounded border border-primary font-semibold">
					{isLoading ? (
						<div className="flex items-center justify-center gap-x-5">
							<Spinner />
							<span className="animate-pulse whitespace-nowrap">
								{t(tKey('buttons.pleaseWait'))}
							</span>
						</div>
					) : (
						<span>{t(tKey('buttons.update'))}</span>
					)}
				</button>
				<Button
					type="button"
					onClick={() => setSearchParams({ profile: ProfileTabs.CONTACTDETAILS })}
					className="px-[46px] py-2.5 max-md:text-sm md:py-[13px] font-semibold rounded border border-primary">
					{t(tKey('buttons.next'))}
				</Button>
			</div>
		</form>
	)
}

const GoogleMaps = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const [_searchParams, setSearchParams] = useSearchParams()

	const tKey = getTKey('companyProfile')

	const company = useAppSelector(state => state.auth.company)
	const { isSidebarVisible } = useAppSelector(state => state.sidebar)

	const [isLoading, setIsLoading] = useState(false)

	const schema = yup.object<{ google_iframe: string; google_map: string }>().shape({
		google_iframe: yup.string().required(t(tKey('errors.googleIframe'))),
		google_map: yup.string().required(t(tKey('errors.googleMap')))
	})

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<{ google_iframe: string; google_map: string }>({
		resolver: yupResolver(schema as any),
		defaultValues: {
			google_iframe: company.google_iframe,
			google_map: company.google_map
		},
		mode: 'all'
	})

	const onSubmit = (data: { google_iframe: string; google_map: string }) => {
		setIsLoading(true)
		companyService
			.updateCompanyDetails(company._id, data, false)
			.then(() => {
				dispatch(updateCompany({ ...company, ...data }))
				toast.success(t(tKey('toast.updateSuccess')))
			})
			.catch(() => toast.error(t(tKey('toast.updateError'))))
			.finally(() => setIsLoading(false))
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col mt-5 md:mt-6 gap-y-5">
			<div
				style={{ boxShadow: '0px 4px 24px 0px rgba(0, 31, 63, 0.08)' }}
				className="rounded-xl mx-5 md:mx-6 p-3 md:p-6 flex flex-col gap-y-24 md:gap-y-12">
				<div className="flex flex-col gap-y-4 md:gap-y-5">
					<h1 className="text-primary font-domine font-bold md:text-lg">
						{t(tKey('titles.googleMaps'))}
					</h1>
					<div className="flex flex-col md:flex-row gap-5">
						<Input
							register={register}
							errors={errors}
							name="google_map"
							labelText={t(tKey('labels.locationMap'))}
						/>
						<Input
							register={register}
							errors={errors}
							labelText={t(tKey('labels.iframe'))}
							name="google_iframe"
						/>
					</div>
				</div>
			</div>
			<div
				className={clsx(
					'fixed w-full bottom-0 z-30 bg-white py-3 px-6 flex justify-center md:justify-end gap-x-3.5 border-t border-[#1232581A]',
					{ 'md:-translate-x-[256px]': isSidebarVisible }
				)}>
				<button className="px-[46px] py-2.5 max-md:text-sm md:py-[13px] text-primary-light rounded border border-primary font-semibold">
					{isLoading ? (
						<div className="flex items-center justify-center gap-x-5">
							<Spinner />
							<span className="animate-pulse whitespace-nowrap">
								{t(tKey('buttons.pleaseWait'))}
							</span>
						</div>
					) : (
						<span>{t(tKey('buttons.update'))}</span>
					)}
				</button>
				<Button
					type="button"
					onClick={() => setSearchParams({ profile: ProfileTabs.SOCIALMEDIA })}
					className="px-[46px] py-2.5 max-md:text-sm md:py-[13px] font-semibold rounded border border-primary">
					{t(tKey('buttons.next'))}
				</Button>
			</div>
		</form>
	)
}

const SocialMedia = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const [_searchParams, setSearchParams] = useSearchParams()

	const tKey = getTKey('companyProfile')

	const company = useAppSelector(state => state.auth.company)
	const { isSidebarVisible } = useAppSelector(state => state.sidebar)

	const [isLoading, setIsLoading] = useState(false)

	const schema = yup
		.object<{ facebook: string; linkedin: string; instagram: string; youtube: string }>()
		.shape({
			facebook: yup.string().notRequired(),
			linkedin: yup.string().notRequired(),
			instagram: yup.string().notRequired(),
			youtube: yup.string().notRequired()
		})

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<{ facebook: string; linkedin: string; instagram: string; youtube: string }>({
		resolver: yupResolver(schema as any),
		defaultValues: {
			facebook: company.facebook,
			linkedin: company.linkedin,
			instagram: company.instagram,
			youtube: company.youtube
		},
		mode: 'all'
	})

	const onSubmit = (data: {
		facebook: string
		linkedin: string
		instagram: string
		youtube: string
	}) => {
		setIsLoading(true)
		companyService
			.updateCompanyDetails(company._id, data, false)
			.then(() => {
				dispatch(updateCompany({ ...company, ...data }))
				toast.success(t(tKey('toast.updateSuccess')))
			})
			.catch(() => toast.error(t(tKey('toast.updateError'))))
			.finally(() => setIsLoading(false))
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col mt-5 md:mt-6 gap-y-5">
			<div
				style={{ boxShadow: '0px 4px 24px 0px rgba(0, 31, 63, 0.08)' }}
				className="rounded-xl max-md:mb-[81px] mx-5 md:mx-6 p-3 md:p-6 flex flex-col gap-y-24 md:gap-y-12">
				<div className="flex flex-col gap-y-4 md:gap-y-5">
					<h1 className="text-primary font-domine font-bold md:text-lg">
						{t(tKey('titles.socialMedia'))}
					</h1>
					<div className="flex flex-col md:flex-row gap-5">
						<Input
							register={register}
							errors={errors}
							name="facebook"
							labelText={t(tKey('labels.facebook'))}
						/>
						<Input
							register={register}
							errors={errors}
							labelText={t(tKey('labels.linkedin'))}
							name="linkedin"
						/>
					</div>
					<div className="flex flex-col md:flex-row gap-5">
						<Input
							register={register}
							errors={errors}
							name="instagram"
							labelText={t(tKey('labels.instagram'))}
						/>
						<Input
							register={register}
							errors={errors}
							labelText={t(tKey('labels.youtube'))}
							name="youtube"
						/>
					</div>
				</div>
			</div>
			<div
				className={clsx(
					'fixed w-full bottom-0 z-30 bg-white py-3 px-6 flex justify-center md:justify-end gap-x-3.5 border-t border-[#1232581A]',
					{ 'md:-translate-x-[256px]': isSidebarVisible }
				)}>
				<button className="px-[46px] py-2.5 max-md:text-sm md:py-[13px] text-primary-light rounded border border-primary font-semibold">
					{isLoading ? (
						<div className="flex items-center justify-center gap-x-5">
							<Spinner />
							<span className="animate-pulse whitespace-nowrap">
								{t(tKey('buttons.pleaseWait'))}
							</span>
						</div>
					) : (
						<span>{t(tKey('buttons.update'))}</span>
					)}
				</button>
				<Button
					type="button"
					onClick={() => setSearchParams({ profile: ProfileTabs.WORKTIME })}
					className="px-[46px] py-2.5 max-md:text-sm md:py-[13px] font-semibold rounded border border-primary">
					{t(tKey('buttons.next'))}
				</Button>
			</div>
		</form>
	)
}

const ContactDetails = () => {
	const { t } = useTranslation()
	const tKey = getTKey('companyProfile')

	const user = useAppSelector(state => state.auth)

	const {
		register,
		formState: { errors },
		reset,
		control
	} = useForm()
	useEffect(() => {
		companyService.getCompanyById(user.companyId).then(res =>
			reset({
				...user,
				gln_number: res.gln_number,
				address: `${res.address} ${res.address_no}, ${res.zip} ${res.city}`
			})
		)
	}, [user])
	return (
		<div className="flex flex-col mt-5 md:mt-6 gap-y-5">
			<div
				style={{ boxShadow: '0px 4px 24px 0px rgba(0, 31, 63, 0.08)' }}
				className="rounded-xl max-md:mb-5 mx-5 md:mx-6 p-3 md:p-6 flex flex-col gap-y-24 md:gap-y-12">
				<div className="flex flex-col gap-y-4 md:gap-y-5">
					<h1 className="text-primary font-domine font-bold md:text-lg">
						{t(tKey('titles.contactDetails'))}
					</h1>
					<Controller
						control={control}
						name="gender"
						disabled
						render={({ field: { onChange, value } }) => (
							<div className="flex flex-row gap-x-3">
								<RadioInput
									labelText={t(tKey('labels.male'))}
									onChange={onChange}
									name="gender"
									disabled
									register={register}
									value="male"
									checked={value === 'male'}
								/>
								<RadioInput
									labelText={t(tKey('labels.female'))}
									onChange={onChange}
									register={register}
									disabled
									name="gender"
									value="female"
									checked={value === 'female'}
								/>
							</div>
						)}
					/>
					<div className="flex flex-col gap-y-5 md:flex-row gap-x-5">
						<Input
							register={register}
							errors={errors}
							disabled
							name="fname"
							labelText={t(tKey('labels.firstName'))}
						/>
						<Input
							register={register}
							errors={errors}
							disabled
							name="lname"
							labelText={t(tKey('labels.lastName'))}
						/>
					</div>
					<div className="grid grid-cols-1 items-start md:grid-cols-2 gap-y-5 md:grid-flow-col gap-x-5">
						<Input register={register} disabled errors={errors} name="email" labelText="Email" />
						<Controller
							control={control}
							name={'phone'}
							disabled
							render={({ field: { onChange, value }, fieldState: { error } }) => (
								<PhoneInput
									numberInputProps={{
										className:
											'w-full rounded font-normal disabled:text-gray-500 pl-4 py-3 bg-white focus:ring-0 border-0 text-primary placeholder-[#7F9AB2] placeholder:text-base focus:outline-none text-md'
									}}
									placeholder="Enter phone number"
									defaultCountry="CH"
									value={value}
									disabled
									error={error}
									onChange={onChange}
								/>
							)}
						/>
					</div>
					<Input
						disabled
						name="address"
						register={register}
						labelText={t(tKey('labels.address'))}
					/>
					<div className="hidden">
						<Input
							register={register}
							errors={errors}
							disabled
							name="gln_number"
							labelText={t(tKey('labels.gln'))}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

const MyPage = () => {
	const { t } = useTranslation()
	const tKey = getTKey('companyProfile')

	const user = useAppSelector(state => state.auth)

	const {
		register,
		formState: { errors },
		setValue,
		control
	} = useForm()

	useEffect(() => {
		setValue('myPage', `https://healthcorner.ch/company-details/${user.companyId}`)
	}, [user])

	return (
		<div className="flex flex-col mt-5 md:mt-6 gap-y-5">
			<div
				style={{ boxShadow: '0px 4px 24px 0px rgba(0, 31, 63, 0.08)' }}
				className="rounded-xl max-md:mb-5 mx-5 md:mx-6 p-3 md:p-6 flex flex-col gap-y-24 md:gap-y-12">
				<div className="flex flex-col gap-y-4 md:gap-y-5">
					<div className="flex justify-between items-center">
						<h1 className="text-primary font-domine font-bold md:text-lg">
							{t(tKey('titles.myPage'))}
						</h1>
						<div className="flex items-center gap-x-5">
							<ShareIcon
								onClick={() => {
									navigator.clipboard.writeText(
										`https://healthcorner.ch/company-details/${user.companyId}`
									)
									toast.success(t(tKey('toast.textCopied')))
								}}
								className="text-primary-light cursor-pointer h-5 w-5"
							/>

							<Link
								to={`https://healthcorner.ch/company-details/${user.companyId}`}
								className="text-white bg-gradient-primary rounded-lg p-2 md:text-sm text-xs"
								target="__blank">
								{t(tKey('labels.goToPage'))}
							</Link>
						</div>
					</div>
					<div className="flex">
						<Input
							register={register}
							errors={errors}
							disabled
							name="myPage"
							labelText={t(tKey('titles.myPage'))}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

const CompanyGallery = () => {
	const { t } = useTranslation()
	const auth = useAppSelector(state => state.auth)
	const dispatch = useAppDispatch()
	const [_searchParams, setSearchParams] = useSearchParams()

	const { isSidebarVisible } = useAppSelector(state => state.sidebar)

	const tKey = getTKey('companyProfile')

	const [files, setFiles] = useState<File[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const [imagePreview, setImagePreview] = useState<string[]>([])
	const [deletePrompt, setDeletePrompt] = useState<{ visibility: boolean; url?: string }>({
		visibility: false,
		url: undefined
	})

	useEffect(() => {
		if (files) {
			files.forEach(file => {
				const reader = new FileReader()
				reader.onloadend = () => {
					const imageDataURL = reader.result
					setImagePreview(prev => [...prev, imageDataURL as any])
				}
				reader.readAsDataURL(file)
			})
		}
	}, [files])

	const handleUpload = (newFiles: FileList) => {
		let updatedFiles: File[] = [...files, ...Array.from(newFiles)]

		updatedFiles = updatedFiles.reduce((uniqueFiles, file) => {
			const fileAlreadyExists = uniqueFiles.some(
				existingFile => existingFile.name === file.name && existingFile.size === file.size
			)

			const fileSizeExceedsLimit = file.size > 100000000

			if (fileAlreadyExists) {
				toast.error(t(tKey('toast.alreadyExists')))
			}
			if (!fileAlreadyExists && !fileSizeExceedsLimit) {
				uniqueFiles.push(file)
			} else if (fileSizeExceedsLimit) {
				toast.error(t(tKey('toast.exceedSize')))
			}
			return uniqueFiles
		}, [] as File[])

		if (updatedFiles.length + auth.company?.gallery.length > 10) {
			return toast.warn(t(tKey('toast.maximumFiles')))
		}
		setFiles(updatedFiles)
	}

	const handleDelete = (index: number) => {
		const newFiles = [...files]
		const newPreviews = Array.from(new Set(imagePreview))
		newFiles.splice(index, 1)
		newPreviews.splice(index, 1)
		setImagePreview(newPreviews)
		setFiles(newFiles)
	}

	const onSubmit = () => {
		setIsLoading(true)
		const formData = new FormData()
		if (files.length > 0) {
			files.map(file => formData.append('files', file))
		}
		galleryService
			.addGalleryImages(auth.companyId, formData)
			.then(data => {
				dispatch(
					updateCompany({
						...auth.company,
						gallery: data.galleryUrls
					})
				)
				toast.success(t(tKey('toast.addGallerySuccess')))
				setFiles([])
				setImagePreview([])
			})
			.catch(error => {
				if (error?.response?.data?.message) {
					return toast.error(error?.response?.data?.message)
				}
				toast.error(t(tKey('toast.addGalleryError')))
			})
			.finally(() => setIsLoading(false))
	}

	const onDelete = (url: string) => {
		galleryService
			.deleteGalleryImage(auth.companyId, url)
			.then(() => {
				dispatch(
					updateCompany({
						...auth.company,
						gallery: auth.company.gallery?.filter(img => img !== url)
					})
				)
				toast.success(t(tKey('toast.deleteGallerySuccess')))
				setDeletePrompt({ visibility: false, url: undefined })
			})
			.catch(() => toast.error(t(tKey('toast.deleteGalleryError'))))
	}

	return (
		<div className="flex flex-col mt-5 md:mt-6 gap-y-5">
			{deletePrompt.visibility && (
				<DeletePrompt
					heading={t(tKey('labels.galleryDeleteTitle'))}
					message={t(tKey('labels.galleryDeleteMessage'))}
					onClose={() => setDeletePrompt({ visibility: false, url: undefined })}
					onDelete={() => onDelete(deletePrompt.url as string)}
				/>
			)}
			<div
				style={{ boxShadow: '0px 4px 24px 0px rgba(0, 31, 63, 0.08)' }}
				className="rounded-xl mx-5 md:mx-6 p-3 md:p-6 flex flex-col max-md:mb-[81px] mb-24 gap-y-5 md:gap-y-10">
				<h1 className="text-primary font-domine font-bold md:text-lg">
					{t(tKey('titles.companyGallery'))}
				</h1>
				<GalleryUpload onUpload={handleUpload} />
				<Swiper
					className="w-full md:w-10/12 myswiper"
					pagination={{
						dynamicBullets: true,
						clickable: true
					}}
					breakpoints={{
						'@0.00': {
							slidesPerView: 1,
							spaceBetween: 10
						},
						'@0.75': {
							slidesPerView: 2,
							spaceBetween: 20
						},
						'@1.00': {
							slidesPerView: 3,
							spaceBetween: 40
						},
						'@1.50': {
							slidesPerView: 4,
							spaceBetween: 50
						},
						'@2.00': {
							slidesPerView: 4,
							spaceBetween: 50
						},
						'@2.25': {
							slidesPerView: 5,
							spaceBetween: 50
						},
						'@2.50': {
							slidesPerView: 6,
							spaceBetween: 50
						}
					}}
					modules={[Pagination]}>
					<>
						{auth.company?.gallery?.map((gallery, index) => {
							return (
								<SwiperSlide key={index}>
									<div className="relative w-fit max-md:mx-auto flex group">
										<img
											className="cursor-pointer border p-1 border-dashed border-gray-300 rounded-lg min-h-44 min-w-44 object-cover"
											src={gallery}
											alt={`Gallery ${index}`}
										/>
										{auth.company?.gallery?.length > 1 && (
											<XCircleIcon
												onClick={event => {
													event.stopPropagation()
													setDeletePrompt({ visibility: true, url: gallery })
												}}
												className="h-6 w-6 absolute hidden group-hover:block right-0 shrink-0 cursor-pointer fill-primary hover:fill-primary-light stroke-white"
											/>
										)}
									</div>
								</SwiperSlide>
							)
						})}
						{Array.from(new Set(imagePreview))?.map((gallery, index) => {
							return (
								<SwiperSlide key={index}>
									<div className="relative w-fit max-md:mx-auto flex group">
										<img
											className="cursor-pointer p-1 border border-dashed border-gray-300 rounded-lg min-h-44 min-w-44 object-cover"
											src={gallery}
											alt={`Gallery ${index}`}
										/>
										<XCircleIcon
											onClick={event => {
												event.stopPropagation()
												handleDelete(index)
											}}
											className="h-6 w-6 absolute hidden group-hover:block right-0 shrink-0 cursor-pointer fill-primary hover:fill-primary-light stroke-white"
										/>
									</div>
								</SwiperSlide>
							)
						})}
					</>
				</Swiper>
			</div>
			<div
				className={clsx(
					'fixed w-full bottom-0 z-30 bg-white py-3 px-6 flex justify-center md:justify-end gap-x-3.5 border-t border-[#1232581A]',
					{ 'md:-translate-x-[256px]': isSidebarVisible }
				)}>
				<button
					disabled={isLoading || files.length === 0}
					onClick={onSubmit}
					className={clsx(
						'px-[46px] py-2.5 max-md:text-sm md:py-[13px] rounded border font-semibold',
						files.length === 0
							? 'pointer-events-none cursor-not-allowed border-gray-300 bg-gray-100 text-gray-400'
							: 'text-primary-light border-primary'
					)}>
					{isLoading ? (
						<div className="flex items-center justify-center gap-x-5">
							<Spinner />
							<span className="animate-pulse whitespace-nowrap">
								{t(tKey('buttons.pleaseWait'))}
							</span>
						</div>
					) : (
						<span>{t(tKey('buttons.update'))}</span>
					)}
				</button>
				<div className="fixed  md:-translate-x-[256px] w-full bottom-0 z-30 bg-white py-3 px-6 flex justify-end border-t border-[#1232581A]">
					<Button
						type="button"
						onClick={() => setSearchParams({ profile: ProfileTabs.BANKACCOUNT })}
						className="px-[46px] py-2.5 max-md:text-sm md:py-[13px] font-semibold rounded border border-primary">
						{t(tKey('buttons.next'))}
					</Button>
				</div>
			</div>
		</div>
	)
}

const WorkTime = () => {
	const { t } = useTranslation()
	const [_searchParams, setSearchParams] = useSearchParams()

	const tKey = getTKey('companyProfile')

	const auth = useAppSelector(state => state.auth)
	const { isSidebarVisible } = useAppSelector(state => state.sidebar)

	const [forceRefresh, setForceRefresh] = useState(false)
	const [time, setTime] = useState<{ [day: string]: ScheduleForm }>({})

	useEffect(() => {
		companyService.getCompanySchedule(auth.companyId).then(res => {
			const mappedTime = res
				.map(r => ({ ...r, day: r.day.toString() }))
				.reduce((agg, curr) => ({ ...agg, [curr.day]: curr }), {})
			setTime(mappedTime)
		})
	}, [forceRefresh])

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>, day: string) => {
		const { name, value } = event.target
		const germanToEnglish = germanToEnglishDays[day]

		const dateTime = DateTime.fromFormat(value, 'HH:mm')
		const weekDay =
			DateTime.fromFormat(getAppLang() === Language.EN ? day : germanToEnglish, 'EEEE').weekday ===
			7
				? 0
				: DateTime.fromFormat(getAppLang() === Language.EN ? day : germanToEnglish, 'EEEE').weekday

		if (!dateTime.isValid) {
			return setTime(prev => ({
				...prev,
				[weekDay.toString()]: {
					...prev[weekDay.toString()],
					day: weekDay.toString(),
					[name]: null
				}
			}))
		}
		setTime(prev => ({
			...prev,
			[weekDay.toString()]: {
				...prev[weekDay.toString()],
				day: weekDay.toString(),
				[name]: { hour: dateTime.hour, minute: dateTime.minute }
			}
		}))
	}

	const addSchedule = (weekDay: number) => {
		const payload: ScheduleForm = {
			start: time[weekDay]?.start ?? { hour: 0, minute: 0 },
			end: time[weekDay]?.end ?? { hour: 0, minute: 0 },
			pause_start: time[weekDay]?.pause_start ?? { hour: 0, minute: 0 },
			pause_end: time[weekDay]?.pause_end ?? { hour: 0, minute: 0 },
			id_service: null,
			type: time[weekDay]?.type ?? 'Closed',
			day: weekDay.toString()
		}
		companyService
			.createCompanySchedule(payload)
			.then(() => {
				toast.success(t(tKey('toast.addScheduleSuccess')))
				setForceRefresh(prev => !prev)
			})
			.catch(() => toast.error(t(tKey('toast.addScheduleError'))))
	}

	const updateSchedule = (weekDay: number) => {
		const scheduleId = time[weekDay]._id as string
		delete time[weekDay].__v
		delete time[weekDay]._id
		delete time[weekDay].id_company
		companyService
			.updateCompanySchedule(scheduleId, time[weekDay])
			.then(() => {
				toast.success(t(tKey('toast.scheduleSuccess')))
				setForceRefresh(prev => !prev)
			})
			.catch(() => toast.error(t(tKey('toast.scheduleError'))))
	}

	const deleteSchedule = (weekDay: number) => {
		const scheduleId = (time[weekDay] as any)._id
		delete (time[weekDay] as any).__v
		delete (time[weekDay] as any)._id
		delete (time[weekDay] as any).id_company
		companyService
			.updateCompanySchedule(scheduleId, {
				start: null,
				end: null,
				pause_start: null,
				pause_end: null,
				day: weekDay.toString(),
				id_service: null,
				type: 'Closed'
			})
			.then(() => {
				toast.success(t(tKey('toast.scheduleSuccess')))
				setForceRefresh(prev => !prev)
			})
			.catch(() => toast.error(t(tKey('toast.scheduleError'))))
	}

	return (
		<div className="flex flex-col mt-5 md:mt-6 gap-y-5">
			<div
				style={{ boxShadow: '0px 4px 24px 0px rgba(0, 31, 63, 0.08)' }}
				className="rounded-xl mx-5 md:mx-6 mb-[95px] max-md:mb-[81px] flex flex-col gap-y-24 md:gap-y-12">
				<div className="flex flex-col md:gap-y-4">
					<h1 className="text-primary p-3 md:p-6 font-domine font-bold md:text-lg">
						{t(tKey('titles.workTime'))}
					</h1>
					<table className="-mt-14 -mb-12 max-md:hidden table-auto w-full max-xl:block whitespace-nowrap overflow-x-auto border-separate border-spacing-x-[29px] border-spacing-y-[34px]">
						<thead>
							<tr>
								<th></th>
								<th className="text-primary text-lg font-semibold">{t(tKey('tables.status'))}</th>
								<th className="text-primary text-lg font-semibold">
									{t(tKey('tables.availability'))}
								</th>
								<th className="text-primary text-lg font-semibold">{t(tKey('tables.break'))}</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{getLocalizedWeekDays().map(day => {
								const germanToEnglish = germanToEnglishDays[day]

								const weekDay =
									DateTime.fromFormat(getAppLang() === Language.EN ? day : germanToEnglish, 'EEEE')
										.weekday === 7
										? 0
										: DateTime.fromFormat(
												getAppLang() === Language.EN ? day : germanToEnglish,
												'EEEE'
											).weekday

								return (
									<tr key={day}>
										<td className="px-2.5 bg-border rounded text-center text-primary font-semibold">
											{day}
										</td>
										<td className="flex w-max">
											<Select
												onChange={event =>
													setTime(prev => ({
														...prev,
														[weekDay.toString()]: {
															...prev[weekDay.toString()],
															type: event.target.value as Schedule['type']
														}
													}))
												}
												value={time[weekDay]?.type ?? 'Closed'}
												name="type">
												<option value="Custom">{t(tKey('labels.custom'))}</option>
												<option value="Closed">{t(tKey('labels.closed'))}</option>
												<option value="24">{t(tKey('labels.open'))}</option>
											</Select>
										</td>
										<td>
											<div className="flex gap-x-5">
												<Input
													disabled={
														!time[weekDay.toString()]?.type ||
														time[weekDay.toString()]?.type === '24' ||
														time[weekDay.toString()]?.type === 'Closed'
													}
													name="start"
													type="time"
													value={
														!time[weekDay.toString()]?.start ||
														time[weekDay.toString()]?.start === null
															? '12:00'
															: DateTime.fromObject(
																	time[weekDay.toString()]?.start as Object
																).toFormat('HH:mm')
													}
													onChange={event => handleChange(event, day)}
												/>
												<Input
													disabled={
														!time[weekDay.toString()]?.type ||
														time[weekDay.toString()]?.type === '24' ||
														time[weekDay.toString()]?.type === 'Closed'
													}
													name="end"
													value={
														!time[weekDay.toString()]?.end || time[weekDay.toString()]?.end === null
															? '12:00'
															: DateTime.fromObject(
																	time[weekDay.toString()]?.end as Object
																).toFormat('HH:mm')
													}
													type="time"
													onChange={event => handleChange(event, day)}
												/>
											</div>
										</td>

										<td>
											<div className="flex gap-x-5">
												<Input
													disabled={
														!time[weekDay.toString()]?.type ||
														time[weekDay.toString()]?.type === '24' ||
														time[weekDay.toString()]?.type === 'Closed'
													}
													name="pause_start"
													value={
														!time[weekDay.toString()]?.pause_start ||
														time[weekDay.toString()]?.pause_start === null
															? '12:00'
															: DateTime.fromObject(
																	time[weekDay.toString()]?.pause_start as Object
																).toFormat('HH:mm')
													}
													type="time"
													onChange={event => handleChange(event, day)}
												/>
												<Input
													disabled={
														!time[weekDay.toString()]?.type ||
														time[weekDay.toString()]?.type === '24' ||
														time[weekDay.toString()]?.type === 'Closed'
													}
													name="pause_end"
													value={
														!time[weekDay.toString()]?.pause_end ||
														time[weekDay.toString()]?.pause_end === null
															? '12:00'
															: DateTime.fromObject(
																	time[weekDay.toString()]?.pause_end as Object
																).toFormat('HH:mm')
													}
													type="time"
													onChange={event => handleChange(event, day)}
												/>
											</div>
										</td>
										<td>
											{time[weekDay]?._id ? (
												<div className="flex gap-x-4">
													<PencilIcon
														onClick={() => updateSchedule(weekDay)}
														className="h-5 w-5 cursor-pointer text-primary"
													/>
													<TrashIcon
														onClick={() => deleteSchedule(weekDay)}
														className="h-5 w-5 cursor-pointer text-[#F84C6B]"
													/>
												</div>
											) : (
												<Button onClick={() => addSchedule(weekDay)}>
													{t(tKey('buttons.add'))}
												</Button>
											)}
										</td>
									</tr>
								)
							})}
							<tr></tr>
						</tbody>
					</table>
					<div className="md:hidden px-3">
						{getLocalizedWeekDays().map((day, index) => {
							const weekDay =
								DateTime.fromFormat(day, 'EEEE').weekday === 7
									? 0
									: DateTime.fromFormat(day, 'EEEE').weekday
							return (
								<Disclosure key={day}>
									{({ open }) => (
										<>
											<Disclosure.Button
												className={clsx(
													'flex justify-between w-full px-3 py-2 border-b border-border',
													{
														'bg-background': open,
														'border-b-0': index === getLocalizedWeekDays().length - 1
													}
												)}>
												<div className="flex justify-between gap-x-3 w-full">
													<div className="flex grow w-full gap-x-3 items-center">
														<ChevronRightIcon
															className={clsx('h-4 w-4 text-primary shrink-0', {
																'rotate-90 transform': open
															})}
														/>
														<p className="text-primary text-sm">{day}</p>
													</div>
													<Select
														onChange={event =>
															setTime(prev => ({
																...prev,
																[weekDay.toString()]: {
																	...prev[weekDay.toString()],
																	type: event.target.value as Schedule['type']
																}
															}))
														}
														onClick={event => event.stopPropagation()}
														value={time[weekDay]?.type ?? 'Closed'}
														name="type"
														className="w-full">
														<option value="Custom">{t(tKey('labels.custom'))}</option>
														<option value="Closed">{t(tKey('labels.closed'))}</option>
														<option value="24">{t(tKey('labels.open'))}</option>
													</Select>
												</div>
											</Disclosure.Button>
											<Disclosure.Panel>
												<div className="flex px-3 py-2 flex-col gap-y-1.5 border-b border-border bg-background">
													<h3 className="text-primary text-sm font-semibold">
														{t(tKey('tables.availability'))}
													</h3>
													<div className="flex justify-center gap-x-5 sm:grid sm:grid-cols-2">
														<input
															disabled={
																!time[weekDay.toString()]?.type ||
																time[weekDay.toString()]?.type === '24' ||
																time[weekDay.toString()]?.type === 'Closed'
															}
															name="start"
															type="time"
															value={
																!time[weekDay.toString()]?.start ||
																time[weekDay.toString()]?.start === null
																	? '12:00'
																	: DateTime.fromObject(
																			time[weekDay.toString()]?.start as Object
																		).toFormat('HH:mm')
															}
															onChange={event => handleChange(event, day)}
															className="px-3 text-sm rounded focus:outline-none focus:ring-0 disabled:text-gray-500 focus:border-secondary border-[#D3E3F1] text-primary placeholder-[#7F9AB2]"
														/>
														<input
															disabled={
																!time[weekDay.toString()]?.type ||
																time[weekDay.toString()]?.type === '24' ||
																time[weekDay.toString()]?.type === 'Closed'
															}
															name="end"
															value={
																!time[weekDay.toString()]?.end ||
																time[weekDay.toString()]?.end === null
																	? '12:00'
																	: DateTime.fromObject(
																			time[weekDay.toString()]?.end as Object
																		).toFormat('HH:mm')
															}
															type="time"
															onChange={event => handleChange(event, day)}
															className="px-3 text-sm rounded focus:outline-none focus:ring-0 disabled:text-gray-500 focus:border-secondary border-[#D3E3F1] text-primary placeholder-[#7F9AB2]"
														/>
													</div>
												</div>
												<div className="flex px-3 py-2 flex-col gap-y-1.5 bg-background">
													<h3 className="text-primary text-sm font-semibold">
														{t(tKey('tables.break'))}
													</h3>
													<div className="flex justify-center gap-x-5 sm:grid sm:grid-cols-2">
														<input
															disabled={
																!time[weekDay.toString()]?.type ||
																time[weekDay.toString()]?.type === '24' ||
																time[weekDay.toString()]?.type === 'Closed'
															}
															name="pause_start"
															type="time"
															value={
																!time[weekDay.toString()]?.pause_start ||
																time[weekDay.toString()]?.pause_start === null
																	? '12:00'
																	: DateTime.fromObject(
																			time[weekDay.toString()]?.pause_start as Object
																		).toFormat('HH:mm')
															}
															onChange={event => handleChange(event, day)}
															className="px-3 text-sm rounded focus:outline-none focus:ring-0 disabled:text-gray-500 focus:border-secondary border-[#D3E3F1] text-primary placeholder-[#7F9AB2]"
														/>
														<input
															disabled={
																!time[weekDay.toString()]?.type ||
																time[weekDay.toString()]?.type === '24' ||
																time[weekDay.toString()]?.type === 'Closed'
															}
															name="pause_end"
															value={
																!time[weekDay.toString()]?.pause_end ||
																time[weekDay.toString()]?.pause_end === null
																	? '12:00'
																	: DateTime.fromObject(
																			time[weekDay.toString()]?.pause_end as Object
																		).toFormat('HH:mm')
															}
															type="time"
															onChange={event => handleChange(event, day)}
															className="px-3 text-sm rounded focus:outline-none focus:ring-0 disabled:text-gray-500 focus:border-secondary border-[#D3E3F1] text-primary placeholder-[#7F9AB2]"
														/>
													</div>
												</div>
												{time[weekDay]?._id ? (
													<div className="bg-[#D3E3F1B2] text-primary grid auto-cols-auto grid-flow-col divide-x divide-gray-400 py-2 px-3 border-b border-border">
														<button
															className="cursor-pointer"
															onClick={() => updateSchedule(weekDay)}>
															{t(tKey('buttons.add'))}
														</button>
														<button
															className="cursor-pointer"
															onClick={() => deleteSchedule(weekDay)}>
															{t(tKey('buttons.clear'))}
														</button>
													</div>
												) : (
													<button
														onClick={() => addSchedule(weekDay)}
														className="bg-[#D3E3F1B2] w-full text-center text-primary py-2 px-3 border-b border-border">
														{t(tKey('buttons.add'))}
													</button>
												)}
											</Disclosure.Panel>
										</>
									)}
								</Disclosure>
							)
						})}
					</div>
				</div>
			</div>
			<div
				className={clsx(
					'fixed w-full bottom-0 z-30 bg-white py-3 px-6 flex justify-end border-t border-[#1232581A]',
					{ 'md:-translate-x-[256px]': isSidebarVisible }
				)}>
				<Button
					type="button"
					onClick={() => setSearchParams({ profile: ProfileTabs.COMPANYGALLERY })}
					className="px-[46px] py-2.5 max-md:text-sm md:py-[13px] font-semibold rounded border border-primary">
					{t(tKey('buttons.next'))}
				</Button>
			</div>
		</div>
	)
}

const BankAccount = () => {
	const { t } = useTranslation()
	const isMobile = useIsMobile()
	const dispatch = useAppDispatch()
	const [isUpdating, setIsUpdating] = useState(false)
	const [searchParams, _setSearchParams] = useSearchParams()

	const auth = useAppSelector(state => state.auth)

	const tKey = getTKey('companyProfile')

	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		if (searchParams.get('stripeId') && !auth.company.stripe_account) {
			companyService
				.addCompanyAccount(searchParams.get('stripeId') as string, auth.companyId)
				.then(response => {
					dispatch(
						updateCompany({
							...auth.company,
							...response.data
						})
					)
					toast.success(t(tKey('toast.accountSuccess')))
				})
		}
	}, [searchParams])

	const updatePaymentStatus = (value: boolean) => {
		setIsUpdating(true)
		companyService
			.updateCompanyDetails(auth.companyId, { ...auth.company, payment_status: value }, false)
			.then(() => {
				dispatch(updateCompany({ ...auth.company, payment_status: value }))
				if (value) {
					toast.success(t(tKey('toast.paymentActivated')))
				} else {
					toast.success(t(tKey('toast.paymentDeactivated')))
				}
			})
			.finally(() => setIsUpdating(false))
	}

	const enableStripe = () => {
		setIsLoading(true)
		companyService.stripeOnboard(auth.companyId).then(response => {
			window.location.href = response
		})
	}

	return (
		<div className="flex max-md:px-5 flex-col mt-5 md:mt-6 gap-y-5">
			<div className="flex min-h-[52vh] md:min-h-[65vh] justify-center items-center">
				<div
					style={{ boxShadow: '0px 6.56px 24px 0px #5252521F' }}
					className="rounded-[20px] border border-[#CFD8DC] flex-col gap-y-4 md:gap-y-5">
					<div className="p-4 bg-[#F5F7FA] rounded-t-[20px] gap-x-6 flex justify-between items-center">
						<div className="flex flex-col gap-y-2.5 md:gap-y-3">
							<h5 className="text-[#001F3F99] max-md:text-sm">{t(tKey('labels.enableStripe'))}</h5>
							{!auth.company.stripe_account ? (
								<div onClick={enableStripe} className="flex items-center cursor-pointer gap-x-1.5">
									<PlusCircleIcon className="h-4 w-4 text-primary-light" />
									<p className="text-primary-light hover:underline text-sm">
										{t(tKey('labels.addAccount'))}
									</p>
									{isLoading && <Spinner color="#61BC5F" className="h-3 w-3 ml-1" />}
								</div>
							) : (
								<div className="border w-fit border-[#D3E3F1] bg-[#FFFFFF] py-0.5 px-2 rounded-full">
									<p className="text-[#B790D8] text-sm">{t(tKey('labels.accountConnected'))}</p>
								</div>
							)}
						</div>

						{isUpdating ? (
							<Spinner color={auth.company.payment_status ? 'currentColor' : '#61BC5F'} />
						) : (
							<ToggleSwitch
								disabled={!auth.company.stripe_account}
								enabled={auth.company.payment_status}
								onChange={updatePaymentStatus}
							/>
						)}
					</div>
					<img src={StripeIcon} className="mx-auto p-8" />
				</div>
			</div>
		</div>
	)
}
