import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { toast } from 'react-toastify'

import generator from 'generate-password-browser'
import * as yup from 'yup'

import { ArrowPathIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import { Spinner } from 'components/animations/spinner'
import { Button } from 'components/app/button'
import { Input } from 'components/inputs/input'
import { MultiSelect } from 'components/inputs/multi-select'
import { RadioInput } from 'components/inputs/radio'
import { Select } from 'components/inputs/select'
import { useAppSelector } from 'hooks'
import { DateTime } from 'luxon'
import { useNavigate } from 'react-router-dom'
import companyService from 'services/company-service'
import employeeService from 'services/employee-service'
import medicalService from 'services/medical-service'
import { getTKey } from 'utils/language'

interface CreateEmployeeProps {
	employeeId?: string
	onCreation: () => void
	onCancel: () => void
}

export const CreateEmployee = ({ employeeId, onCreation, onCancel }: CreateEmployeeProps) => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const tKey = getTKey('employees.create')

	const auth = useAppSelector(state => state.auth)
	const isEditForm = !!employeeId

	const [isPasswordVisible, setIsPasswordVisible] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [companyDepartments, setCompanyDepartments] = useState<Department[]>([])
	const [companyServices, setCompanyServices] = useState<Service[]>([])
	const [selectedDepartments, setSelectedDepartments] = useState<string[]>([])
	const [selectedService, setSelectedService] = useState<string[]>([])

	const schema = yup.object<EmployeeForm>().shape({
		employee_id: yup.string().required(t(tKey('errors.employeeId'))),
		fname: yup.string().required(t(tKey('errors.firstName'))),
		lname: yup.string().required(t(tKey('errors.lastName'))),
		password: yup.string().test('password-test', t(tKey('errors.password')), function (value) {
			if (isEditForm) return true
			if (!value) return false
			if (!/^(?=.*[A-Z])(?=.*[0-9!@#\$%\^\&*\)\(+=._-]).{8,}$/.test(value)) {
				return this.createError({
					path: 'password',
					message: t('employees.create.errors.validPassword')
				})
			}
			if (value.length < 8) {
				return this.createError({
					path: 'password',
					message: t(tKey('errors.minPassword'))
				})
			}
			return true
		}),
		gender: yup.string().required(t(tKey('errors.gender'))),
		email: yup
			.string()
			.required(t(tKey('errors.email')))
			.email(t(tKey('errors.validEmail'))),
		phone: yup
			.string()
			.required(t(tKey('errors.phone')))
			.max(13, t(tKey('errors.maxPhone'))),
		startingDate: yup.string().required(t(tKey('errors.startDate'))),
		licenseKey: yup.string().required(t(tKey('errors.licenseKey'))),
		address_no: yup.string().required(t(tKey('errors.addressNumber'))),
		departments: yup
			.array()
			.test('departments-test', t(tKey('errors.departments')), function (value) {
				if (selectedDepartments.length === 0) return false
				return true
			}),
		services: yup.array().test('services-test', t(tKey('errors.services')), function (value) {
			if (selectedService.length === 0) return false
			return true
		}),
		biography: yup.string().required(t(tKey('errors.biography'))),
		country: yup.string().required(t(tKey('errors.country'))),
		address: yup.string().required(t(tKey('errors.address'))),
		city: yup.string().required(t(tKey('errors.city'))),
		zip: yup.string().required(t(tKey('errors.zip'))),
		is_active: yup.string().required(t(tKey('errors.status')))
	})

	const {
		register,
		handleSubmit,
		reset,
		clearErrors,
		formState: { errors },
		control
	} = useForm<EmployeeForm>({
		resolver: yupResolver(schema as any),
		defaultValues: {
			phone: '+41',
			password: generator.generate({
				length: 10,
				numbers: true,
				uppercase: true
			}),
			country: 'Switzerland'
		},
		mode: 'all'
	})

	useEffect(() => {
		companyService.getCompanyDepartments().then(response => setCompanyDepartments(response))
		medicalService
			.getServiceByCompany(auth.companyId)
			.then(response => setCompanyServices(response))
	}, [auth, isEditForm])

	useEffect(() => {
		if (isEditForm) {
			employeeService.getEmployeeById(employeeId).then(res => {
				reset({ ...(res as any), country: 'Switzerland' })
				const defaultDepartments = companyDepartments
					.filter(department => res.departments.includes(department._id))
					.map(department => department.department_name)
				const defaultServices = companyServices
					.filter(service => res.services.includes(service._id))
					.map(service => service.name)
				setSelectedDepartments(defaultDepartments)
				setSelectedService(defaultServices)
			})
		}
	}, [isEditForm, companyDepartments, companyServices])

	const onSubmit = (data: any) => {
		setIsLoading(true)
		const payload: EmployeeForm = {
			...data,
			startingDate: DateTime.fromFormat(data.startingDate, 'yyyy-LL-dd').toMillis(),
			services: companyServices
				.filter(service => selectedService.includes(service.name))
				.map(service => service._id),
			departments: companyDepartments
				.filter(department => selectedDepartments.includes(department.department_name))
				.map(department => department._id),
			companyId: auth.companyId,
			...(isEditForm ? {} : { roles: ['user', 'employee'] }),
			userType: 'employee',
			is_active: data.is_active
		}
		if (isEditForm) {
			employeeService
				.updateEmployee(employeeId as string, payload)
				.then(() => {
					toast.success(t(tKey('toast.updateSuccess')))
					onCreation()
				})
				.catch(() => toast.error(t(tKey('toast.updateError'))))
				.finally(() => setIsLoading(false))
		} else {
			employeeService
				.createEmployee(payload)
				.then(() => {
					toast.success(t(tKey('toast.employeeSuccess')))
					onCreation()
				})
				.catch(err => toast.error(err?.response?.data?.message ?? t(tKey('toast.employeeError'))))
				.finally(() => setIsLoading(false))
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="md:px-8 px-5 py-6">
			<div className="flex flex-col gap-y-5">
				<div className="flex items-center justify-between">
					<h2 className="text-primary font-domine font-bold text-sm md:text-lg">
						{t(tKey('titles.basicInfo'))}
					</h2>
					<p
						onClick={() =>
							reset({
								password: generator.generate({
									length: 10,
									numbers: true,
									uppercase: true
								})
							})
						}
						className="text-primary-light whitespace-nowrap hover:underline flex items-center gap-x-1 cursor-pointer text-sm">
						<ArrowPathIcon className="size-4" />
						{t('employees.create.labels.regeneratePassword')}
					</p>
				</div>
				<Controller
					control={control}
					name="gender"
					render={({ field: { onChange, value } }) => (
						<div className="flex flex-col">
							<div className="flex flex-row gap-x-3">
								<RadioInput
									labelText={t(tKey('labels.male'))}
									onChange={onChange}
									name="gender"
									register={register}
									value="male"
									checked={value === 'male'}
								/>
								<RadioInput
									labelText={t(tKey('labels.female'))}
									onChange={onChange}
									register={register}
									name="gender"
									value="female"
									checked={value === 'female'}
								/>
							</div>
							{errors?.gender && (
								<p className="text-xs text-red-500 mt-1">{errors.gender.message}</p>
							)}
						</div>
					)}
				/>

				<div className="flex flex-col gap-y-5 md:flex-row gap-x-5">
					<Input
						register={register}
						errors={errors}
						name="fname"
						labelText={t(tKey('labels.firstName'))}
					/>
					<Input
						register={register}
						errors={errors}
						name="lname"
						labelText={t(tKey('labels.lastName'))}
					/>
				</div>

				<div className="grid grid-cols-1 items-start md:grid-cols-2 gap-y-5 md:grid-flow-col gap-x-5">
					<Input
						register={register}
						disabled={isEditForm}
						errors={errors}
						name="email"
						labelText="Email"
					/>
					{!isEditForm && (
						<div>
							<div className="relative z-0 flex items-center">
								<Input
									type={isPasswordVisible ? 'text' : 'password'}
									register={register}
									autoCapitalize="false"
									autoCorrect="off"
									autoComplete="new-password"
									name="password"
									labelText={t(tKey('labels.password'))}
								/>
								<div
									onClick={() => setIsPasswordVisible(!isPasswordVisible)}
									className="absolute right-4">
									{isPasswordVisible ? (
										<EyeIcon className="w-6 h-6 stroke-primary" />
									) : (
										<EyeSlashIcon className="w-6 h-6 stroke-primary" />
									)}
								</div>
							</div>
							{errors.password && (
								<p className="text-xs text-red-500 mt-1">{errors.password.message}</p>
							)}
						</div>
					)}

					{isEditForm && (
						<Input
							register={register}
							errors={errors}
							name="employee_id"
							disabled={isEditForm}
							labelText={t(tKey('labels.employeeId'))}
						/>
					)}
				</div>

				<div className="grid grid-cols-1 md:grid-cols-2 gap-y-5 gap-x-5">
					{!isEditForm && (
						<Input
							register={register}
							errors={errors}
							name="employee_id"
							disabled={isEditForm}
							labelText={t(tKey('labels.employeeId'))}
						/>
					)}

					<Controller
						control={control}
						name={'phone'}
						render={({ field: { onChange, value }, fieldState: { error } }) => (
							<div className="flex flex-col">
								<PhoneInput
									numberInputProps={{
										className:
											'w-full rounded font-normal pl-4 py-3 bg-white focus:ring-0 border-0 text-primary placeholder-[#7F9AB2] placeholder:text-base focus:outline-none text-md'
									}}
									placeholder="Enter phone number"
									defaultCountry="CH"
									value={value}
									error={error}
									onChange={onChange}
								/>
								{errors?.phone && (
									<p className="text-xs text-red-500 mt-1">{errors.phone.message as string}</p>
								)}
							</div>
						)}
					/>
					{isEditForm && (
						<Select
							labelText={t(tKey('labels.status'))}
							name="is_active"
							register={register}
							errors={errors}>
							<option value="">{t(tKey('labels.status'))}</option>
							<option value="aktiv">{t(tKey('labels.active'))}</option>
							<option value="inaktiv">{t(tKey('labels.inActive'))}</option>
						</Select>
					)}
				</div>

				{!isEditForm && (
					<div className="grid grid-cols-1 md:grid-cols-2 gap-y-5 gap-x-5">
						<Input
							register={register}
							errors={errors}
							type="date"
							min={DateTime.now().startOf('day').toFormat('yyyy-LL-dd')}
							name="startingDate"
							labelText={t(tKey('labels.startDate'))}
						/>
						<Select
							labelText={t(tKey('labels.status'))}
							name="is_active"
							register={register}
							errors={errors}>
							<option value="">{t(tKey('labels.status'))}</option>
							<option value="aktiv">{t(tKey('labels.active'))}</option>
							<option value="inaktiv">{t(tKey('labels.inActive'))}</option>
						</Select>
						<div />
					</div>
				)}
			</div>

			{!isEditForm && (
				<div className="flex flex-col gap-y-6 mt-5">
					<h2 className="text-primary font-domine font-bold text-sm md:text-lg">
						{t(tKey('titles.licenseInfo'))}
					</h2>

					<div className="flex flex-col gap-y-0.5">
						<Input
							register={register}
							errors={errors}
							name="licenseKey"
							disabled={isEditForm}
							labelText={t(tKey('labels.licenseKey'))}
						/>
					</div>
					<p className="text-sm -mt-3 text-gray-700">
						{t(tKey('labels.noLicense'))}{' '}
						<span
							onClick={() => navigate('/settings/licenses', { state: true })}
							className="hover:underline cursor-pointer text-blue-600">
							Purchase License
						</span>
					</p>
				</div>
			)}

			<div className="flex flex-col gap-y-6 mt-5">
				<h2 className="text-primary font-domine font-bold text-sm md:text-lg">
					{t(tKey('titles.professionalInfo'))}
				</h2>

				<div className="flex flex-col gap-y-0.5">
					<textarea
						{...register('biography')}
						placeholder={t(tKey('labels.biography'))}
						className="w-full rounded font-normal pl-4 py-3 bg-transparent focus:ring-0 border focus:border-secondary border-[#D3E3F1] text-primary placeholder-[#7F9AB2] placeholder:text-base focus:outline-none max-md:text-sm"
					/>
					{errors?.biography && <p className="text-xs text-red-500">{errors.biography?.message}</p>}
				</div>

				<div className="grid grid-cols-1 md:grid-cols-2 gap-y-5 gap-x-5">
					<div className="flex flex-col gap-y-1">
						<MultiSelect
							label={t(tKey('labels.departments'))}
							options={companyDepartments.map(department => ({
								label: department.department_name,
								value: department.department_name
							}))}
							selectedOptions={companyDepartments
								.filter(department => selectedDepartments.includes(department.department_name))
								.map(department => ({
									label: department.department_name,
									value: department.department_name
								}))}
							onChange={(selectedOptions: { label: string; value: any }[]) => {
								const selectedValues = selectedOptions.map(option => option.value)
								setSelectedDepartments(selectedValues)
							}}
						/>
						{errors?.departments && (
							<p className="text-xs text-red-500">{errors.departments?.message}</p>
						)}
					</div>
					<div className="flex flex-col gap-y-1">
						<MultiSelect
							label={t(tKey('labels.services'))}
							options={companyServices.map(service => ({
								label: service.name,
								value: service.name
							}))}
							selectedOptions={companyServices
								.filter(service => selectedService.includes(service.name))
								.map(service => ({
									label: service.name,
									value: service.name
								}))}
							onChange={(selectedOptions: { label: string; value: any }[]) => {
								clearErrors('services')
								const selectedValues = selectedOptions.map(option => option.value)
								setSelectedService(selectedValues)
							}}
						/>
						{errors?.services && <p className="text-xs text-red-500">{errors.services?.message}</p>}
					</div>
				</div>
			</div>

			<div className="flex flex-col gap-y-6 mt-5">
				<h2 className="text-primary font-domine font-bold text-sm md:text-lg">
					{t(tKey('titles.locationInfo'))}
				</h2>
				<div className="flex flex-col gap-y-5">
					<div className="flex flex-col gap-y-5 md:flex-row gap-x-5">
						<Input
							register={register}
							errors={errors}
							labelText={t(tKey('labels.address'))}
							name="address"
						/>
						<Input
							register={register}
							errors={errors}
							labelText={t(tKey('labels.addressNo'))}
							name="address_no"
						/>
						<Input
							register={register}
							errors={errors}
							disabled
							labelText={t(tKey('labels.country'))}
							name="country"
						/>
					</div>
					<div className="flex flex-col md:flex-row gap-5">
						<Input
							register={register}
							errors={errors}
							labelText={t(tKey('labels.city'))}
							name="city"
						/>
						<Input
							register={register}
							errors={errors}
							type="number"
							inputMode="numeric"
							min="0"
							labelText={t(tKey('labels.zipCode'))}
							name="zip"
						/>
					</div>
				</div>
				<div className="flex gap-x-6 justify-end">
					<button type="button" onClick={onCancel} className="text-primary font-bold text-sm">
						{t(tKey('buttons.cancel'))}
					</button>
					<Button disabled={isLoading} className="text-sm font-bold">
						{isLoading ? (
							<div className="flex items-center justify-center gap-x-5">
								<Spinner />
								<span className="animate-pulse whitespace-nowrap">
									{t(tKey('buttons.pleaseWait'))}
								</span>
							</div>
						) : (
							<span>{isEditForm ? t(tKey('buttons.update')) : t(tKey('buttons.save'))}</span>
						)}
					</Button>
				</div>
			</div>
		</form>
	)
}
