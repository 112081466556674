import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import { TransparentHeartIcon } from 'assets/icons'
import { useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Button } from 'components/app/button'
import FileUpload from 'components/app/file-upload'
import { AppLayout } from 'components/app/layout'
import useIsMobile from 'hooks/useIsMobile'
import ticketService from 'services/ticket-service'
import { getTKey } from 'utils/language'

export const MobileSupport = () => {
	const navigate = useNavigate()
	const isMobile = useIsMobile()
	const { t } = useTranslation()

	const tKey = getTKey('support')

	const [ticketData, setTicketData] = useState({
		subject: '',
		text: '',
		files: [] as File[],
		email: ''
	})

	useLayoutEffect(() => {
		if (isMobile === false) {
			navigate('/', { replace: true })
		}
	}, [isMobile])

	const createTicket = (data: FormData) => {
		ticketService
			.createTicket(data)
			.then(() => {
				toast.success(t(tKey('toast.requestSent')))
				navigate('/', { replace: true })
			})
			.catch(() => toast.error(t(tKey('toast.requestError'))))
	}

	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault()
		if (ticketData.files.length > 5) return
		const formData = new FormData()
		formData.append('subject', ticketData.subject)
		formData.append('text', ticketData.text)
		formData.append('email', ticketData.email)
		if (ticketData.files.length > 0) {
			ticketData.files.map(file => formData.append('files', file))
		}
		createTicket(formData)
	}

	return (
		<AppLayout isHeaderVisible={false} title={t(tKey('title'))}>
			<form onSubmit={handleSubmit}>
				<div className="bg-wave p-5 pb-6 bg-cover bg-bottom flex flex-col gap-y-6 bg-no-repeat">
					<div className="grid grid-cols-3 items-center">
						<ChevronLeftIcon
							onClick={() => navigate(-1)}
							className="h-5 w-5 stroke-white cursor-pointer"
						/>
						<h1 className="text-[#FFF] whitespace-nowrap text-center">{t(tKey('title'))}</h1>
						<div />
					</div>
					<div className="flex justify-between pr-4">
						<div className="flex flex-col">
							<h2 className="text-lg text-[#FFF]">{t(tKey('welcome'))}</h2>
							<p className="text-sm text-[#FFF]">{t(tKey('message'))}</p>
						</div>
						<img src={TransparentHeartIcon} />
					</div>
					<p className="text-sm text-[#FFF]">{t(tKey('support'))}</p>
				</div>
				<div className="flex flex-col gap-y-[18px] p-5">
					<div className="relative z-0">
						<input
							type="email"
							onChange={e => setTicketData(prev => ({ ...prev, email: e.target.value }))}
							required
							id="floating_standard"
							className="block py-2.5 px-0 w-full bg-transparent border-0 border-b border-[#D3E3F1] appearance-none focus:outline-none focus:ring-0 focus:border-primary peer"
							placeholder=" "
						/>
						<label
							htmlFor="floating_standard"
							className="absolute text-tertiary duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
							{t(tKey('email'))}
						</label>
					</div>

					<div className="relative z-0">
						<input
							type="text"
							onChange={e => setTicketData(prev => ({ ...prev, subject: e.target.value }))}
							required
							id="floating_standard"
							className="block py-2.5 px-0 w-full bg-transparent border-0 border-b border-[#D3E3F1] appearance-none focus:outline-none focus:ring-0 focus:border-primary peer"
							placeholder=" "
						/>
						<label
							htmlFor="floating_standard"
							className="absolute text-tertiary duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
							{t(tKey('subject'))}
						</label>
					</div>
					<textarea
						required
						onChange={e => setTicketData(prev => ({ ...prev, text: e.target.value }))}
						className="border-none px-0 py-2 placeholder:text-tertiary focus:ring-0 focus:outline-none"
						placeholder={t(tKey('content'))}
					/>
				</div>
				<FileUpload
					files={ticketData.files}
					allowMultiple={true}
					maximumAllowedFiles={5}
					onDismiss={(files?: File[]) => {
						if (files && files?.length > 0) return setTicketData(prev => ({ ...prev, files }))
						setTicketData(prev => ({ ...prev, files: prev.files.slice(0, 5) }))
					}}
					onDelete={(files?: File[]) => {
						setTicketData(prev => ({ ...prev, files: files as File[] }))
					}}
					onUpload={(files: File[]) =>
						setTicketData(prev => ({
							...prev,
							files: [...prev?.files, ...files]
						}))
					}
					name="email-attachment"
				/>
				<div className="px-5 fixed bottom-4 w-full">
					<Button className="w-full bottom-4" type="submit">
						{t(tKey('send'))}
					</Button>
				</div>
			</form>
		</AppLayout>
	)
}
