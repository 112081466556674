import { Disclosure } from '@headlessui/react'
import {
	ChevronDownIcon,
	ChevronLeftIcon,
	ChevronRightIcon,
	ChevronUpIcon,
	PencilIcon,
	TrashIcon,
	XMarkIcon
} from '@heroicons/react/24/outline'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import clsx from 'clsx'

import { Button } from 'components/app/button'
import { DeletePrompt } from 'components/app/delete-prompt'
import { AppLayout } from 'components/app/layout'
import { EllipsisMenu, MenuDropdown } from 'components/app/menu'
import { Pagination } from 'components/app/pagination'
import { Table } from 'components/app/table'
import { InputDate } from 'components/inputs/date'
import { TaskStatus, mappedTaskStatus } from 'constants/constants'
import useIsMobile from 'hooks/useIsMobile'
import { DateTime } from 'luxon'
import { useNavigate } from 'react-router-dom'
import companyService from 'services/company-service'
import taskService from 'services/task-service'
import { getAppLang, getTKey } from 'utils/language'

export const TaskManager = () => {
	const { t } = useTranslation()
	const isMobile = useIsMobile()
	const navigate = useNavigate()

	const tKey = getTKey('taskManager')
	const appLanguage = getAppLang()

	const [deletePrompt, setDeletePrompt] = useState<{ visibility: boolean; id?: string }>({
		visibility: false,
		id: undefined
	})
	const [forceRefresh, setForceRefresh] = useState(false)
	const [isSearchVisible, setIsSearchVisible] = useState(false)
	const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false)
	const [tasks, setTasks] = useState<Task[]>([])
	const [selectedDate, setSelectedDate] = useState<string>('')
	const [selectedStatus, setSelectedStatus] = useState('allStatus')
	const [sorting, setSorting] = useState<{ isSorted: boolean; type: 'asc' | 'desc' }>()
	const [searchText, setSearchText] = useState('')

	useEffect(() => {
		taskService.getAllTasks().then(res => setTasks(res))
	}, [forceRefresh])

	const deleteTask = (id: string) => {
		taskService
			.deleteTask(id)
			.then(() => {
				toast.success(t(tKey('toast.deleteSuccess')))
				setForceRefresh(prev => !prev)
			})
			.catch(() => toast.error(t(tKey('toast.deleteError'))))
			.finally(() => setDeletePrompt({ visibility: false, id: undefined }))
	}

	const { filteredTasks } = useMemo(() => {
		const { filteredTasks } = tasks
			.map((task, index) => ({ ...task, id: index + 1 }))
			.filter(task =>
				selectedStatus === 'allStatus'
					? true
					: selectedStatus === mappedTaskStatus[task.status as TaskStatus]
			)
			.reduce(
				(prev, curr) => {
					if (searchText) {
						const assigneeName = curr.assignee?.fname + ' ' + curr.assignee?.lname
						if (
							curr.taskName?.toLowerCase().includes(searchText.toLowerCase()) ||
							curr.taskType?.toLowerCase().includes(searchText.toLowerCase()) ||
							assigneeName?.toLowerCase().includes(searchText.toLowerCase()) ||
							DateTime.fromISO(curr.taskDueDate)
								.toFormat('dd.LL.yyyy')
								.includes(searchText.toLowerCase()) ||
							curr.priority?.toLowerCase().includes(searchText.toLowerCase()) ||
							curr.status.toLowerCase().includes(searchText.toLowerCase())
						) {
							return { filteredTasks: [...prev.filteredTasks, curr] }
						}
					} else if (selectedDate) {
						if (
							DateTime.fromISO(curr.taskDueDate).hasSame(
								DateTime.fromFormat(selectedDate, 'dd LLL yyyy'),
								'day'
							)
						) {
							return { filteredTasks: [...prev.filteredTasks, curr] }
						}
					} else {
						return { filteredTasks: [...prev.filteredTasks, curr] }
					}
					return prev
				},
				{
					filteredTasks: [] as Task[]
				}
			)
		return { filteredTasks }
	}, [tasks, companyService, searchText, selectedDate, selectedStatus])

	const columns = [
		{
			header: 'Id',
			accessorKey: 'id'
		},
		{
			header: t(tKey('tables.taskName')),
			accessorKey: 'taskName',
			cell: (data: Cell) => (
				<div className="flex flex-col gap-y-0.5">
					<p>{data.getValue()}</p>
					<p className="text-tertiary text-xs capitalize">
						{data.row.original.taskType === 'customTask'
							? 'Custom Task'
							: data.row.original.taskType}
					</p>
				</div>
			)
		},
		{
			header: t(tKey('tables.assignee')),
			accessorFn: (row: Task) => `${row.assignee.fname} ${row.assignee.lname}`
		},
		{
			header: t(tKey('tables.dueDate')),
			accessorKey: 'taskDueDate',
			cell: (data: Cell) =>
				DateTime.fromISO(data.getValue()).setLocale(appLanguage).toFormat('dd.LL.yyyy')
		},
		{
			header: t(tKey('tables.priority')),
			accessorKey: 'priority',
			cell: (data: Cell) => (
				<p
					className={clsx('text-center capitalize', {
						'text-[#18DF80]': data.getValue() === 'low',
						'text-[#F5B715]': data.getValue() === 'medium',
						'text-[#F84C6B]': data.getValue() === 'high'
					})}>
					{data.getValue()}
				</p>
			)
		},
		{
			header: t(tKey('tables.status')),
			accessorKey: 'status',
			cell: (data: Cell) => (
				<p
					className={clsx('text-center py-1 px-5 rounded-full font-semibold text-white', {
						'bg-[#AAAAAA]': data.getValue() === TaskStatus.NOTSTARTED,
						'bg-[#207DD3]': data.getValue() === TaskStatus.INPROGRESS,
						'bg-[#13BAB4]': data.getValue() === TaskStatus.COMPLETED
					})}>
					{mappedTaskStatus[data.getValue() as TaskStatus]}
				</p>
			)
		},
		{
			id: 'action-task',
			hasNoHeading: true,
			cell: (data: Cell) => (
				<div className="flex gap-x-6 justify-end items-center">
					<svg
						onClick={() => setDeletePrompt({ visibility: true, id: data.row.original._id })}
						xmlns="http://www.w3.org/2000/svg"
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
						className="cursor-pointer group shrink-0">
						<path
							d="M6.08975 17.0832C5.67549 17.0832 5.32085 16.9356 5.02585 16.6406C4.73084 16.3456 4.58333 15.991 4.58333 15.5767V4.99984H4.375C4.19792 4.99984 4.04948 4.93992 3.92969 4.82007C3.8099 4.70024 3.75 4.55174 3.75 4.37457C3.75 4.19742 3.8099 4.04901 3.92969 3.92936C4.04948 3.8097 4.19792 3.74986 4.375 3.74986H7.49998C7.49998 3.54581 7.57183 3.37193 7.71552 3.22824C7.85922 3.08454 8.03309 3.0127 8.23715 3.0127H11.7628C11.9669 3.0127 12.1407 3.08454 12.2844 3.22824C12.4281 3.37193 12.5 3.54581 12.5 3.74986H15.625C15.802 3.74986 15.9505 3.80979 16.0703 3.92963C16.1901 4.04948 16.25 4.19798 16.25 4.37513C16.25 4.5523 16.1901 4.70071 16.0703 4.82036C15.9505 4.94001 15.802 4.99984 15.625 4.99984H15.4166V15.5767C15.4166 15.991 15.2691 16.3456 14.9741 16.6406C14.6791 16.9356 14.3245 17.0832 13.9102 17.0832H6.08975ZM14.1666 4.99984H5.83331V15.5767C5.83331 15.6515 5.85735 15.713 5.90544 15.7611C5.95352 15.8091 6.01496 15.8332 6.08975 15.8332H13.9102C13.985 15.8332 14.0464 15.8091 14.0945 15.7611C14.1426 15.713 14.1666 15.6515 14.1666 15.5767V4.99984ZM8.46181 14.1665C8.63897 14.1665 8.78737 14.1066 8.90702 13.9868C9.02669 13.867 9.08652 13.7186 9.08652 13.5415V7.29149C9.08652 7.11442 9.0266 6.96599 8.90675 6.8462C8.7869 6.7264 8.6384 6.66651 8.46125 6.66651C8.28408 6.66651 8.13567 6.7264 8.01602 6.8462C7.89637 6.96599 7.83654 7.11442 7.83654 7.29149V13.5415C7.83654 13.7186 7.89647 13.867 8.01631 13.9868C8.13615 14.1066 8.28465 14.1665 8.46181 14.1665ZM11.5387 14.1665C11.7159 14.1665 11.8643 14.1066 11.9839 13.9868C12.1036 13.867 12.1634 13.7186 12.1634 13.5415V7.29149C12.1634 7.11442 12.1035 6.96599 11.9836 6.8462C11.8638 6.7264 11.7153 6.66651 11.5381 6.66651C11.361 6.66651 11.2126 6.7264 11.0929 6.8462C10.9733 6.96599 10.9134 7.11442 10.9134 7.29149V13.5415C10.9134 13.7186 10.9734 13.867 11.0932 13.9868C11.2131 14.1066 11.3616 14.1665 11.5387 14.1665Z"
							fill="#F84C6B"
							className="group-hover:fill-[#F84C6B] fill-[#7F9AB2]"
						/>
					</svg>
					<svg
						onClick={() => navigate(`/task-manager/create/${data.row.original._id}`)}
						xmlns="http://www.w3.org/2000/svg"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						className="cursor-pointer group shrink-0">
						<path
							d="M5 19H6.09808L16.7962 8.30193L15.6981 7.20385L5 17.9019V19ZM4.80768 20C4.57884 20 4.38702 19.9226 4.2322 19.7678C4.0774 19.613 4 19.4212 4 19.1923V18.1519C4 17.9308 4.04359 17.7201 4.13078 17.5198C4.21794 17.3194 4.33397 17.1468 4.47885 17.0019L17.1808 4.28652C17.2832 4.19517 17.3963 4.12458 17.5201 4.07475C17.6439 4.02492 17.7729 4 17.9072 4C18.0416 4 18.1717 4.02115 18.2977 4.06345C18.4236 4.10577 18.5398 4.18205 18.6462 4.2923L19.7135 5.36537C19.8237 5.47179 19.899 5.58852 19.9394 5.71557C19.9798 5.84262 20 5.96968 20 6.09673C20 6.23224 19.9772 6.36189 19.9315 6.48568C19.8858 6.60946 19.8131 6.72257 19.7135 6.825L6.99808 19.5212C6.85321 19.666 6.6806 19.7821 6.48025 19.8692C6.27988 19.9564 6.06916 20 5.84808 20H4.80768ZM16.2375 7.76252L15.6981 7.20385L16.7962 8.30193L16.2375 7.76252Z"
							fill="#123258"
							className="group-hover:fill-[#123258] fill-[#7F9AB2]"
						/>
					</svg>
				</div>
			)
		}
	]

	if (isMobileFilterOpen) {
		return (
			<div>
				<div className="py-4 px-5 items-center grid grid-cols-3">
					<ChevronLeftIcon
						className="w-4 h-4 cursor-pointer"
						onClick={() => setIsMobileFilterOpen(false)}
					/>
					<h1 className="text-center font-domine font-bold text-sm text-primary">
						{t(tKey('titles.filters'))}
					</h1>
				</div>
				<div className="p-5 flex flex-col gap-y-5">
					<InputDate
						placeholder={t(tKey('placeholders.date'))}
						value={selectedDate}
						onDateChange={(value: string) => setSelectedDate(value)}
						className="w-full p-4 rounded"
					/>
					<select
						name="status"
						onChange={e => setSelectedStatus(e.target.value)}
						value={selectedStatus}
						className="text-primary text-sm font-semibold rounded p-4 border border-[#D3E3F1] focus:border-[#06AB91] focus:ring-0">
						<option value={'allStatus'}>{t('menu.labels.allStatus')}</option>
						{Object.values(mappedTaskStatus).map(status => (
							<option key={status} value={status}>
								{status}
							</option>
						))}
					</select>
					<div className="self-end flex gap-x-6">
						<button
							onClick={() => {
								setSelectedDate('')
								setSelectedStatus('allStatus')
							}}
							className="text-primary font-bold text-sm">
							{t(tKey('buttons.reset'))}
						</button>
						<Button onClick={() => setIsMobileFilterOpen(false)} className="font-bold text-sm">
							{t(tKey('buttons.apply'))}
						</Button>
					</div>
				</div>
			</div>
		)
	}

	const renderMobileTasksTable = (tasks: Task[]) => {
		return (
			<>
				{tasks
					.sort((a, b) => {
						if (sorting?.type === 'asc') {
							return a.taskName.localeCompare(b.taskName)
						} else if (sorting?.type === 'desc') {
							return b.taskName.localeCompare(a.taskName)
						}
						return 0
					})
					.map((task, index) => (
						<div key={task._id} className="w-full">
							<Disclosure>
								{({ open }) => (
									<>
										<Disclosure.Button
											className={clsx(
												'flex justify-between w-full px-3 py-2 border border-[#1C1C1C1A]',
												{
													'bg-background': open,
													'border-t-0': index > 0
												}
											)}>
											<div className="flex w-full items-center justify-between">
												<div className="flex gap-x-2.5 items-center">
													<ChevronRightIcon
														className={clsx('h-4 w-4 text-primary shrink-0', {
															'rotate-90 transform': open
														})}
													/>
													<div className="flex items-start flex-col gap-y-1">
														<h3 className="text-primary text-sm font-semibold">{task.taskName}</h3>
														<div className="flex gap-x-2 items-center">
															<p className="text-tertiary text-xs">{(task as any).id}</p>
															<svg
																xmlns="http://www.w3.org/2000/svg"
																width="4"
																height="4"
																viewBox="0 0 4 4"
																fill="none"
																className="shrink-0">
																<circle cx="2" cy="2" r="2" fill="#D3E3F1" />
															</svg>
															<p
																className={clsx('text-xs whitespace-nowrap', {
																	'text-[#AAAAAA]': task.status === TaskStatus.NOTSTARTED,
																	'text-[#207DD3]': task.status === TaskStatus.INPROGRESS,
																	'text-[#13BAB4]': task.status === TaskStatus.COMPLETED
																})}>
																{mappedTaskStatus[task.status as TaskStatus]}
															</p>
														</div>
													</div>
												</div>
												<EllipsisMenu
													options={[
														{
															icon: TrashIcon,
															color: 'text-[#F84C6B]',
															action: () => setDeletePrompt({ visibility: true, id: task._id })
														},
														{
															icon: PencilIcon,
															color: 'text-primary',
															action: () => navigate(`/task-manager/create/${task._id}`)
														}
													]}
												/>
											</div>
										</Disclosure.Button>
										<Disclosure.Panel>
											<div className="flex px-6 py-2 gap-x-1.5 border-t-0 border border-[#1C1C1C1A] bg-background">
												<h5 className="text-primary font-semibold text-sm">
													{t(tKey('tables.priority'))}:
												</h5>
												<p
													className={clsx('text-sm whitespace-nowrap capitalize', {
														'text-[#18DF80]': task.priority === 'low',
														'text-[#F5B715]': task.priority === 'medium',
														'text-[#F84C6B]': task.priority === 'high'
													})}>
													{task.priority}
												</p>
											</div>
											<div className="flex px-6 py-2 gap-x-1.5 border-t-0 border border-[#1C1C1C1A] bg-background">
												<h5 className="text-primary font-semibold text-sm">
													{t(tKey('tables.status'))}:
												</h5>
												<p className="text-primary text-sm">
													{mappedTaskStatus[task.status as TaskStatus]}
												</p>
											</div>
											<div className="flex px-6 py-2 gap-x-1.5 border-t-0 border border-[#1C1C1C1A] bg-background">
												<h5 className="text-primary font-semibold text-sm">
													{t(tKey('tables.assignee'))}:
												</h5>
												<p className="text-primary text-sm">
													{task.assignee.fname + ' ' + task.assignee.lname}
												</p>
											</div>
											<div className="flex px-6 py-2 gap-x-1.5 border-t-0 border border-[#1C1C1C1A] bg-background">
												<h5 className="text-primary font-semibold text-sm">
													{t(tKey('tables.assignedBy'))}:
												</h5>
												<p className="text-primary text-sm">
													{task.assignedBy.fname + ' ' + task.assignedBy.lname}
												</p>
											</div>
											<div className="flex px-6 py-2 gap-x-1.5 border-t-0 border border-[#1C1C1C1A] bg-background">
												<h5 className="text-primary font-semibold text-sm">
													{t(tKey('tables.taskType'))}:
												</h5>
												<p className="text-primary capitalize text-sm">
													{task.taskType === ('customTask' as TaskType)
														? 'Custom Task'
														: task.taskType}
												</p>
											</div>
										</Disclosure.Panel>
									</>
								)}
							</Disclosure>
						</div>
					))}
			</>
		)
	}

	return (
		<AppLayout isHeaderVisible={!isMobile} title={t(tKey('titles.taskManager'))}>
			{deletePrompt.visibility && (
				<DeletePrompt
					heading={t(tKey('labels.deleteTitle'))}
					message={t(tKey('labels.deleteMessage'))}
					onClose={() => setDeletePrompt({ visibility: false, id: undefined })}
					onDelete={() => deleteTask(deletePrompt.id as string)}
				/>
			)}
			<div className="p-5 md:p-6 flex flex-col gap-y-5 md:gap-y-8">
				<div className="flex items-center justify-between md:hidden">
					<h1 className="text-primary font-domine font-bold">{t(tKey('titles.taskManager'))}</h1>
					<div className="flex gap-x-2.5 items-center">
						<div
							style={{ boxShadow: '0px 6px 24px 0px rgba(18, 50, 88, 0.08)' }}
							onClick={() => {
								if (isSearchVisible) {
									setSearchText('')
									return setIsSearchVisible(false)
								}
								setIsSearchVisible(true)
							}}
							className={clsx('rounded-full border border-[#D3E3F1] p-[5px] cursor-pointer', {
								'bg-[#1232584D]': isSearchVisible
							})}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none">
								<g clipPath="url(#clip0_2734_2383)">
									<path
										d="M12.9162 11.6672H12.2579L12.0245 11.4422C13.0245 10.2755 13.5412 8.68386 13.2579 6.99219C12.8662 4.67552 10.9329 2.82552 8.59954 2.54219C5.07454 2.10885 2.10788 5.07552 2.54121 8.60052C2.82454 10.9339 4.67455 12.8672 6.99121 13.2589C8.68288 13.5422 10.2745 13.0255 11.4412 12.0255L11.6662 12.2589V12.9172L15.2079 16.4589C15.5495 16.8005 16.1079 16.8005 16.4495 16.4589C16.7912 16.1172 16.7912 15.5589 16.4495 15.2172L12.9162 11.6672ZM7.91621 11.6672C5.84121 11.6672 4.16621 9.99219 4.16621 7.91719C4.16621 5.84219 5.84121 4.16719 7.91621 4.16719C9.99121 4.16719 11.6662 5.84219 11.6662 7.91719C11.6662 9.99219 9.99121 11.6672 7.91621 11.6672Z"
										fill="#123258"
									/>
								</g>
								<defs>
									<clipPath id="clip0_2734_2383">
										<rect width="20" height="20" fill="white" />
									</clipPath>
								</defs>
							</svg>
						</div>
						<div
							style={{ boxShadow: '0px 6px 24px 0px rgba(18, 50, 88, 0.08)' }}
							onClick={() => setIsMobileFilterOpen(true)}
							className={clsx('rounded-full border border-[#D3E3F1] p-[5px] cursor-pointer', {
								'bg-[#1232584D]': selectedStatus !== 'allStatus' || selectedDate !== ''
							})}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none">
								<path
									d="M4.16699 17.5V7.5M15.8337 2.5V5.83333V2.5ZM15.8337 17.5V9.16667V17.5ZM10.0003 2.5V12.5V2.5ZM10.0003 17.5V15.8333V17.5ZM4.16699 2.5V4.16667V2.5Z"
									stroke="#123258"
									strokeWidth="1.2"
									strokeLinecap="round"
								/>
								<path
									d="M14.1667 7.49968C14.1667 8.42015 14.9129 9.16634 15.8333 9.16634C16.7538 9.16634 17.5 8.42015 17.5 7.49968C17.5 6.5792 16.7538 5.83301 15.8333 5.83301C14.9129 5.83301 14.1667 6.5792 14.1667 7.49968Z"
									stroke="#123258"
									strokeWidth="1.2"
									strokeLinecap="round"
								/>
								<path
									d="M8.33366 14.1667C8.33366 15.0871 9.07985 15.8333 10.0003 15.8333C10.9208 15.8333 11.667 15.0871 11.667 14.1667C11.667 13.2462 10.9208 12.5 10.0003 12.5C9.07985 12.5 8.33366 13.2462 8.33366 14.1667Z"
									stroke="#123258"
									strokeWidth="1.2"
									strokeLinecap="round"
								/>
								<path
									d="M2.49967 5.83366C2.49967 6.75413 3.24587 7.50033 4.16634 7.50033C5.08682 7.50033 5.83301 6.75413 5.83301 5.83366C5.83301 4.91318 5.08682 4.16699 4.16634 4.16699C3.24587 4.16699 2.49967 4.91318 2.49967 5.83366Z"
									stroke="#123258"
									strokeWidth="1.2"
									strokeLinecap="round"
								/>
							</svg>
						</div>
						<div
							style={{ boxShadow: '0px 6px 24px 0px rgba(18, 50, 88, 0.08)' }}
							onClick={() => navigate('/task-manager/create')}
							className="rounded-full border border-[#D3E3F1] p-[5px] cursor-pointer">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none">
								<path
									d="M9.37492 10.625H5.20825C5.03117 10.625 4.88273 10.5651 4.76294 10.4452C4.64315 10.3254 4.58325 10.1769 4.58325 9.99975C4.58325 9.82258 4.64315 9.67417 4.76294 9.55452C4.88273 9.43487 5.03117 9.37504 5.20825 9.37504H9.37492V5.20837C9.37492 5.03129 9.43484 4.88285 9.55467 4.76306C9.67452 4.64327 9.82302 4.58337 10.0002 4.58337C10.1773 4.58337 10.3257 4.64327 10.4454 4.76306C10.5651 4.88285 10.6249 5.03129 10.6249 5.20837V9.37504H14.7915C14.9686 9.37504 15.1171 9.43496 15.2369 9.55479C15.3566 9.67464 15.4165 9.82314 15.4165 10.0003C15.4165 10.1775 15.3566 10.3259 15.2369 10.4455C15.1171 10.5652 14.9686 10.625 14.7915 10.625H10.6249V14.7917C10.6249 14.9688 10.565 15.1172 10.4451 15.237C10.3253 15.3568 10.1768 15.4167 9.99963 15.4167C9.82246 15.4167 9.67405 15.3568 9.5544 15.237C9.43474 15.1172 9.37492 14.9688 9.37492 14.7917V10.625Z"
									fill="#123258"
								/>
							</svg>
						</div>
					</div>
				</div>
				<div className="flex max-md:hidden justify-between items-center">
					<div className="relative flex items-center justify-end">
						<input
							style={{ boxShadow: '0px 6px 24px 0px rgba(18, 50, 88, 0.08)' }}
							type="text"
							placeholder={t(tKey('placeholders.keyword'))}
							value={searchText}
							onChange={e => setSearchText(e.target.value)}
							className="px-4 py-2 w-[322px] placeholder:text-[#7F9AB2] text-primary focus:border-primary border border-border rounded-full"
						/>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="25"
							viewBox="0 0 24 25"
							fill="none"
							className="absolute -translate-x-3">
							<g clipPath="url(#clip0_2878_8260)">
								<path
									d="M15.4996 14.5006H14.7096L14.4296 14.2306C15.6296 12.8306 16.2496 10.9206 15.9096 8.89063C15.4396 6.11063 13.1196 3.89063 10.3196 3.55063C6.08965 3.03063 2.52965 6.59063 3.04965 10.8206C3.38965 13.6206 5.60965 15.9406 8.38965 16.4106C10.4196 16.7506 12.3296 16.1306 13.7296 14.9306L13.9996 15.2106V16.0006L18.2496 20.2506C18.6596 20.6606 19.3296 20.6606 19.7396 20.2506C20.1496 19.8406 20.1496 19.1706 19.7396 18.7606L15.4996 14.5006ZM9.49965 14.5006C7.00965 14.5006 4.99965 12.4906 4.99965 10.0006C4.99965 7.51063 7.00965 5.50063 9.49965 5.50063C11.9896 5.50063 13.9996 7.51063 13.9996 10.0006C13.9996 12.4906 11.9896 14.5006 9.49965 14.5006Z"
									fill="#123258"
								/>
							</g>
							<defs>
								<clipPath id="clip0_2878_8260">
									<rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
								</clipPath>
							</defs>
						</svg>
					</div>
					<div className="flex gap-x-4 items-center">
						<InputDate
							placeholder={t(tKey('placeholders.date'))}
							value={selectedDate}
							onDateChange={(value: string) => setSelectedDate(value)}
							className="px-3 py-2.5 rounded "
						/>
						<MenuDropdown
							options={['allStatus', ...Object.values(mappedTaskStatus)]}
							onChange={(value: string) => setSelectedStatus(value)}
							value={selectedStatus}
						/>
						<button
							style={{ boxShadow: '0px 6px 24px 0px rgba(18, 50, 88, 0.08)' }}
							onClick={() => navigate('/task-manager/create')}
							className="pr-4 pl-3 py-2.5 flex items-center gap-x-2 rounded bg-primary text-white text-sm font-semibold">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none">
								<path
									d="M9.58333 10.4167H5V9.58333H9.58333V5H10.4167V9.58333H15V10.4167H10.4167V15H9.58333V10.4167Z"
									fill="white"
								/>
							</svg>
							<span className="whitespace-nowrap">{t(tKey('buttons.addTask'))}</span>
						</button>
					</div>
				</div>
				{isSearchVisible && (
					<div className="relative lg:hidden flex items-center">
						<input
							style={{ boxShadow: '0px 6px 24px 0px rgba(18, 50, 88, 0.08)' }}
							type="text"
							placeholder={t(tKey('placeholders.keyword'))}
							autoFocus
							className="rounded w-full placeholder:text-tertiary text-primary focus:outline-none border-border focus:ring-0 focus:border-secondary px-4 py-[5px]"
							onChange={event => setSearchText(event.target.value)}
						/>
						<XMarkIcon
							onClick={() => {
								setSearchText('')
								setIsSearchVisible(false)
							}}
							className="absolute h-6 w-6 right-3 stroke-primary stroke-1"
						/>
					</div>
				)}
				<div className="md:hidden">
					<div
						onClick={() => {
							if (sorting?.isSorted && sorting.type === 'desc') return setSorting(undefined)
							if (!sorting?.isSorted) return setSorting({ isSorted: true, type: 'asc' })
							if (sorting.type === 'asc') return setSorting({ isSorted: true, type: 'desc' })
						}}
						className="px-6 py-3 cursor-pointer flex items-center justify-between rounded-tl-xl rounded-tr-xl bg-primary">
						<h3 className="font-domine text-sm font-bold text-white">
							{t(tKey('tables.taskName'))}
						</h3>
						<div className="flex flex-col">
							<ChevronUpIcon
								className={clsx(
									'h-3 w-3 cursor-pointer',
									sorting?.isSorted && sorting.type === 'asc'
										? 'stroke-2 text-white'
										: 'text-gray-400'
								)}
							/>
							<ChevronDownIcon
								className={clsx(
									'h-3 w-3 cursor-pointer',
									sorting?.isSorted && sorting.type === 'desc'
										? 'stroke-2 text-white'
										: 'text-gray-400'
								)}
							/>
						</div>
					</div>
					<Pagination renderComponent={renderMobileTasksTable} items={filteredTasks} />
				</div>
				<Table
					data={filteredTasks}
					columns={columns}
					shouldRedirect="task"
					hasActionColumn
					className="max-md:hidden table-auto"
				/>
			</div>
		</AppLayout>
	)
}
