import { ArrowPathIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { toast } from 'react-toastify'

import clsx from 'clsx'
import generator from 'generate-password-browser'
import { DateTime } from 'luxon'
import * as yup from 'yup'

import { Spinner } from 'components/animations/spinner'
import { Button } from 'components/app/button'
import { Checkbox } from 'components/inputs/checkbox'
import { Input } from 'components/inputs/input'
import { RadioInput } from 'components/inputs/radio'
import { Language } from 'constants/constants'
import { useAppSelector } from 'hooks'
import userService from 'services/user-service'
import { getAppLang, getTKey } from 'utils/language'

interface CreateClientProps {
	onCreation: () => void
	onCancel: () => void
	isCalendarForm?: boolean
	className?: string
	setCreatedClient?: (id: string) => void
}

export const CreateClient = ({
	onCreation,
	onCancel,
	isCalendarForm,
	className,
	setCreatedClient
}: CreateClientProps) => {
	const { t } = useTranslation()
	const tKey = getTKey('clients.create')

	const auth = useAppSelector(state => state.auth)

	const [isPasswordVisible, setIsPasswordVisible] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	const schema = yup.object<UserForm>().shape({
		fname: yup.string().required(t(tKey('errors.firstName'))),
		lname: yup.string().required(t(tKey('errors.lastName'))),
		password: yup
			.string()
			.required(t(tKey('errors.password')))
			.matches(
				/^(?=.*[A-Z])(?=.*[0-9!@#\$%\^\&*\)\(+=._-]).{8,}$/,
				t('employees.create.errors.validPassword')
			)
			.min(8, t(tKey('errors.minPassword'))),
		gender: yup.string().required(t(tKey('errors.gender'))),
		email: yup
			.string()
			.required(t(tKey('errors.email')))
			.email(t(tKey('errors.validEmail'))),
		pID: yup.string().required(t(tKey('errors.pID'))),
		phone: yup
			.string()
			.required(t(tKey('errors.phone')))
			.max(13, t(tKey('errors.maxPhone'))),
		insuranceNumber: yup
			.string()
			.required(t(tKey('errors.insuranceNumber')))
			.matches(/^80756\d{15}$/, t(tKey('errors.validInsuranceNumber'))),
		birthDate: yup
			.date()
			.typeError(t(tKey('errors.dob')))
			.max(new Date(Date.now() - 18 * 365 * 24 * 60 * 60 * 1000), t(tKey('errors.dobAge'))),
		streetNumber: yup.string().notRequired(),
		country: yup.string().notRequired(),
		address: yup.string().notRequired(),
		city: yup.string().notRequired(),
		zip: yup.string().notRequired()
	})

	const {
		register,
		handleSubmit,
		watch,
		reset,
		formState: { errors },
		control
	} = useForm<UserForm>({
		resolver: yupResolver(schema as any),
		defaultValues: {
			insuranceNumber: '80756',
			phone: '+41',
			country: getAppLang() === Language.EN ? 'Switzerland' : 'Schweiz',
			password: generator.generate({
				length: 10,
				numbers: true,
				uppercase: true
			}),
			birthDate: DateTime.fromJSDate(new Date(0)).toFormat('yyyy-LL-dd') as any
		},
		mode: 'all'
	})

	const sendEmail = watch('sendEmail')

	const onSubmit = (data: any) => {
		setIsLoading(true)
		const payload: UserForm = {
			...data,
			companyId: auth.companyId,
			birthDate: DateTime.fromJSDate(data.birthDate).toMillis(),
			source: 'crm',
			created: DateTime.now().toMillis()
		}
		const emailPayload = {
			name: data.fname + ' ' + data.lname,
			phone: data.phone,
			email: data.email,
			birthDate: DateTime.fromJSDate(data.birthDate).toFormat('yyyy-LL-dd'),
			password: data.password
		}
		userService
			.createUser(payload)
			.then(res => {
				toast.success(t(tKey('toast.clientSuccess')))
				setCreatedClient?.(res._id)
				if (sendEmail) {
					userService.sendRegistrationEmail(emailPayload)
				}
				onCreation()
			})
			.catch(error => {
				if (error?.response?.data?.message) {
					return toast.error(error?.response?.data?.message)
				}
				toast.error(t(tKey('toast.clientError')))
			})
			.finally(() => setIsLoading(false))
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className={clsx('md:px-8 py-6 max-md:px-5', className)}>
			<div className="flex flex-col gap-y-5">
				<div className="flex justify-between items-center">
					<h2 className="text-primary font-domine font-bold text-sm md:text-lg">
						{t(tKey('titles.basicInfo'))}
					</h2>
					<p
						onClick={() =>
							reset({
								password: generator.generate({
									length: 10,
									numbers: true,
									uppercase: true
								})
							})
						}
						className="text-primary-light whitespace-nowrap hover:underline flex items-center gap-x-1 cursor-pointer text-sm">
						<ArrowPathIcon className="size-4" />
						{t('employees.create.labels.regeneratePassword')}
					</p>
				</div>
				<Controller
					control={control}
					name="gender"
					render={({ field: { onChange, value } }) => (
						<div className="flex flex-col">
							<div className="flex flex-row gap-x-3">
								<RadioInput
									labelText={t(tKey('labels.male'))}
									onChange={onChange}
									name="gender"
									register={register}
									value="male"
									checked={value === 'male'}
								/>
								<RadioInput
									labelText={t(tKey('labels.female'))}
									onChange={onChange}
									register={register}
									name="gender"
									value="female"
									checked={value === 'female'}
								/>
							</div>
							{errors?.gender && (
								<p className="text-xs text-red-500 mt-1">{errors.gender.message}</p>
							)}
						</div>
					)}
				/>

				<div
					className={clsx('flex flex-col gap-y-5 md:flex-row gap-x-5', {
						'!flex-col': isCalendarForm
					})}>
					<Input
						register={register}
						errors={errors}
						name="fname"
						labelText={t(tKey('labels.firstName'))}
					/>
					<Input
						register={register}
						errors={errors}
						name="lname"
						labelText={t(tKey('labels.lastName'))}
					/>
				</div>
				<div
					className={clsx(
						'grid grid-cols-1 items-start md:grid-cols-2 gap-y-5 md:grid-flow-col gap-x-5',
						{ '!grid-cols-1 !grid-flow-row': isCalendarForm }
					)}>
					<Input register={register} errors={errors} name="email" labelText="Email" />
					<div>
						<div className="relative z-0 flex items-center">
							<Input
								type={isPasswordVisible ? 'text' : 'password'}
								register={register}
								autoCapitalize="false"
								autoCorrect="off"
								autoComplete="new-password"
								name="password"
								labelText={t(tKey('labels.password'))}
							/>
							<div
								onClick={() => setIsPasswordVisible(!isPasswordVisible)}
								className="absolute right-4">
								{isPasswordVisible ? (
									<EyeIcon className="w-6 h-6 stroke-primary" />
								) : (
									<EyeSlashIcon className="w-6 h-6 stroke-primary" />
								)}
							</div>
						</div>
						{errors.password && (
							<p className="text-xs text-red-500 mt-1">{errors.password.message}</p>
						)}
					</div>
				</div>
				<div
					className={clsx(
						'grid grid-cols-1 items-start md:grid-cols-2 gap-y-5 md:grid-flow-col gap-x-5',
						{ '!grid-cols-1 !grid-flow-row': isCalendarForm }
					)}>
					<Input
						name="birthDate"
						register={register}
						errors={errors}
						labelText={t(tKey('labels.dob'))}
						type="date"
					/>
					<Controller
						control={control}
						name={'phone'}
						render={({ field: { onChange, value }, fieldState: { error } }) => (
							<div className="flex flex-col">
								<PhoneInput
									numberInputProps={{
										className:
											'w-full rounded font-normal pl-4 py-3 bg-white focus:ring-0 border-0 text-primary placeholder-[#7F9AB2] placeholder:text-base focus:outline-none text-md'
									}}
									placeholder="Enter phone number"
									defaultCountry="CH"
									value={value}
									error={error}
									onChange={onChange}
								/>
								{errors?.phone && (
									<p className="text-xs text-red-500 mt-1">{errors.phone.message as string}</p>
								)}
							</div>
						)}
					/>
				</div>
				<div
					className={clsx(
						'grid grid-cols-1 items-start md:grid-cols-2 gap-y-5 md:grid-flow-col gap-x-5',
						{ '!grid-cols-1 !grid-flow-row': isCalendarForm }
					)}>
					<Input
						register={register}
						errors={errors}
						inputMode="numeric"
						type="number"
						min="0"
						labelText={t(tKey('labels.insuranceNo'))}
						name="insuranceNumber"
					/>
					<Input register={register} errors={errors} labelText={t(tKey('labels.pID'))} name="pID" />
				</div>
				<div className="flex flex-col gap-y-6 mt-5">
					<h2 className="text-primary font-domine font-bold text-sm md:text-lg">
						{t(tKey('titles.locationInfo'))}
					</h2>
					<div className="flex flex-col gap-y-5">
						<div
							className={clsx('flex flex-col gap-y-5 md:flex-row gap-x-5', {
								'!flex-col': isCalendarForm
							})}>
							<Input
								register={register}
								errors={errors}
								labelText={t(tKey('labels.location'))}
								name="address"
							/>
							<Input
								register={register}
								errors={errors}
								inputMode="numeric"
								type="number"
								min="0"
								labelText={t(tKey('labels.streetNo'))}
								name="streetNumber"
							/>
							<Input
								register={register}
								errors={errors}
								type="number"
								inputMode="numeric"
								min="0"
								labelText={t(tKey('labels.zipCode'))}
								name="zip"
							/>
						</div>
						<div
							className={clsx('flex flex-col gap-y-5 md:flex-row gap-x-5', {
								'!flex-col': isCalendarForm
							})}>
							<Input
								register={register}
								errors={errors}
								labelText={t(tKey('labels.city'))}
								name="city"
							/>
							<Input
								register={register}
								errors={errors}
								labelText={t(tKey('labels.country'))}
								name="country"
							/>
						</div>
					</div>

					<Checkbox name="sendEmail" register={register} labelText={t(tKey('labels.sendEmail'))} />

					<div className="flex gap-x-6 justify-end">
						<button type="button" onClick={onCancel} className="text-primary font-bold text-sm">
							{t(tKey('buttons.cancel'))}
						</button>
						<Button disabled={isLoading} className="text-sm font-bold">
							{isLoading ? (
								<div className="flex items-center justify-center gap-x-5">
									<Spinner />
									<span className="animate-pulse whitespace-nowrap">
										{t(tKey('buttons.pleaseWait'))}
									</span>
								</div>
							) : (
								<span>{t(tKey('buttons.save'))}</span>
							)}
						</Button>
					</div>
				</div>
			</div>
		</form>
	)
}
