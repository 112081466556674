import { Link } from 'react-router-dom'

import clsx from 'clsx'

interface StatsCardProps {
	title: string
	value: number
	link: string
	className?: string
}

export const StatsCard = ({ title, value, link, className }: StatsCardProps) => {
	return (
		<Link
			to={link}
			className={clsx(
				'flex flex-col w-full font-semibold gap-y-3.5 text-primary grow max-md:py-3 max-md:px-5 md:p-6 cursor-pointer rounded-2xl',
				className
			)}>
			<h1 className="text-xs md:text-sm leading-6">{title}</h1>
			<p className="text-2xl md:text-[32px] leading-9">{value}</p>
		</Link>
	)
}
