import axios from 'services/axios'

const getMedicationsByAppointmentId = (appointmentId: string): Promise<Prescription[]> => {
	return axios
		.get(`/medicationsByAppointmentId/Pro/${appointmentId}`)
		.then(response => response.data)
}

const getMedicationById = (id: string): Promise<Prescription> => {
	return axios.get(`/medication/${id}`).then(response => response.data)
}

const createMedication = (data: PrescriptionForm) => {
	return axios.post('/medication', data).then(response => response.data)
}

const updateMedication = (id: string, data: PrescriptionForm) => {
	return axios.put(`/medication/${id}`, data).then(response => response.data)
}

const deleteMedication = (id: string) => {
	return axios.delete(`/medication/${id}`).then(response => response.data)
}

const medicationService = {
	getMedicationsByAppointmentId,
	getMedicationById,
	createMedication,
	updateMedication,
	deleteMedication
}

export default medicationService
