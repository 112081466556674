import axios from 'services/axios'

const getEditingStaffByAppointmentId = (appointmentId: string): Promise<EditingStaff> => {
	return axios.get(`/editingStaffByAppointmentId/${appointmentId}`).then(response => response.data)
}

const getEditingStaffById = (id: string): Promise<EditingStaff> => {
	return axios.get(`/editingStaff/${id}`).then(response => response.data)
}

const createEditingStaff = (data: EditingStaffForm) => {
	return axios.post('/editingStaff', data).then(response => response.data)
}

const updateEditingStaff = (id: string, data: EditingStaffForm) => {
	return axios.put(`/editingStaff/${id}`, data).then(response => response.data)
}

const deleteEditingStaff = (id: string) => {
	return axios.delete(`/editingStaff/${id}`).then(response => response.data)
}

const editingStaffService = {
	getEditingStaffById,
	getEditingStaffByAppointmentId,
	createEditingStaff,
	updateEditingStaff,
	deleteEditingStaff
}

export default editingStaffService
