import React, { useRef, useState } from 'react'
import { useDrag } from 'react-dnd'
import { toast } from 'react-toastify'

import clsx from 'clsx'
import { DateTime } from 'luxon'

import { CalendarEvent } from 'components/calendar'
import { useAppSelector } from 'hooks'
import appointmentService from 'services/appointment-service'
import eventService from 'services/event-service'
import { socket } from 'sockets/socket-context'

interface EventCardProps {
	event: CalendarEvent
	dayIndex: number
	onUpdate: () => void
	eventIndex: number
	totalEvents: number
	calculateEventPosition: (
		event: CalendarEvent,
		dayIndex: number,
		totalEvents: number,
		eventIndex: number,
		isOverlapping: boolean
	) => {
		top: number
		height: number
		left: string
		width: string
	}
	handleEventSelection: (event: CalendarEvent, date: string) => void
	day: DateTime
	isDragging: boolean
	setIsDragging: React.Dispatch<React.SetStateAction<boolean>>
}

const EventCard = ({
	event,
	dayIndex,
	eventIndex,
	totalEvents,
	onUpdate,
	day,
	calculateEventPosition,
	handleEventSelection,
	isDragging,
	setIsDragging
}: EventCardProps) => {
	const isOverlapping = totalEvents > 1
	const { top, height, left, width } = calculateEventPosition(
		event,
		dayIndex,
		totalEvents,
		eventIndex,
		isOverlapping
	)
	const auth = useAppSelector(state => state.auth)
	const [isResizing, setIsResizing] = useState(false)
	const [currentHeight, setCurrentHeight] = useState(height)
	const resizerRef = useRef<HTMLDivElement>(null)

	const [{ isCurrentItemDragging }, dragRef] = useDrag(
		() => ({
			type: 'weekly',
			item: event,
			canDrag: () =>
				!isResizing &&
				(event.type === 'appointment' ||
					(event.type === 'event' && (event as any).addedBy._id === auth._id)),
			isDragging: monitor => {
				setIsDragging(true)
				return monitor.getItem().id === event.id
			},
			collect: monitor => ({
				isCurrentItemDragging: monitor.isDragging()
			}),
			end: () => setIsDragging(false)
		}),
		[isResizing]
	)

	const onMouseOrTouchDown = (e: React.MouseEvent | React.TouchEvent, event: CalendarEvent) => {
		if (event.type === 'task') return
		e.stopPropagation()
		setIsResizing(true)

		const startY = 'touches' in e ? e.touches[0].clientY : e.clientY
		const startHeight = resizerRef.current?.parentElement?.offsetHeight || 0
		const startTime = DateTime.fromISO(event.startDateTime)
		const endTime = DateTime.fromISO(event.endDateTime)
		let updatedEndTime = endTime

		const onMouseOrTouchMove = (e: MouseEvent | TouchEvent) => {
			const clientY = 'touches' in e ? e.touches[0].clientY : e.clientY
			const heightChange = clientY - startY
			const newHeight = startHeight + heightChange
			setCurrentHeight(newHeight)

			const minutesChange = (heightChange / 64) * 60
			updatedEndTime = endTime.plus({ minutes: minutesChange })

			if (updatedEndTime > endTime.endOf('day')) {
				updatedEndTime = endTime.set({ hour: 23, minute: 59 })
				const maxHeight = (updatedEndTime.diff(startTime, 'minutes').minutes / 60) * 64
				setCurrentHeight(maxHeight)
			}
		}

		const onMouseOrTouchUp = () => {
			setIsResizing(false)
			document.removeEventListener('mousemove', onMouseOrTouchMove)
			document.removeEventListener('mouseup', onMouseOrTouchUp)
			document.removeEventListener('touchmove', onMouseOrTouchMove)
			document.removeEventListener('touchend', onMouseOrTouchUp)

			if (event.type === 'appointment') {
				appointmentService
					.updateAppointmentSlots(event.id, startTime.toMillis(), updatedEndTime.toMillis())
					.then(res => {
						socket.emit('Appointment created', {
							appointment: res.data
						})
						onUpdate()
					})
					.catch(err => {
						setCurrentHeight(height)
						toast.error(err?.response?.data?.message)
					})
			} else {
				eventService
					.updateEvent(event.id, {
						title: (event as any).title,
						description: event.description as string,
						duration: (event as any).duration,
						guests: (event as any).guests,
						allDay: event.allDay,
						colorCode: event.color,
						id_company: (event as any).companyId,
						from: startTime.toMillis(),
						to: updatedEndTime.toMillis()
					})
					.then(() => {
						onUpdate()
					})
					.catch(err => {
						setCurrentHeight(height)
						toast.error(err?.response?.data?.message)
					})
			}
		}

		document.addEventListener('mousemove', onMouseOrTouchMove)
		document.addEventListener('mouseup', onMouseOrTouchUp)
		document.addEventListener('touchmove', onMouseOrTouchMove)
		document.addEventListener('touchend', onMouseOrTouchUp)
	}

	const handleClick = () => {
		if (!isResizing) {
			handleEventSelection(event, day.toISODate() as string)
		}
	}

	return (
		<div
			key={event.id}
			onClick={handleClick}
			className={clsx(
				'absolute cursor-pointer scroll-container gap-1 border-l-[1.2px] md:border-l-[3px] max-md:pt-1 max-md:pb-[1.6px] max-md:px-1 md:pl-2 md:pr-1 md:py-1.5 flex flex-col',
				isDragging ? 'pointer-events-none' : 'pointer-events-auto'
			)}
			style={{
				top: `${top}px`,
				height: `${currentHeight}px`,
				left,
				width,
				background: event.color + '10',
				borderColor: event.color,
				zIndex: 40
			}}
			ref={dragRef}>
			<p className="text-primary font-semibold text-[10px] leading-[10px] md:text-sm md:leading-[18px]">
				{event.name}
			</p>
			<time
				dateTime={event.startDateTime}
				className="flex whitespace-nowrap items-center gap-[2.4px] md:gap-1.5 text-[#757575] text-[8px] md:text-xs">
				{DateTime.fromISO(event.startDateTime).toFormat('HH:mm')}
				<svg
					width="4"
					height="5"
					viewBox="0 0 4 5"
					fill="none"
					className="shrink-0"
					xmlns="http://www.w3.org/2000/svg">
					<circle cx="2" cy="2.55078" r="2" fill="#7F9AB2" />
				</svg>
				{(event as any).type === 'appointment'
					? (event as any).id_client.fname + ' ' + (event as any).id_client.lname
					: (event as any).type === 'event'
						? (event as any).addedBy.fname + ' ' + (event as any).addedBy.lname
						: (event as any).assignedBy.fname + ' ' + (event as any).assignedBy.lname}
			</time>
			<div
				ref={resizerRef}
				onMouseDown={e => onMouseOrTouchDown(e, event)}
				onTouchStart={e => onMouseOrTouchDown(e, event)}
				onClick={e => e.stopPropagation()}
				className="absolute bottom-0 left-0 w-full h-2 cursor-ns-resize bg-transparent"
				style={{
					transform: 'translateY(50%)',
					zIndex: 50 // Ensures it stays on top during resizing
				}}
			/>
		</div>
	)
}

export default EventCard
